import { CLIENT_ASIDE, CLIENT } from '../../../../types/client/chat.types';
import { MutableRefObject } from 'react';
import { sortByLastMsg } from './sort';

export const setChatsUtil = (
  prev: CLIENT_ASIDE[],
  newChats: CLIENT_ASIDE[],
  selectedClientId: MutableRefObject<string | null>
): CLIENT_ASIDE[] => {
  const res: CLIENT_ASIDE[] = [];
  const setKeysChats: Set<string> = new Set();

  for (let i = 0; i < newChats.length; i++) {
    const chat: CLIENT_ASIDE = newChats[i];

    // Check if we already have it
    if (setKeysChats.has(chat.client_id)) continue;

    // We push that chat
    // If we are in this current chat, the unread msgs is 0
    if (selectedClientId.current === chat.client_id) {
      res.push({
        ...chat,
        unread: 0,
      });
    } else {
      res.push(chat);
    }
    setKeysChats.add(chat.client_id);
  }

  for (let i = 0; i < prev.length; i++) {
    const chat: CLIENT_ASIDE = prev[i];

    // Check if we already have it
    if (setKeysChats.has(chat.client_id)) continue;

    // We push that chat
    // If we are in this current chat, the unread msgs is 0
    if (selectedClientId.current === chat.client_id) {
      res.push({
        ...chat,
        unread: 0,
      });
    } else {
      res.push(chat);
    }
    setKeysChats.add(chat.client_id);
  }

  const sortedChats: CLIENT_ASIDE[] = sortByLastMsg(res);
  return sortedChats;
};

export const updateThisChatRead = (
  prev: CLIENT_ASIDE[],
  clientId: string,
  unreadValue: number
): CLIENT_ASIDE[] => {
  const aux: CLIENT_ASIDE[] = [];

  for (let i = 0; i < prev.length; i++) {
    if (prev[i].client_id !== clientId) {
      aux.push(prev[i]);
      continue;
    }

    aux.push({
      ...prev[i],
      unread: unreadValue,
    });
  }

  return aux;
};

export const updateChats = (
  chats: CLIENT_ASIDE[],
  client: CLIENT
): CLIENT_ASIDE[] => {
  const aux: CLIENT_ASIDE[] = [];

  for (let i = 0; i < chats.length; i++) {
    const chat: CLIENT_ASIDE = chats[i];

    if (chat.client_id !== client.id) {
      aux.push(chat);
      continue;
    }

    // We update
    aux.push({
      client_id: chat.client_id,
      client_name: client.name, // Update
      last_msg_content: chat.last_msg_content,
      last_msg_created_at: chat.last_msg_created_at,
      unread: chat.unread,
    });
  }

  return aux;
};
