import React, { useContext } from 'react';
import styles from './Info.module.css';
import { DashboardContext } from '../../Dashboard';
import Skeleton from './Skeleton/Skeleton';
import UserProfile from '../../Components/UserProfile/UserProfile';
import { PersistentAgentContext } from '../../../../components/Layout/Context/PersistentAgent';

const Info: React.FunctionComponent = (): JSX.Element => {
  const { isLoadingGetClient } = useContext(DashboardContext);
  const { selectedClient } = useContext(PersistentAgentContext);

  const getInfo = (): string => {
    if (selectedClient === null) return '-';
    if (
      selectedClient.whatsapp !== null &&
      selectedClient.whatsapp.trim() !== ''
    )
      return selectedClient.whatsapp;
    if (selectedClient.email !== null && selectedClient.email.trim() !== '')
      return selectedClient.email;

    return '-';
  };

  if (isLoadingGetClient) {
    return (
      <div className={styles.info}>
        <Skeleton />
      </div>
    );
  }

  if (selectedClient === null) {
    return <div className={styles.info}></div>;
  }

  return (
    <div className={styles.info}>
      <UserProfile isGateway user={selectedClient} />
      <div className={styles.info_data}>
        <div className={styles.info_name}>{selectedClient.name}</div>
        <div className={styles.info_telephone}>{getInfo()}</div>
      </div>
    </div>
  );
};

export default Info;
