import React from 'react';
import styles from './Tags.module.css';
import { invertColor } from '../../utils/invertColors';
import { TAG } from '../../types/ticket/ticket.types';

interface PropsTag {
  tag: TAG | null;
  click?: () => void;
  isLoading?: boolean;
  withColor?: boolean;
}

const Tag: React.FunctionComponent<PropsTag> = ({
  tag,
  click = null,
  isLoading = false,
  withColor = true,
}): JSX.Element => {
  const clickHandler = (): void => {
    if (click === null) return;
    if (isLoading) return;
    click();
  };

  const getColor = (): string => {
    if (!withColor) {
      return '#EEEEEE';
    }
    if (tag === null) return '';
    if (tag.color.length === 6) return `#${tag.color}`;
    return tag.color;
  };

  if (tag === null) return <div>Sin etiqueta...</div>;
  return (
    <div
      className={`${styles.tag} ${!withColor && styles.no_color} ${
        click !== null && styles.click
      } ${isLoading && styles.loading}`}
      style={{
        backgroundColor: getColor(),
        color: invertColor(getColor(), true),
      }}
      onClick={clickHandler}
    >
      {tag.name}
    </div>
  );
};

export default Tag;
