import { useCallback, useEffect, useRef } from 'react';
import { DEBUG } from '../config/routes';

type BEHAVIOR = 'smooth' | 'auto';

interface PARAMS {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  sensitive?: number;
  callback: () => any;
  condition: boolean;
  isReversed?: boolean;
}

interface RETURN {
  doScroll: (behavior: BEHAVIOR) => void;
}

type HOOK = ({ ref, sensitive, callback, condition }: PARAMS) => RETURN;

export const useScroll: HOOK = ({
  ref,
  sensitive = 50,
  callback,
  condition,
  isReversed = false,
}) => {
  const prevScroll = useRef<number>(0);
  const listenToScroll = useRef<boolean>(false);
  let isWaitingToCallCallbackAgain = false;

  const time = (): void => {
    if (isWaitingToCallCallbackAgain) return;

    isWaitingToCallCallbackAgain = true;
    const timeOut = setTimeout(() => {
      isWaitingToCallCallbackAgain = false;
      clearTimeout(timeOut);
    }, 2000);
  };

  useEffect(() => {
    if (ref.current === null) return;

    ref.current.addEventListener('scroll', handleScrollCallback);

    return () => {
      if (ref.current === null) return;
      ref.current.removeEventListener('scroll', handleScrollCallback);
    };
  }, [ref.current, condition]);

  const handleScrollCallback = useCallback((): void => {
    if (ref.current === null) return;
    let max: number = 0;

    if (isReversed) {
      max = (ref.current.scrollHeight - ref.current.offsetHeight) * -1;
    }
    const scrollTop: number = ref.current.scrollTop - max;

    if (!listenToScroll.current) {
      if (scrollTop >= prevScroll.current) {
        prevScroll.current = scrollTop;
        return;
      }
      listenToScroll.current = true;
    }

    if (scrollTop > sensitive) return;
    if (!condition) return;
    if (isWaitingToCallCallbackAgain) return;
    if (DEBUG) {
      console.log(
        '%cCallback more msgs...',
        'color: #FF0000; font-size: 32px;'
      );
    }
    callback();
    time();
  }, [condition, ref.current]);

  const doScroll = (behavior: BEHAVIOR): void => {
    if (ref.current === null) return;
    if (DEBUG) {
      console.log('%cDo scroll...', 'color: #FF0000; font-size: 32px;');
    }
    ref.current.scrollTo({
      top: 100000000,
      behavior,
    });
    prevScroll.current = 0;
    listenToScroll.current = false;
  };

  return {
    doScroll,
  };
};
