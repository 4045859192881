import React, { useContext, useState } from 'react';
import styles from './NewNote.module.css';
import stylesTag from '../TagsCompany/TagsCompany.module.css';
import { TicketModalContext } from '../Ticket';
import { Button } from '@chakra-ui/react';
import { fetcher, RESPONSE } from '../../../../utils/fetcher';
import { RESPONSE_DATA } from '../../../../routes/index.routes';
import { NOTE } from '../../../../types/ticket/ticket.types';
import { DashboardLayoutContext } from '../../../Layout/Dashboard/DashboardLayout';
import { LayoutContext } from '../../../Layout/Layout';
import {
  createNote,
  CREATE_NOTE_BODY,
  CREATE_NOTE_DATA,
  GET_TICKET_DATA,
} from '../../../../routes/tickets.routes';
import { DashboardContext } from '../../../../pages/Dashboard/Dashboard';
import { PersistentAgentContext } from '../../../Layout/Context/PersistentAgent';

const NewNote: React.FunctionComponent = (): JSX.Element => {
  const { setIsEditTag, setIsAddingNote } = useContext(TicketModalContext);
  const { setDataTicket } = useContext(DashboardLayoutContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);

  const { selectedTicketId } = useContext(DashboardContext);
  const { selectedClientId } = useContext(PersistentAgentContext);
  const [text, setText] = useState<string>('');
  const [msg, setMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setText(e.target.value);
    setMsg('');
  };

  const returnAndClose = (): void => {
    setIsEditTag(false);
    setIsAddingNote(false);
  };

  const updateData = (
    prev: GET_TICKET_DATA | null,
    newNote: NOTE
  ): GET_TICKET_DATA | null => {
    if (prev === null) return null;
    const newNotesArr: NOTE[] = [];

    newNotesArr.push(newNote);

    if (prev.notes !== null) {
      newNotesArr.push(...prev.notes);
    }

    return {
      ticket: prev.ticket,
      tag: prev.tag,
      notes: newNotesArr,
      schema: prev.schema,
    };
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const createNewNote = (): void => {
    if (text.trim() === '') {
      setMsg('Debes de escribir primero la nota...');
      return;
    }

    const doFetch = async (): Promise<void> => {
      try {
        if (selectedTicketId.current === null) return;
        if (selectedClientId.current === null) return;

        const body: CREATE_NOTE_BODY = {
          text,
          ticket: selectedTicketId.current,
        };

        setIsLoading(true);
        const res: RESPONSE = await fetcher[createNote.method]({
          uri: createNote.url,
          body,
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const data: CREATE_NOTE_DATA = resData.data;

        setDataTicket((prev) => updateData(prev, data));

        // Go back
        returnAndClose();
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al crear una nueva nota', 'danger');
        setIsLoading(false);
        void askToDoRefresh();
      }
    };

    void doFetch();
  };

  const close = (): void => {
    if (isLoading) {
      // Hasta que se guarden los cambios
      setMsg('Guardando nota...');
      return;
    }
    if (text === '') {
      returnAndClose();
      return;
    }

    if (msg === '') {
      // No se ha puesto el menssaje...
      setMsg('Si vuelves no se guardará la nota...');
      return;
    }

    returnAndClose();
  };

  return (
    <div className={styles.note}>
      <div onClick={close} className={stylesTag.return}>
        Volver
      </div>
      {msg !== '' && <div className={styles.msg}>{msg}</div>}
      <div className={styles.note_instructions}>Escribe una nueva nota:</div>
      <div className={styles.note_body}>
        <textarea
          name="new-note"
          id="new-note-textarea"
          className={styles.note_body_text}
          placeholder="Nueva nota..."
          value={text}
          onChange={handleChange}
        ></textarea>
        <Button isLoading={isLoading} onClick={createNewNote}>
          Crear nota
        </Button>
      </div>
    </div>
  );
};

export default NewNote;
