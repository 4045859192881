import { CLIENT_ASIDE } from '../../../../types/client/chat.types';

export const sortByLastMsg = (chats: CLIENT_ASIDE[]): CLIENT_ASIDE[] => {
  if (chats === undefined) return [];
  const sortedChats: CLIENT_ASIDE[] = chats;

  sortedChats.sort((a: CLIENT_ASIDE, b: CLIENT_ASIDE) => {
    if (a.last_msg_created_at < b.last_msg_created_at) {
      return 1;
    }
    if (a.last_msg_created_at > b.last_msg_created_at) {
      return -1;
    }
    return 0;
  });

  return sortedChats;
};
