import React from 'react';
import styles from './Profile.module.css';
import { USER } from '../../../types/user/user.types';
import { getRawText } from '../../../utils/getRawText';

interface Props {
  user: USER;
  atribute?: string;
}

const Profile: React.FunctionComponent<Props> = ({
  user,
  atribute = '',
}): JSX.Element => {
  const getName = (): string => {
    return getRawText(user.name);
  };

  return (
    <div className={styles.profile} extra-css={atribute}>
      {getName().split(' ')[0][0]}
      {getName().split(' ').length > 1 && getName().split(' ')[1][0]}
    </div>
  );
};

export default Profile;
