export const getDateFormat = (date: Date | number): string => {
  const today = new Date(date);
  const dateFormat = new Date(date);

  const dd: string = String(dateFormat.getDate()).padStart(2, '0');
  const mm: string = dateFormat.toLocaleString('default', { month: 'short' });
  const yyyyToday: string = String(today.getFullYear());
  const yyyyDate: string = String(dateFormat.getFullYear());

  if (yyyyToday === yyyyDate) {
    return dd + ' ' + mm;
  }

  return dd + ' ' + mm + ' ' + yyyyDate;
};

export const getHourFormat = (date: Date | number): string => {
  const dateFormat = new Date(date);

  return `${
    dateFormat.getHours() > 12
      ? dateFormat.getHours() - 12
      : dateFormat.getHours()
  }:${String(dateFormat.getMinutes()).padStart(2, '0')} ${
    dateFormat.getHours() > 12 ? 'pm' : 'am'
  }`;
};
