import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import LogIn from './pages/LogIn/LogIn';
import Dashboard from './pages/Dashboard/Dashboard';
import NotFound from './pages/NotFound/NotFound';
import Settings from './pages/Settings/Settings';
import CompanyPanel from './pages/CompanyPanel/CompanyPanel';
import AdminPage from './pages/Admin/Admin';
import { USER } from './types/user/user.types';
import { COMPANY } from './types/company/company.types';
import { TAG } from './types/ticket/ticket.types';
import Layout from './components/Layout/Layout';
import Auth from './components/Auth/Auth';
import PersistentAgentProvider from './components/Layout/Context/PersistentAgent';
import PersistentAdminProvider from './components/Layout/Context/PersistentAdmin';
import PersistentCAdminProvider from './components/Layout/Context/PersistentCAdmin';
import {
  ROUTE_DASHBOARD_NAME,
  ROUTE_HOME,
  ROUTE_SETTINGS_NAME,
  ROUTE_COMPANY_ADMIN_PANEL_NAME,
  ROUTE_ADMIN_PANEL_NAME,
} from './config/routes';
import { HelmetProvider } from 'react-helmet-async';

// Context
export const AppContext = createContext({} as AppValueProvider);

interface AppValueProvider {
  user: USER | null;
  company: COMPANY | null;
  isAuth: boolean;
  isLoadingGetUser: boolean;
  companyTags: TAG[];

  setUser: Dispatch<SetStateAction<USER | null>>;
  setCompany: Dispatch<SetStateAction<COMPANY | null>>;
  setIsAuth: Dispatch<SetStateAction<boolean>>;
  setIsLoadingGetUser: Dispatch<SetStateAction<boolean>>;
  setCompanyTags: Dispatch<SetStateAction<TAG[]>>;
}

const App: React.FunctionComponent = (): JSX.Element => {
  const [user, setUser] = useState<USER | null>(null);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [isLoadingGetUser, setIsLoadingGetUser] = useState<boolean>(false);
  const [company, setCompany] = useState<COMPANY | null>(null);
  const [companyTags, setCompanyTags] = useState<TAG[]>([]);

  return (
    <HelmetProvider>
      <ChakraProvider>
        <AppContext.Provider
          value={{
            user,
            company,
            isAuth,
            isLoadingGetUser,
            companyTags,

            setUser,
            setCompany,
            setIsAuth,
            setIsLoadingGetUser,
            setCompanyTags,
          }}
        >
          <BrowserRouter>
            <PersistentAgentProvider>
              <PersistentCAdminProvider>
                <PersistentAdminProvider>
                  <Auth />
                  <Routes>
                    <Route
                      path={ROUTE_HOME}
                      element={<Layout layoutType="login" />}
                    >
                      <Route path={ROUTE_HOME} element={<LogIn />} />
                    </Route>
                    <Route
                      path={ROUTE_DASHBOARD_NAME}
                      element={<Layout layoutType="dashboard" />}
                    >
                      <Route index element={<Dashboard />} />
                    </Route>
                    <Route
                      path={ROUTE_COMPANY_ADMIN_PANEL_NAME}
                      element={<Layout layoutType="company-admin" />}
                    >
                      <Route index element={<CompanyPanel />} />
                    </Route>
                    <Route
                      path={ROUTE_ADMIN_PANEL_NAME}
                      element={<Layout layoutType="admin" />}
                    >
                      <Route index element={<AdminPage />} />
                    </Route>
                    <Route
                      path={ROUTE_SETTINGS_NAME}
                      element={<Layout layoutType="settings" />}
                    >
                      <Route index element={<Settings />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </PersistentAdminProvider>
              </PersistentCAdminProvider>
            </PersistentAgentProvider>
          </BrowserRouter>
        </AppContext.Provider>
      </ChakraProvider>
    </HelmetProvider>
  );
};

export default App;
