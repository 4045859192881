import React, { useContext, useState, useEffect } from 'react';
import styles from './Tags.module.css';
import { InfoIcon } from '@chakra-ui/icons';
import ReactTooltip from 'react-tooltip';
import Skeleton from './Skeleton/Skeleton';
import { AppContext } from '../../../../App';
import { LayoutContext } from '../../../../components/Layout/Layout';
import { CPanelLayoutContext } from '../../../../components/Layout/CompanyPanel/CompanyPanelLayout';
import { PersistentCAdminContext } from '../../../../components/Layout/Context/PersistentCAdmin';
import { fetcher, RESPONSE } from '../../../../utils/fetcher';
import { TAG } from '../../../../types/ticket/ticket.types';
import Tag from '../../../../components/Tags/Tags';
import {
  getAllTagsCompany as getAllTagsCompanyRoute,
  GET_ALL_TAGS_COMPANY_DATA,
  updateTagOnCompany,
  BODY_UPDATE_TAG,
} from '../../../../routes/tags.routes';
import { RESPONSE_DATA } from '../../../../routes/index.routes';

const CPanelTags: React.FunctionComponent = () => {
  const { setCompanyTags, company, companyTags } = useContext(AppContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const { setIsModalAddTag } = useContext(CPanelLayoutContext);
  const { hasFetchTags } = useContext(PersistentCAdminContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tagToMove, setTagToMove] = useState<TAG | null>(null);

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const getAllTagsCompany = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (company === null) return;
        if (isLoading) return;
        setIsLoading(true);
        hasFetchTags.current = true;
        const res: RESPONSE = await fetcher[getAllTagsCompanyRoute.method]({
          uri: getAllTagsCompanyRoute.url(company.id),
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const dataTags: GET_ALL_TAGS_COMPANY_DATA = resData.data;
        setCompanyTags(dataTags.tags);
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al obtener las etiquetas de la empresa', 'danger');
        void askToDoRefresh();
        setIsLoading(false);
      }
    };

    void doFetch();
  };

  const needsToDoFetch = (): void => {
    if (hasFetchTags.current) return;

    getAllTagsCompany();
  };

  const addTag = (): void => {
    setIsModalAddTag(true);
  };

  const isAddingTag = (tag: TAG): boolean => {
    if (tagToMove === null) return false;
    return tagToMove.id === tag.id;
  };

  const updateThisTag = (prev: TAG[], tag: TAG): TAG[] => {
    const aux: TAG[] = [];
    for (let i = 0; i < prev.length; i++) {
      if (prev[i].id !== tag.id) {
        aux.push(prev[i]);
        continue;
      }
      aux.push({
        ...prev[i],
        is_active: !tag.is_active,
      });
    }
    return aux;
  };

  const clickTag = (tag: TAG): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (tagToMove !== null) return;
        setTagToMove(tag);
        const body: BODY_UPDATE_TAG = {
          value: !tag.is_active,
        };
        const res = await fetcher[updateTagOnCompany.method]({
          uri: updateTagOnCompany.url(tag.id),
          body,
        });
        const resData = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        setTagToMove(null);
        setCompanyTags((prev) => updateThisTag(prev, tag));
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al habilitar/deshabilitar la etiqueta', 'danger');
        void askToDoRefresh();
      }
    };
    void doFetch();
  };

  useEffect(() => {
    needsToDoFetch();
  }, []);

  return (
    <div className={styles.tags}>
      <div className={styles.fluid}>
        <div className={styles.add}>
          <button
            data-tip
            data-for="button-add-tag-cpanel"
            className={styles.add_tag}
            onClick={addTag}
          >
            +
          </button>
          <span>Etiquetas</span>
          <ReactTooltip
            id="button-add-tag-cpanel"
            effect="solid"
            type="dark"
            place="top"
          >
            Añadir etiqueta
          </ReactTooltip>
        </div>
        <div className={styles.info} data-tip data-for="info-cpanel-tags">
          <InfoIcon />
        </div>
        <ReactTooltip
          id="info-cpanel-tags"
          effect="solid"
          type="dark"
          place="top"
        >
          Para habilitar/deshabilitar etiquetas debes dar click en ellas.
        </ReactTooltip>
      </div>
      {isLoading && <Skeleton />}
      {!isLoading && (
        <div className={styles.data}>
          <div className={styles.tags_container}>
            {companyTags.map((tag: TAG, index: number) => {
              if (!tag.is_active)
                return (
                  <React.Fragment key={`${index}:active`}></React.Fragment>
                );
              return (
                <React.Fragment key={`${index}:active`}>
                  <Tag
                    click={() => {
                      clickTag(tag);
                    }}
                    isLoading={isAddingTag(tag)}
                    tag={tag}
                  />
                </React.Fragment>
              );
            })}
          </div>
          <div className={styles.line}></div>
          <div className={styles.in_active_tags}>
            <div className={styles.label}>Deshabilitadas:</div>
            <div className={styles.tags_container}>
              {companyTags.map((tag: TAG, index: number) => {
                if (tag.is_active)
                  return (
                    <React.Fragment key={`${index}:inactive`}></React.Fragment>
                  );
                return (
                  <React.Fragment key={`${index}:inactive`}>
                    <Tag
                      click={() => {
                        clickTag(tag);
                      }}
                      tag={tag}
                      isLoading={isAddingTag(tag)}
                      withColor={false}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CPanelTags;
