import React, { useRef, useState, useContext } from 'react';
import styles from './InputChat.module.css';
import { AttachmentIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { DashboardContext } from '../../Dashboard';
import { LayoutContext } from '../../../../components/Layout/Layout';
import { AppContext } from '../../../../App';
import { PersistentAgentContext } from '../../../../components/Layout/Context/PersistentAgent';
import { canSendMsg, getInputHeight } from './utils/index';
import { fetcher } from '../../../../utils/fetcher';
import { sendMessage, BODY_SEND_MSG } from '../../../../routes/messages.routes';
import { MESSAGE } from '../../../../types/messages/messages.types';
import ReactTooltip from 'react-tooltip';
import { TEMP_ID_PUSH_MSGS } from '../../../../config/constraints';

const InputChat: React.FunctionComponent = (): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const chatRef = useRef<HTMLFormElement>(null);
  const { isLoadingMsgs } = useContext(DashboardContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const { company, user } = useContext(AppContext);
  const { selectedClient, setMessagesLoading, setMessages, amountUnReadMsgs } =
    useContext(PersistentAgentContext);
  const [msgInput, setMsgInput] = useState<string>('');

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key !== 'Enter' || e.shiftKey) return;
    if (chatRef.current === undefined) return;
    if (chatRef.current === null) return;

    e.preventDefault();
    sendMsg();
  };

  const onInput = (): void => {
    if (textareaRef.current === null) return;
    if (chatRef.current === null) return;

    chatRef.current.style.height = '0px';

    chatRef.current.style.height =
      String(getInputHeight(textareaRef.current)) + 'px';
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    e.preventDefault();

    setMsgInput(e.target.value);
  };

  const disableInput = (): boolean => {
    if (isLoadingMsgs) return true;
    if (selectedClient === null) return true;
    return false;
  };

  const getId = (): string => {
    return `${TEMP_ID_PUSH_MSGS}:${new Date().getTime()}`;
  };

  const updateOnReadAlMsgs = (): void => {
    const update = (prev: MESSAGE[]): MESSAGE[] => {
      const aux: MESSAGE[] = [];

      for (let i = 0; i < prev.length; i++) {
        const msg: MESSAGE = prev[i];

        aux.push({
          ...msg,
          is_read: true,
        });
      }

      return aux;
    };

    setMessages((prev) => update(prev));
    amountUnReadMsgs.current = 0;
  };

  const pushMsg = (text: string): void => {
    if (selectedClient === null) return;
    if (user === null) return;
    const newContent: string = text;

    const newMsg: MESSAGE = {
      id: getId(),
      content: newContent,
      client: selectedClient.id,
      created_at: new Date().getTime(),
      is_read: true,
      from_client: false,
      from_agent: user.username,
      read_by: [],
      type: 'fb_msngr_text',
    };

    setMessagesLoading((prev) => [newMsg, ...prev]);
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const sendMsg = (e?: any): void => {
    if (e !== undefined) {
      e.preventDefault();
    }
    if (isLoadingMsgs) return;
    if (selectedClient === null) return;
    if (!canSendMsg(msgInput)) return;
    if (company === null) return;

    // send msg
    const doFetch = async (): Promise<void> => {
      try {
        const body: BODY_SEND_MSG = {
          client: selectedClient.id,
          content: msgInput,
          company: company.id,
          type: 'fb_msngr_text',
          skip_chats: 0, // Para que sí mande este último mensaje y no le de skip
          skip_msgs: 0, // Para que sí mande este último mensaje y no le de skip
        };

        // clean input
        setMsgInput('');

        // reset textarea size
        if (chatRef.current !== null) {
          chatRef.current.style.height = '40px';
        }

        pushMsg(body.content);
        updateOnReadAlMsgs();
        const res = await fetcher[sendMessage.method]({
          uri: sendMessage.url,
          body,
        });

        const resData = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al enviar el mensaje', 'danger');
        setMsgInput('');
        void askToDoRefresh();
      }
    };

    void doFetch();
  };

  return (
    <form onSubmit={sendMsg} ref={chatRef} className={styles.chat}>
      <div
        className={styles.chat_file}
        data-tip
        data-for="inputchat-attachment-icon"
      >
        <AttachmentIcon />
        <ReactTooltip
          id="inputchat-attachment-icon"
          effect="solid"
          type="dark"
          place="top"
        >
          Próximamente...
        </ReactTooltip>
      </div>
      <textarea
        style={{
          width: `calc(100% - 40px - ${canSendMsg(msgInput) ? 40 : 10}px)`,
        }}
        tabIndex={1}
        name="message"
        id="input-chat"
        onKeyDown={onKeyDown}
        onInput={onInput}
        ref={textareaRef}
        placeholder="Escribe un mensaje aquí"
        value={msgInput}
        onChange={onChange}
        disabled={disableInput()}
      ></textarea>
      {canSendMsg(msgInput) && (
        <button role="submit" className={styles.chat_file}>
          <ArrowForwardIcon />
        </button>
      )}
    </form>
  );
};

export default InputChat;
