import React, { Dispatch, SetStateAction, useContext } from 'react';
import styles from './Extra.module.css';
import { DashboardLayoutContext } from '../../../Layout/Dashboard/DashboardLayout';
import { LayoutContext } from '../../../Layout/Layout';
import { Input, Switch, FormControl, FormLabel } from '@chakra-ui/react';
import Skeleton from './Skeleton/Skeleton';

import {
  TICKET_EXTRA,
  TICKET_TYPES,
  TICKET_VALUES,
} from '../../../../types/ticket/ticket.types';

interface Props {
  ticketGroup: TICKET_EXTRA;
  setTicketGroup: Dispatch<SetStateAction<TICKET_EXTRA>>;
}

const TicketExtra: React.FunctionComponent<Props> = ({
  ticketGroup,
  setTicketGroup,
}): JSX.Element => {
  const { dataTicket } = useContext(DashboardLayoutContext);
  const { addStaticMsg } = useContext(LayoutContext);

  if (dataTicket === null) return <div></div>;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ): void => {
    try {
      let value: TICKET_VALUES;
      const typeKey: TICKET_TYPES = getType(key);

      if (typeKey === 'str') {
        value = e.target.value;
      } else if (typeKey === 'bool') {
        value = e.target.checked;
      } else if (typeKey === 'int') {
        value = e.target.value;
      } else if (typeKey === 'float') {
        value = e.target.value;
      } else {
        throw new Error('Tipo de dato no soportado.');
      }

      if (value === null) return;

      setTicketGroup((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    } catch (error) {
      addStaticMsg('Error al actualizar los datos del ticket', 'danger');
      console.error(error);
    }
  };

  const getType = (key: string): TICKET_TYPES => {
    if (dataTicket.ticket.extra === null) return 'str';
    if (dataTicket.schema === null) return 'loading';
    if (dataTicket.schema.fields[key] === 'bool') return 'bool';
    if (dataTicket.schema.fields[key] === 'float') return 'float';
    if (dataTicket.schema.fields[key] === 'int') return 'int';
    if (dataTicket.schema.fields[key] === 'str') return 'str';

    // Null...
    return 'str';
  };

  const getValue = (key: string): any => {
    if (ticketGroup[key] === null || ticketGroup[key] === undefined) {
      const typeKey: TICKET_TYPES = getType(key);
      if (typeKey === 'bool') return false;
      if (typeKey === 'float') return 0.0;
      if (typeKey === 'int') return 0;
      if (typeKey === 'str') return '';

      return '';
    }

    return ticketGroup[key];
  };

  function containsOnlyNumbers(str: string): boolean {
    return /^\d+$/.test(str);
  }

  return (
    <div
      className={`${styles.form_inputs} ${
        dataTicket.schema === null && styles.loading
      }`}
    >
      {ticketGroup !== null &&
        Object.keys(ticketGroup).map((key: string, index: number) => {
          return (
            <React.Fragment key={index}>
              <FormControl
                className={`${
                  getType(key) === 'bool' ? styles.form_switch : styles.form
                }`}
                flexDir={getType(key) === 'bool' ? 'row' : 'column'}
              >
                {getType(key) !== 'loading' && (
                  <FormLabel htmlFor={`${key}-switch`}>{key}</FormLabel>
                )}
                {getType(key) === 'loading' && <Skeleton />}
                {getType(key) === 'str' && (
                  <Input
                    autoComplete="new-password"
                    value={getValue(key)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e, key);
                    }}
                    id={`${key}-switch`}
                    placeholder={key}
                    type={'text'}
                  />
                )}
                {getType(key) === 'int' && (
                  <input
                    className={styles.my_own_input}
                    autoComplete="new-password"
                    value={getValue(key)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!containsOnlyNumbers(e.target.value)) return;
                      handleChange(e, key);
                    }}
                    type="number"
                    step={1}
                  />
                )}
                {getType(key) === 'float' && (
                  <input
                    className={styles.my_own_input}
                    autoComplete="new-password"
                    value={getValue(key)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (isNaN(parseInt(e.target.value))) return;
                      handleChange(e, key);
                    }}
                    type="number"
                    step={0.1}
                  />
                )}
                {getType(key) === 'bool' && (
                  <Switch
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e, key);
                    }}
                    isChecked={getValue(key)}
                    id={`${key}-switch`}
                  />
                )}
              </FormControl>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default TicketExtra;
