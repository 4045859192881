import React, {
  useContext,
  useEffect,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import PopUpModal from '../PopUp/PopUp';
import { DashboardLayoutContext } from '../../Layout/Dashboard/DashboardLayout';
import { DashboardContext } from '../../../pages/Dashboard/Dashboard';
import TicketContainer from './Container/Container';

export const TicketModalContext = createContext({} as ValueTicketModalProvider);

interface ValueTicketModalProvider {
  isEditTag: boolean;
  isAddingNote: boolean;

  setIsEditTag: Dispatch<SetStateAction<boolean>>;
  setIsAddingNote: Dispatch<SetStateAction<boolean>>;
}

const Ticket: React.FunctionComponent = (): JSX.Element => {
  const { isModalTicket, setIsModalTicket, selectedTicketId } =
    useContext(DashboardContext);
  const { setDataTicket } = useContext(DashboardLayoutContext);

  const [isEditTag, setIsEditTag] = useState<boolean>(true);
  const [isAddingNote, setIsAddingNote] = useState<boolean>(true);

  const clear = (): void => {
    setDataTicket(null);
    setIsEditTag(false);
    setIsAddingNote(false);
    selectedTicketId.current = null;
  };

  useEffect(() => {
    if (isModalTicket) return;

    // When it unMounts
    const timeOut = setTimeout(() => {
      clear();
      clearTimeout(timeOut);
    }, 500);
  }, [isModalTicket]);

  return (
    <PopUpModal setIsOpen={setIsModalTicket} isOpen={isModalTicket}>
      <TicketModalContext.Provider
        value={{
          isEditTag,
          isAddingNote,

          setIsEditTag,
          setIsAddingNote,
        }}
      >
        <TicketContainer />
      </TicketModalContext.Provider>
    </PopUpModal>
  );
};

export default Ticket;
