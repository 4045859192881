import React, { useContext } from 'react';
import stylesLayout from '../../components/Layout/Layout.module.css';
import { Helmet } from 'react-helmet-async';
import AsideLinks, { mapWindowStateToText } from './Aside/Aside';
import AsideIcons from './Aside/Icons';
import { PersistentCAdminContext } from '../../components/Layout/Context/PersistentCAdmin';
import Body from './Body/Body';

const CompanyPanel: React.FunctionComponent = (): JSX.Element => {
  const { windowState } = useContext(PersistentCAdminContext);

  return (
    <>
      <Helmet>
        <title>Rhyno | Panel</title>
      </Helmet>
      <div className={stylesLayout.layout}>
        <aside className={stylesLayout.aside}>
          <AsideLinks />
        </aside>
        <section className={stylesLayout.section}>
          <h2 className={stylesLayout.section_title}>
            Panel Admin
            <div className={stylesLayout.section_subtitle}>
              <AsideIcons state={windowState} />
              <span>{mapWindowStateToText.get(windowState)}</span>
            </div>
          </h2>
          <Body />
        </section>
      </div>
    </>
  );
};

export default CompanyPanel;
