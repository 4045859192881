import { MESSAGE } from '../../../../../types/messages/messages.types';
import { MutableRefObject } from 'react';
import { SORTED_MSGS, sortByDate } from './sort';

export const getSortedMsgs = (
  aux: MESSAGE[],
  msgs: MESSAGE[],
  allMsgsToRead: boolean,
  selectedClientId: MutableRefObject<string | null>
): SORTED_MSGS => {
  const res: MESSAGE[] = [];
  const setKeysMsgs: Set<string> = new Set();

  for (let i = 0; i < msgs.length; i++) {
    const msg: MESSAGE = msgs[i];

    // Check if we already have it
    if (setKeysMsgs.has(msg.id)) continue;
    if (msg.client !== selectedClientId.current) continue;

    // We push that msg
    if (allMsgsToRead) {
      res.push({
        ...msg,
        is_read: true,
      });
    } else {
      res.push(msg);
    }
    setKeysMsgs.add(msg.id);
  }

  for (let i = 0; i < aux.length; i++) {
    const msg: MESSAGE = aux[i];

    // Check if we already have it
    if (setKeysMsgs.has(msg.id)) continue;
    if (msg.client !== selectedClientId.current) continue;

    // We push that msg
    if (allMsgsToRead) {
      res.push({
        ...msg,
        is_read: true,
      });
    } else {
      res.push(msg);
    }
    setKeysMsgs.add(msg.id);
  }

  const sortedMsgs: SORTED_MSGS = sortByDate(res);
  return sortedMsgs;
};
