import React, { useContext } from 'react';
import { PersistentAdminContext } from '../../../components/Layout/Context/PersistentAdmin';
import Companies from './Companies/Companies';
import Company from './Company/Company';
import Data from './Data/Data';

const Body: React.FunctionComponent = () => {
  const { windowState } = useContext(PersistentAdminContext);

  return (
    <div>
      {windowState === 'companies' && <Companies />}
      {windowState === 'data' && <Data />}
      {windowState === 'view' && <Company />}
    </div>
  );
};

export default Body;
