import React, { useContext, useState } from 'react';
import PopUpModal from '../PopUp/PopUp';
import styles from './AddAgent.module.css';
import { CPanelLayoutContext } from '../../Layout/CompanyPanel/CompanyPanelLayout';
import { PersistentAgentContext } from '../../Layout/Context/PersistentAgent';
import { AppContext } from '../../../App';
import { LayoutContext } from '../../Layout/Layout';
import { Input, Button, InputGroup, InputRightElement } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import ReactTooltip from 'react-tooltip';
import { createSecurePassword } from '../../../utils/password';
import { fetcher, RESPONSE } from '../../../utils/fetcher';
import {
  createAgent,
  CREATE_USER_BODY,
  CREATE_USER_DATA,
} from '../../../routes/auth.routes';
import { RESPONSE_DATA } from '../../../routes/index.routes';

interface Props {
  password: string;
  callback: (text: string) => void;
}

const CopyClipboard: React.FunctionComponent<Props> = ({
  password,
  callback,
}) => {
  return (
    <>
      <span
        onClick={() => {
          callback(password);
        }}
        data-tip
        data-for="copy-clipboard-agent-new-agent-pswd"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
        </svg>
      </span>
      <ReactTooltip
        id="copy-clipboard-agent-new-agent-pswd"
        effect="solid"
        type="dark"
        place="top"
      >
        Copiar al portapapeles.
      </ReactTooltip>
    </>
  );
};

const AddAgent: React.FunctionComponent = () => {
  const { isModalAddAgent, setIsModalAddAgent } =
    useContext(CPanelLayoutContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const { company } = useContext(AppContext);
  const { setUsersFromCompany } = useContext(PersistentAgentContext);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [realName, setRealName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const close = (): void => {
    setIsModalAddAgent(false);
    clean();
  };

  const clean = (): void => {
    setUsername('');
    setPassword('');
    setRealName('');
    setIsLoading(false);
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (password.length < 8) {
      addStaticMsg(
        'La contraseña debe de tener al menos 8 caracteres.',
        'danger'
      );
      return;
    }

    if (password.length > 32) {
      addStaticMsg(
        'La contraseña no puede tener más de 32 caracteres.',
        'danger'
      );
      return;
    }

    const doFetch = async (): Promise<void> => {
      try {
        if (company === null) return;

        const body: CREATE_USER_BODY = {
          username,
          password,
          name: realName,
          company: company.id,
        };
        setIsLoading(true);
        const res: RESPONSE = await fetcher[createAgent.method]({
          uri: createAgent.url,
          body,
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;
        const status: number = res.status;
        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }
        const data: CREATE_USER_DATA = resData.data;
        setUsersFromCompany(
          (prevMap) => new Map(prevMap.set(data.username, data))
        );
        addStaticMsg('Agente creado con éxito!', 'success');
        close();
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        addStaticMsg('Error al crear un nuevo agente.', 'danger');
        void askToDoRefresh();
      }
    };

    void doFetch();
  };

  const copy = (text: string): void => {
    const wait = async (): Promise<void> => {
      await navigator.clipboard.writeText(text);
      addStaticMsg('Se ha copiado la contraseña al portapapeles!', 'success');
    };
    void wait();
  };

  const createPswd = (): void => {
    const pswd: string = createSecurePassword();
    setPassword(pswd);
    copy(pswd);
  };

  return (
    <PopUpModal
      callbackClose={clean}
      isOpen={isModalAddAgent}
      setIsOpen={setIsModalAddAgent}
    >
      <div className={styles.container}>
        <div className={styles.title}>Nuevo agente:</div>
        <form
          autoComplete="new-password"
          onSubmit={onSubmit}
          className={styles.form}
        >
          <div className={styles.wrapper}>
            <div className={styles.input}>
              <span>Correo electrónico:</span>
              <Input
                type="email"
                autoComplete="new-password"
                placeholder="ejemplo@gmail.com"
                value={username}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUsername(e.target.value);
                }}
                isRequired
              />
            </div>
            <div className={styles.input}>
              <span className={styles.input_span}>
                <div>Contraseña:</div>
                <div className={styles.password}>
                  {password.trim() !== '' && (
                    <CopyClipboard callback={copy} password={password} />
                  )}
                  <span
                    onClick={createPswd}
                    data-tip
                    data-for="add-random-pswd-add-agent"
                    className={styles.add}
                  >
                    +
                  </span>
                  <ReactTooltip
                    id="add-random-pswd-add-agent"
                    effect="solid"
                    type="dark"
                    place="top"
                  >
                    Crear contraseña segura.
                  </ReactTooltip>
                </div>
              </span>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  autoComplete="new-password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Contraseña..."
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                  isRequired
                  maxLength={32}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                  >
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.input}>
              <span>Nombre de usuario:</span>
              <Input
                type="text"
                autoComplete="new-password"
                placeholder="John Doe"
                value={realName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setRealName(e.target.value);
                }}
                isRequired
              />
            </div>
          </div>
          <div className={styles.btns}>
            <Button onClick={close} className={styles.btn} type="button">
              Cancelar
            </Button>
            <Button
              className={`${styles.btn} ${styles.btn_color}`}
              isLoading={isLoading}
              type="submit"
            >
              Guardar
            </Button>
          </div>
        </form>
      </div>
    </PopUpModal>
  );
};

export default AddAgent;
