import styles from './Skeleton.module.css';
import React from 'react';

const SkeletonTagsContainer: React.FunctionComponent = () => {
  return (
    <div className={styles.skeleton}>
      <div className={styles.skeleton_level}>
        <div className={styles.skeleton_body}></div>
        <div className={styles.skeleton_body}></div>
        <div className={styles.skeleton_body}></div>
      </div>
      <div className={styles.skeleton_level}>
        <div className={styles.skeleton_line}></div>
      </div>
      <div className={styles.skeleton_level}>
        <div className={styles.skeleton_body}></div>
      </div>
      <div className={styles.skeleton_level}>
        <div className={styles.skeleton_body}></div>
        <div className={styles.skeleton_body}></div>
      </div>
    </div>
  );
};

export default SkeletonTagsContainer;
