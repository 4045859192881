import { REQUEST } from './index.routes';
import { API_URL } from '../config/routes';

export const sendMessage: REQUEST = {
  url: `${API_URL}/messages`,
  method: 'post',
};

export interface BODY_SEND_MSG {
  client: string;
  content: string;
  company: string;
  type: 'fb_msngr_text'; // Just for now
  skip_chats: number;
  skip_msgs: number;
}
