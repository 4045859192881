import React from 'react';
import styles from './Skeleton.module.css';

const SkeletonChat: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className={styles.skeleton_chat}>
      <div className={styles.skeleton_chat_img}></div>
      <div className={styles.skeleton_chat_info}>
        <div className={styles.skeleton_chat_info_name}></div>
        <div className={styles.skeleton_chat_info_sub}></div>
      </div>
    </div>
  );
};

const Skeleton: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className={styles.skeleton}>
      <SkeletonChat />
      <SkeletonChat />
      <SkeletonChat />
      <SkeletonChat />
    </div>
  );
};

export default Skeleton;
