import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { LINK_ASIDE } from '../../../types/admin/admin.types';
import { COMPANY } from '../../../types/company/company.types';

export const PersistentAdminContext = createContext({} as ValueAdminProvider);

interface ValueAdminProvider {
  windowState: LINK_ASIDE;
  allCompanies: COMPANY[];
  hasFetchCompanies: React.MutableRefObject<boolean>;
  selectedCompany: COMPANY | null;

  setWindowState: Dispatch<SetStateAction<LINK_ASIDE>>;
  setAllCompanies: Dispatch<SetStateAction<COMPANY[]>>;
  setSelectedCompany: Dispatch<SetStateAction<COMPANY | null>>;
}

interface Props {
  children: any;
}

const AdminContextWrapper: React.FunctionComponent<Props> = ({ children }) => {
  const [windowState, setWindowState] = useState<LINK_ASIDE>('companies');
  const [allCompanies, setAllCompanies] = useState<COMPANY[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<COMPANY | null>(null);
  const hasFetchCompanies = useRef<boolean>(false);

  return (
    <PersistentAdminContext.Provider
      value={{
        windowState,
        allCompanies,
        hasFetchCompanies,
        selectedCompany,

        setWindowState,
        setAllCompanies,
        setSelectedCompany,
      }}
    >
      {children}
    </PersistentAdminContext.Provider>
  );
};
export default AdminContextWrapper;
