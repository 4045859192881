export const getHasTriedLogIn = (): boolean => {
  try {
    const store: string | null = sessionStorage.getItem('has-tried-log-in');
    if (store === null) return false;

    return JSON.parse(store);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const setHasTriedLogIn = (): void => {
  try {
    sessionStorage.setItem('has-tried-log-in', JSON.stringify(true));
  } catch (error) {
    console.error(error);
  }
};

export const clearHasTriedLogIn = (): void => {
  try {
    sessionStorage.removeItem('has-tried-log-in');
  } catch (error) {
    console.error(error);
  }
};
