import { useContext } from 'react';
import {
  useNavigate,
  useLocation,
  NavigateFunction,
  Location,
} from 'react-router-dom';
import { AppContext } from '../App';
import { LayoutContext } from '../components/Layout/Layout';
import { fetcher, RESPONSE } from '../utils/fetcher';
import { USER_STATUS, USER } from '../types/user/user.types';
import { getUser, GET_USER_DATA } from '../routes/auth.routes';
import { getHasTriedLogIn, clearHasTriedLogIn } from '../cache/logIn';
import {
  authProtectedRoutes,
  authRestrictedRoute,
  ROUTE_HOME,
  ROUTE_DASHBOARD,
  agentRoutes,
  companyAdminRoutes,
  adminRoutes,
  ROUTE_ADMIN_PANEL,
  ROUTE_SETTINGS,
} from '../config/routes';

export type REFETCH_USER = (callback?: (res: RESPONSE) => any) => Promise<void>;

type USE_AUTH = () => REFETCH_USER;

export const useAuth: USE_AUTH = () => {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const { setIsLoadingGetUser, setCompany, setIsAuth, setUser } =
    useContext(AppContext);
  const { addStaticMsg } = useContext(LayoutContext);

  const fetchUser = async (
    callback?: (res: RESPONSE) => any
  ): Promise<void> => {
    try {
      setIsLoadingGetUser(true);
      const response: RESPONSE = await fetcher[getUser.method]({
        uri: getUser.url,
      });
      setIsLoadingGetUser(false);

      addMsgIfHasTriedLogIn(response.data.isAuth);
      redirectInCaseOfProtectedRoute(response.data.isAuth);
      setIsAuth(response.data.isAuth);

      if (response.data.isAuth) {
        const userData: GET_USER_DATA = response.data.data;
        setUser({
          username: userData.user.username,
          name: userData.user.name,
          company: userData.company.id,
          status: userData.user.status,
          created_at: userData.user.created_at,
          is_active: userData.user.is_active,
        });
        setCompany({
          id: userData.company.id,
          name: userData.company.name,
          created_at: userData.company.created_at,
          is_active: userData.company.is_active,
          max_agents: userData.company.max_agents,
        });
        redirectInCaseOfStatusUser(userData);
      }
      if (callback != null) callback(response);
      return;
    } catch {
      setIsLoadingGetUser(false);
      addMsgIfHasTriedLogIn(false);
      if (location.pathname === ROUTE_HOME) return;
      window.localStorage.clear();
      navigate(ROUTE_HOME);
    }
  };

  const addMsgIfHasTriedLogIn = (isAuth: boolean): void => {
    if (!isAuth && getHasTriedLogIn()) {
      addStaticMsg(
        'La sesión no se guardó correctamente... Intenta checar si no tienes bloqueadas las cookies',
        'danger'
      );
    }

    clearHasTriedLogIn();
  };

  const redirectInCaseOfProtectedRoute = (isAuth: boolean): void => {
    if (!isAuth) {
      // Clear cache
      window.localStorage.clear();

      // Redirect in case of no Auth
      for (let i = 0; i < authProtectedRoutes.length; i++) {
        if (!location.pathname.includes(authProtectedRoutes[i])) continue;

        // Redirect to home if no AUTH
        navigate(ROUTE_HOME);
        return;
      }
      return;
    }

    // Redirect in case of Auth
    if (location.pathname !== authRestrictedRoute) return;

    // Redirect to dashboard because it has AUTH
    navigate(ROUTE_DASHBOARD);
  };

  const isInCompany = (userData: GET_USER_DATA): boolean => {
    const user: USER = userData.user;

    if (user === null) return false;
    if (user.company === '') return false;
    if (user.company === null) return false;
    if (user.company === undefined) return false;
    return userData.company.is_active;
  };

  const redirectInCaseOfStatusUser = (userData: GET_USER_DATA): void => {
    const user: USER = userData.user;

    const status: USER_STATUS = user.status;

    if (status === 'agent') {
      const needsToRedirect: boolean = !agentRoutes.includes(location.pathname);
      if (!needsToRedirect) return;

      // Redirect to dashboard
      // eslint-disable-next-line
      if (userData.company.is_active) {
        navigate(ROUTE_DASHBOARD);
      } else {
        navigate(ROUTE_SETTINGS);
      }
    } else if (status === 'company_admin') {
      const needsToRedirect: boolean = !companyAdminRoutes.includes(
        location.pathname
      );
      if (!needsToRedirect) return;

      // Redirect to panel company admin
      // eslint-disable-next-line
      if (userData.company.is_active) {
        navigate(ROUTE_DASHBOARD);
      } else {
        navigate(ROUTE_SETTINGS);
      }
    } else if (status === 'admin') {
      const needsToRedirect: boolean = !adminRoutes.includes(location.pathname);
      if (!needsToRedirect) return;

      // Redirect to dashboard
      // Check if it has a company
      if (!isInCompany(userData)) {
        navigate(ROUTE_ADMIN_PANEL);
        return;
      }
      // IT has a company
      // eslint-disable-next-line
      if (userData.company.is_active) {
        navigate(ROUTE_DASHBOARD);
      } else {
        navigate(ROUTE_SETTINGS);
      }
    }
  };

  return fetchUser;
};
