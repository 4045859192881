import { REQUEST } from './index.routes';
import { USER } from '../types/user/user.types';
import { METHODS } from '../utils/fetcher';
import { COMPANY } from '../types/company/company.types';
import { API_URL } from '../config/routes';

export const getUser: REQUEST = {
  url: `${API_URL}/v2/user`,
  method: 'get',
};

export interface GET_USER_DATA {
  user: USER;
  company: COMPANY;
}

export const login: REQUEST = {
  url: `${API_URL}/v2/login`,
  method: 'post',
};

export interface LOGIN_BODY {
  username: string;
  password: string;
}

export const logout: REQUEST = {
  url: `${API_URL}/v2/logout`,
  method: 'post',
};

export const createAgent: REQUEST = {
  url: `${API_URL}/v2/user`,
  method: 'post',
};

export interface CREATE_USER_BODY {
  username: string;
  password: string;
  name: string;
  company: string;
}

export interface CREATE_USER_DATA extends USER {}

interface REQUEST_PARAM {
  url: (userId: string) => string;
  method: METHODS;
}

export const updateUser: REQUEST_PARAM = {
  url: (userId: string) => `${API_URL}/v2/user/${userId}`,
  method: 'put',
};

export interface UPDATE_USER_BODY {
  key: 'name' | 'password' | 'is_active';
  value: string | boolean;
  old_password?: string;
}

export interface UPDATE_USER_DATA extends USER {}
