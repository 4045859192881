import React, { useContext } from 'react';
import stylesLayout from '../../components/Layout/Layout.module.css';
import { Helmet } from 'react-helmet-async';
import AsideLinks, { mapWindowStateToText } from './Aside/Aside';
import AsideIcons from './Aside/Icons';
import { PersistentAdminContext } from '../../components/Layout/Context/PersistentAdmin';
import Body from './Body/Body';

const Admin: React.FunctionComponent = (): JSX.Element => {
  const { windowState, selectedCompany, setWindowState, setSelectedCompany } =
    useContext(PersistentAdminContext);

  const getColorCompany = (): string => {
    if (selectedCompany === null) return '#6c727f';
    // eslint-disable-next-line
    if (selectedCompany.is_active) return '#6c727f';
    return '#FF6347';
  };

  return (
    <>
      <Helmet>
        <title>Rhyno | Panel Admin</title>
      </Helmet>
      <div className={stylesLayout.layout}>
        <aside className={stylesLayout.aside}>
          <AsideLinks />
        </aside>
        <section className={stylesLayout.section}>
          <h2 className={stylesLayout.section_title}>
            <div className={stylesLayout.section_title_wrapper}>
              <span>
                <img src="logo-evolutel/evolutel_green.png" alt="Evolutel" />
              </span>
              Rhyno
            </div>
            <div className={stylesLayout.section_subtitle}>
              <AsideIcons state={windowState} />
              <span
                onClick={() => {
                  setSelectedCompany(null);
                  setWindowState('companies');
                }}
                className={`${
                  windowState === 'view' && stylesLayout.span_click
                }`}
              >
                {mapWindowStateToText.get(windowState)}
              </span>
              <span
                style={{
                  color: getColorCompany(),
                }}
              >
                {windowState === 'view' && ` / ${selectedCompany?.name}`}
              </span>
            </div>
          </h2>
          <Body />
        </section>
      </div>
    </>
  );
};

export default Admin;
