import React, { useState } from 'react';
import { fetcher, RESPONSE } from '../../utils/fetcher';
import { login, LOGIN_BODY } from '../../routes/auth.routes';
import { useAuth } from '../../hooks/useAuth';
import CardLogIn from '../../components/LogIn/Card';
import { Helmet } from 'react-helmet-async';
import { setHasTriedLogIn } from '../../cache/logIn';

const LogIn: React.FunctionComponent = (): JSX.Element => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [msgInput, setMsgInput] = useState<string>('');

  const fetchUser = useAuth();

  const handleLogIn = (e: React.FormEvent): void => {
    e.preventDefault();

    const bodyLogin: LOGIN_BODY = {
      username,
      password,
    };

    const doFetch = async (): Promise<void> => {
      try {
        setIsLoading(true);
        setMsgInput('');
        const res: RESPONSE = await fetcher[login.method]({
          uri: login.url,
          body: bodyLogin,
        });
        setIsLoading(false);
        const resData = res.data;

        if (!resData.isAuth) {
          setMsgInput(resData.msg);
        }
        if (res.status !== 200) {
          setMsgInput(resData.msg);
          return;
        }

        // In this case we think its successful
        setHasTriedLogIn();
        setIsLoading(true);
        await fetchUser();
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setMsgInput('Error al iniciar sesión');
        setIsLoading(false);
      }
    };

    void doFetch();
  };

  return (
    <>
      <Helmet>
        <title>Rhyno</title>
      </Helmet>
      <section>
        <CardLogIn
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          handleLogIn={handleLogIn}
          msgInput={msgInput}
          isLoading={isLoading}
        />
      </section>
    </>
  );
};

export default LogIn;
