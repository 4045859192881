import { USER } from '../types/user/user.types';

export type KEY_VAL_USER = Map<string, USER>;

type GET_AGENTS_NAME = () => KEY_VAL_USER;

export const convertArrayUsersToObject = (arr: USER[]): KEY_VAL_USER => {
  const res: KEY_VAL_USER = new Map();
  for (let i = 0; i < arr.length; i++) {
    const user: USER = arr[i];
    res.set(user.username, user);
  }
  return res;
};

export const getUsersFromCompany: GET_AGENTS_NAME = (): KEY_VAL_USER => {
  try {
    const store: string | null = localStorage.getItem('users-company');
    if (store === null) return new Map();
    return new Map(JSON.parse(store));
  } catch (error) {
    console.error(error);
    return new Map();
  }
};

type SET_USERS = (values: KEY_VAL_USER) => void;
export const setUsersFromCompany: SET_USERS = (values): void => {
  try {
    if (typeof values !== 'object') return;
    localStorage.setItem(
      'users-company',
      JSON.stringify(Array.from(values.entries()))
    );
  } catch (error) {
    console.error(error);
  }
};

type GET_LAST_HYDRATION = () => Date | null;
export const getLastHydrationUsersFromCompany: GET_LAST_HYDRATION =
  (): Date | null => {
    try {
      const store: string | null = localStorage.getItem(
        'users-company-last-hydration'
      );
      if (store === null) return null;

      return JSON.parse(store);
    } catch (error) {
      console.error(error);
      return null;
    }
  };

type SET_LAST_HYDRATION = () => void;
export const setLastHydrationUsersFromCompany: SET_LAST_HYDRATION =
  (): void => {
    try {
      localStorage.setItem(
        'users-company-last-hydration',
        JSON.stringify(new Date())
      );
    } catch (error) {
      console.error(error);
    }
  };
