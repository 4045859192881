export const createSecurePassword = (): string => {
  const numbers: string = '0123456789';
  const letters: string =
    'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';
  const specialChars: string = '!#%$&@.+-_?;';

  const proportionNumbers: number = 3;
  const proportionLetters: number = 9;
  const proportionSpecialChars: number = 3;

  const pswd: string[] = [];

  for (let i = 0; i < proportionLetters; i++) {
    const randomIndexLetters: number = Math.floor(
      Math.random() * letters.length
    );
    const randomIndexToInsert: number = Math.floor(Math.random() * pswd.length);
    const letter: string = letters[randomIndexLetters];
    pswd.splice(randomIndexToInsert, 0, letter);
  }
  for (let i = 0; i < proportionNumbers; i++) {
    const randomIndexNumbers: number = Math.floor(
      Math.random() * numbers.length
    );
    const randomIndexToInsert: number = Math.floor(Math.random() * pswd.length);
    const number: string = numbers[randomIndexNumbers];
    pswd.splice(randomIndexToInsert, 0, number);
  }
  for (let i = 0; i < proportionSpecialChars; i++) {
    const randomIndexSpecialChar: number = Math.floor(
      Math.random() * specialChars.length
    );
    const randomIndexToInsert: number = Math.floor(Math.random() * pswd.length);
    const specialChar: string = specialChars[randomIndexSpecialChar];
    pswd.splice(randomIndexToInsert, 0, specialChar);
  }

  return pswd.join('');
};
