import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import Nav from '../../Nav/CompanyAdmin/CompanyAdminNav';
import { Outlet } from 'react-router-dom';
import styles from './CompanyPanelLayout.module.css';
import AddTagModal from '../../Modals/AddTag/AddTag';
import { USER } from '../../../types/user/user.types';
import AddAgentModal from '../../Modals/AddAgent/AddAgent';
import EditAgentModal from '../../Modals/EditAgent/EditAgent';
import AddTemplateModal from '../../Modals/AddTemplate/AddTemplate';

// Context
export const CPanelLayoutContext = createContext({} as CPanelValueProvider);

interface CPanelValueProvider {
  isModalAddTag: boolean;
  isModalAddAgent: boolean;
  isModalEditAgent: USER | null;
  isModalAddTemplate: boolean;

  setIsModalAddTag: Dispatch<SetStateAction<boolean>>;
  setIsModalAddAgent: Dispatch<SetStateAction<boolean>>;
  setIsModalEditAgent: Dispatch<SetStateAction<USER | null>>;
  setIsModalAddTemplate: Dispatch<SetStateAction<boolean>>;
}

const CompanyPanelLayout: React.FunctionComponent = (): JSX.Element => {
  const [isModalAddTag, setIsModalAddTag] = useState<boolean>(false);
  const [isModalAddAgent, setIsModalAddAgent] = useState<boolean>(false);
  const [isModalEditAgent, setIsModalEditAgent] = useState<USER | null>(null);
  const [isModalAddTemplate, setIsModalAddTemplate] = useState<boolean>(false);

  return (
    <CPanelLayoutContext.Provider
      value={{
        isModalAddTag,
        isModalAddAgent,
        isModalEditAgent,
        isModalAddTemplate,

        setIsModalAddTag,
        setIsModalAddAgent,
        setIsModalEditAgent,
        setIsModalAddTemplate,
      }}
    >
      <AddTagModal />
      <AddAgentModal />
      <EditAgentModal />
      <AddTemplateModal />
      <div className={styles.layout}>
        <Nav />
        <Outlet />
      </div>
    </CPanelLayoutContext.Provider>
  );
};

export default CompanyPanelLayout;
