import React from 'react';
import styles from './LandingNav.module.css';

const LandingNav: React.FunctionComponent = (): JSX.Element => {
  return (
    <nav className={styles.nav}>
      <a href="https://www.evolutel.mx/" target="_blank" rel="noreferrer">
        <img width="70px" src="logo-evolutel/evolutel.svg" alt="Evolutel" />
      </a>
    </nav>
  );
};

export default LandingNav;
