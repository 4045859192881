import React, { useContext } from 'react';
import styles from './Message.module.css';
import { PersistentAgentContext } from '../../../../../components/Layout/Context/PersistentAgent';
import { useCache } from '../../../../../hooks/useCache';
import { MESSAGE } from '../../../../../types/messages/messages.types';
import {
  getDateFormat,
  getHourFormat,
} from '../../../../../utils/dates/format';
import { CheckIcon } from '@chakra-ui/icons';
import { TEMP_ID_PUSH_MSGS } from '../../../../../config/constraints';
import { USER } from '../../../../../types/user/user.types';

interface Props {
  msg: MESSAGE;
}

const Message: React.FunctionComponent<Props> = ({ msg }): JSX.Element => {
  const { selectedClientId, usersFromCompany } = useContext(
    PersistentAgentContext
  );
  const { populateUsersFromCompany } = useCache();

  const isTemporal = (): boolean => {
    return msg.id.split(':')[0] === TEMP_ID_PUSH_MSGS;
  };

  const getRealNameAgent = (): string => {
    if (msg.from_agent === null) return '';
    if (msg.from_agent === '') return '';

    const realName: USER | undefined = usersFromCompany.get(msg.from_agent);
    if (realName === undefined) {
      if (!msg.from_client) {
        populateUsersFromCompany(true);
      }
      return msg.from_agent;
    }
    return realName.name;
  };

  if (selectedClientId.current !== msg.client) return <></>;

  return (
    <div
      className={`${styles.msg} ${
        msg.from_client ? styles.msg_client : styles.msg_mine
      }`}
    >
      {isTemporal() && <div className={styles.msg_temp}></div>}
      {msg.from_agent !== null && (
        <div className={styles.msg_agent}>{getRealNameAgent()}</div>
      )}
      <div className={styles.msg_text}>{msg.content}</div>
      <div className={styles.msg_below}>
        <div className={styles.msg_date}>
          {getDateFormat(msg.created_at)} {getHourFormat(msg.created_at)}
        </div>
        {!msg.from_client && msg.is_read && (
          <div className={styles.msg_read}>
            <CheckIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;
