import { useContext } from 'react';
import { PersistentAgentContext } from '../components/Layout/Context/PersistentAgent';
import { LayoutContext } from '../components/Layout/Layout';
import { AppContext } from '../App';
import { DEBUG } from '../config/routes';
import {
  getLastHydrationUsersFromCompany,
  setUsersFromCompany as setUsersFromCompanyCache,
  getUsersFromCompany,
  setLastHydrationUsersFromCompany,
  convertArrayUsersToObject,
  KEY_VAL_USER,
} from '../cache/agents';
import {
  getUsersFromCompanyRoute,
  DATA_GET_USERS_COMPANY,
} from '../routes/cache.routes';
import { RESPONSE_DATA } from '../routes/index.routes';
import { fetcher, RESPONSE } from '../utils/fetcher';

interface RETURN_HOOK {
  populateUsersFromCompany: (force?: boolean) => void;
}
type HOOK = () => RETURN_HOOK;

export const useCache: HOOK = () => {
  const { setUsersFromCompany } = useContext(PersistentAgentContext);
  const { addAsyncMsg, addStaticMsg } = useContext(LayoutContext);
  const { company } = useContext(AppContext);

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const populateUsersFromCompany = (force: boolean = false): void => {
    const lastHydration: Date | null = getLastHydrationUsersFromCompany();
    const doFetch = async (): Promise<void> => {
      try {
        if (company === null) return;
        if (DEBUG) {
          console.log(
            '%cPopulating agentsRealName...',
            'color:#FF00FF; font-size: 32px;'
          );
        }
        setLastHydrationUsersFromCompany();
        const res: RESPONSE = await fetcher[getUsersFromCompanyRoute.method]({
          uri: getUsersFromCompanyRoute.url(company.id),
        });
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const data: DATA_GET_USERS_COMPANY = resData.data;

        const valueForCache: KEY_VAL_USER = convertArrayUsersToObject(
          data.users
        );
        setUsersFromCompanyCache(valueForCache);
        setUsersFromCompany(getUsersFromCompany());
      } catch (error) {
        console.error(error);
        void askToDoRefresh();
      }
    };

    if (force) {
      void doFetch();
    } else if (lastHydration === null) {
      void doFetch();
    } else {
      const today: Date = new Date();
      const diff = today.getTime() - new Date(lastHydration).getTime();

      // To calculate the no. of days between two dates
      const diffDays = diff / (1000 * 3600 * 24);
      if (diffDays >= 1) {
        void doFetch();
      } else {
        // If it never updated
        setUsersFromCompany(getUsersFromCompany());
      }
    }
  };

  return {
    populateUsersFromCompany,
  };
};
