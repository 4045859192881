import React, { useContext, useState, useRef } from 'react';
import styles from './Element.module.css';
import { fetcher } from '../../../../utils/fetcher';
import {
  updateClient,
  BODY_UPDATE_CLIENT,
} from '../../../../routes/clients.routes';
import { PersistentAgentContext } from '../../../../components/Layout/Context/PersistentAgent';
import { LayoutContext } from '../../../../components/Layout/Layout';
import { AppContext } from '../../../../components/../App';
import { MAX_LENGTH_WHATSAPP } from '../../../../config/constraints';
import { Spinner } from '@chakra-ui/react';
import { TICKET_TYPES } from '../../../../types/ticket/ticket.types';

type REJECT_UPDATES = 'blank' | 'length_whatsapp' | true;

interface PropsInfo {
  label: string;
  value: string | boolean | number | null;
  labelKey?: string;
  type?: 'text' | 'email' | TICKET_TYPES;
  onClick?: () => void;
  isLoading?: boolean;
  className?: string;
  isMongo?: boolean;
}

const InfoElement: React.FunctionComponent<PropsInfo> = ({
  label,
  value,
  onClick = null,
  labelKey = undefined,
  type = 'text',
  isLoading: isLoadingExt = false, // Only if onClick is func
  className = '',
  isMongo = false,
}): JSX.Element => {
  const { selectedClient } = useContext(PersistentAgentContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const { company } = useContext(AppContext);
  const [newValue, setNewValue] = useState<string | boolean | number | null>(
    value
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateSuccess = useRef<boolean>(false);

  const canUpdate = (): REJECT_UPDATES => {
    if (newValue === null) return 'blank';

    if (typeof newValue !== 'string') {
      return true;
    }

    // For string
    if (newValue.trim() === '') return 'blank';

    if (labelKey !== 'whatsapp') {
      return true;
    }

    // For WhatsApp
    if (newValue.length > MAX_LENGTH_WHATSAPP) return 'length_whatsapp';

    return true;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    updateSuccess.current = false;

    if (value === newValue) {
      // If its the same... dont do anything
      return;
    }

    if (canUpdate() === 'length_whatsapp') {
      addStaticMsg(
        `No puedes escibir un número mayor a ${MAX_LENGTH_WHATSAPP} characteres`,
        'danger'
      );
      setNewValue(value);
      return;
    }

    if (canUpdate() === 'blank' && labelKey === 'name') {
      addStaticMsg(`No puedes dejar vacío el nombre`, 'danger');
      setNewValue(value);
      return;
    }

    const askToDoRefresh = async (): Promise<void> => {
      const doRefresh: boolean = await addAsyncMsg(
        '¿Quiéres recargar la página?'
      );
      if (doRefresh) {
        location.reload();
      }
    };

    const doFetch = async (): Promise<void> => {
      try {
        updateSuccess.current = true;
        if (labelKey === undefined) return;
        if (selectedClient === null) return;
        if (newValue === null) return;
        if (company === null) return;

        let actualV: any = newValue;
        if (type === 'float') {
          actualV = parseFloat(actualV);
        }
        if (type === 'int') {
          actualV = parseInt(actualV);
        }

        const body: BODY_UPDATE_CLIENT = {
          client: selectedClient.id,
          company: company.id,
          key: labelKey,
          value: actualV,
          update: 'info',
        };

        setIsLoading(true);
        const res = await fetcher[updateClient.method]({
          uri: updateClient.url,
          body,
        });
        setIsLoading(false);
        const resData = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }
      } catch (error) {
        setIsLoading(false);
        addStaticMsg(
          'Error al actualizar la información del cliente',
          'danger'
        );
        void askToDoRefresh();
        console.error(error);
      }
    };

    if (
      canUpdate() === 'blank' &&
      (labelKey === 'email' || labelKey === 'whatsapp' || isMongo)
    ) {
      const ask = async (): Promise<void> => {
        updateSuccess.current = true;
        const res: boolean = await addAsyncMsg(
          '¿Estás seguro que quieres dejar este campo vacío?'
        );
        if (!res) {
          updateSuccess.current = false;
          setNewValue(value);
          return;
        }
        void doFetch();
      };
      void ask();
      return;
    }

    void doFetch();
  };

  function containsOnlyNumbers(str: string): boolean {
    return /^\d+$/.test(str);
  }

  const getValueFromField = (): any => {
    if (newValue === null) return '';
    return newValue;
  };

  return (
    <form
      onBlur={() => {
        if (isLoading) return;
        if (updateSuccess.current) return;
        setNewValue(value);
      }}
      onSubmit={onSubmit}
      className={`${styles.info} ${className}`}
    >
      <div>{label}</div>
      {onClick !== null && (
        <div
          onClick={() => {
            if (isLoadingExt) return;
            if (onClick !== null) {
              onClick();
            }
          }}
          className={`${styles.info_value} ${styles.info_value_click} ${
            !isLoadingExt && styles.info_value_click_hover
          }`}
        >
          {isLoadingExt && <Spinner className={styles.spinner} />}
          {!isLoadingExt && value}
        </div>
      )}
      {onClick === null && (
        <>
          {(type === 'text' || type === 'email') && (
            <input
              className={`${styles.info_value} ${styles.info_value_input} ${
                isLoading && styles.info_value_input_loading
              }`}
              autoComplete="new-password"
              value={getValueFromField()}
              disabled={isLoading}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateSuccess.current = false;
                setNewValue(e.target.value);
              }}
              type={type}
            />
          )}
          {type === 'int' && (
            <input
              className={`${styles.info_value} ${styles.info_value_input} ${
                isLoading && styles.info_value_input_loading
              }`}
              autoComplete="new-password"
              value={getValueFromField()}
              disabled={isLoading}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateSuccess.current = false;
                if (!containsOnlyNumbers(e.target.value)) return;
                setNewValue(e.target.value);
              }}
              type="number"
              step={1}
            />
          )}
          {type === 'float' && (
            <input
              className={`${styles.info_value} ${styles.info_value_input} ${
                isLoading && styles.info_value_input_loading
              }`}
              autoComplete="new-password"
              value={getValueFromField()}
              disabled={isLoading}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateSuccess.current = false;
                if (isNaN(parseInt(e.target.value))) return;
                setNewValue(e.target.value);
              }}
              type="number"
              step={0.1}
            />
          )}
          {type === 'bool' && (
            <button
              disabled={isLoading}
              className={styles.btn_bool}
              type="submit"
              onClick={() => {
                // eslint-disable-next-line
                setNewValue((prev) => !prev);
              }}
            >
              {isLoading && <Spinner />}
              {!isLoading && <>{newValue === true ? 'Sí' : 'No'}</>}
            </button>
          )}
        </>
      )}
    </form>
  );
};

export default InfoElement;
