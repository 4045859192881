import { TICKET_SCHEMA } from '../../../../../types/ticket/ticket.types';

export const sortTemplates = (templates: TICKET_SCHEMA[]): TICKET_SCHEMA[] => {
  if (templates.length === 0) return [];

  templates.sort((a: TICKET_SCHEMA, b: TICKET_SCHEMA) => {
    if (a.created_at < b.created_at) {
      return 1;
    }
    if (a.created_at > b.created_at) {
      return -1;
    }
    return 0;
  });

  return templates;
};
