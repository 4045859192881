import React, { useContext, useEffect } from 'react';
import styles from './Chat.module.css';
import UserChat from './UserChat/UserChat';
import { CLIENT_ASIDE } from '../../../types/client/chat.types';
import { DashboardContext } from '../Dashboard';
import { DashboardLayoutContext } from '../../../components/Layout/Dashboard/DashboardLayout';
import { AppContext } from '../../../App';
import { PersistentAgentContext } from '../../../components/Layout/Context/PersistentAgent';
import { updateThisChatRead } from './utils/chats';
import { DEBUG } from '../../../config/routes';
import Skeleton from './Skeleton/Skeleton';

let emitController: boolean = false;

const Chat: React.FunctionComponent = (): JSX.Element => {
  const { isLoadingClients, setIsLoadingClients } =
    useContext(DashboardContext);
  const { controllerOnChats } = useContext(DashboardLayoutContext);
  const { company } = useContext(AppContext);
  const {
    socket,
    chats,
    setChats,
    selectedClientIdState,
    setSelectedClientIdState,
  } = useContext(PersistentAgentContext);

  useEffect(() => {
    if (socket === null) return;
    if (socket.current === null) return;
    if (controllerOnChats.current) return;
    controllerOnChats.current = true;

    socket.current.on('clients', () => {
      setIsLoadingClients(false);
    });
  }, [socket, socket?.current]);

  useEffect(() => {
    if (socket === null) return;
    if (socket.current === null) return;
    if (company === null) return;
    if (emitController) return;
    emitController = true;

    if (DEBUG) {
      console.log('%cEmit get clients...', 'color:#0000FF; font-size: 32px;');
    }
    setIsLoadingClients(true);
    socket.current.emit('get clients', { company: company.id, skip: 0 });
  }, [socket, company, socket?.current]);

  if (isLoadingClients) {
    return (
      <div className={`${styles.chat} ${styles.chat_skeleton}`}>
        <Skeleton />
      </div>
    );
  }

  const markAsReaded = (clientId: string, unreadValue: number = 0): void => {
    setChats((prev) => updateThisChatRead(prev, clientId, unreadValue));
  };

  return (
    <div className={styles.chat}>
      {chats.map((user: CLIENT_ASIDE, index: number) => {
        return (
          <React.Fragment key={index}>
            <UserChat
              isSelected={selectedClientIdState === user.client_id}
              user={user}
              click={setSelectedClientIdState}
              markAsReaded={markAsReaded}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Chat;
