import { TICKET, NOTE } from '../../../../types/ticket/ticket.types';

export const sortTickets = (tickets: TICKET[]): TICKET[] => {
  if (tickets.length === 0) return [];

  tickets.sort((a: TICKET, b: TICKET) => {
    if (a.created_at < b.created_at) {
      return 1;
    }
    if (a.created_at > b.created_at) {
      return -1;
    }
    return 0;
  });

  return tickets;
};

export const sortNotes = (notes: NOTE[]): NOTE[] => {
  if (notes.length === 0) return [];

  notes.sort((a: NOTE, b: NOTE) => {
    if (a.created_at < b.created_at) {
      return 1;
    }
    if (a.created_at > b.created_at) {
      return -1;
    }
    return 0;
  });

  return notes;
};
