import React from 'react';
import styles from './Skeleton.module.css';

const SkeletonNotes: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className={styles.skeleton_notes}>
      <div className={styles.skeleton_notes_left}></div>
      <div className={styles.skeleton_notes_right}></div>
    </div>
  );
};

const SkeletonTitleNotes: React.FunctionComponent = (): JSX.Element => {
  return <div className={styles.skeleton_title_notes}></div>;
};

const Skeleton: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className={styles.skeleton}>
      <SkeletonTitleNotes />
      <SkeletonNotes />
      <SkeletonNotes />
      <SkeletonNotes />
      <SkeletonNotes />
    </div>
  );
};

export default Skeleton;
