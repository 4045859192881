import { REQUEST } from './index.routes';
import { API_URL } from '../config/routes';
import { METHODS } from '../utils/fetcher';
import { COMPANY } from '../types/company/company.types';

interface REQUEST_PARAM {
  url: (companyId: string) => string;
  method: METHODS;
}

export const getAllCompanies: REQUEST = {
  url: `${API_URL}/v1/companies`,
  method: 'get',
};

export type DATA_GET_ALL_COMPANIES = COMPANY[];

export const createCompany: REQUEST = {
  url: `${API_URL}/v1/companies`,
  method: 'post',
};

export interface BODY_CREATE_COMPANY {
  name: string;
  admin_username: string;
  admin_name: string;
  admin_password: string;
}

export interface DATA_CREATE_COMPANY extends COMPANY {}

export const updateCompany: REQUEST_PARAM = {
  url: (companyId: string) => `${API_URL}/v1/company/${companyId}`,
  method: 'put',
};

export interface BODY_UPDATE_COMPANY {
  key: 'name' | 'max_agents' | 'is_active';
  value: string | number | boolean;
}

export interface DATA_UPDATE_COMPANY extends COMPANY {}
