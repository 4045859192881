import styles from './Company.module.css';
import React, { useContext, useState } from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import { PersistentAdminContext } from '../../../../components/Layout/Context/PersistentAdmin';
import { LayoutContext } from '../../../../components/Layout/Layout';
import { AppContext } from '../../../../App';
import ReactTooltip from 'react-tooltip';
import { Input, Button } from '@chakra-ui/react';
import { fetcher, RESPONSE } from '../../../../utils/fetcher';
import { RESPONSE_DATA } from '../../../../routes/index.routes';
import {
  updateCompany,
  BODY_UPDATE_COMPANY,
  DATA_UPDATE_COMPANY,
} from '../../../../routes/company.routes';
import { COMPANY } from '../../../../types/company/company.types';

const Company: React.FunctionComponent = () => {
  const {
    setWindowState,
    setSelectedCompany,
    selectedCompany,
    setAllCompanies,
  } = useContext(PersistentAdminContext);
  const { addAsyncMsg, addStaticMsg } = useContext(LayoutContext);
  const { company, setCompany } = useContext(AppContext);

  const getAgents = (): number => {
    if (selectedCompany === null) return 0;
    return selectedCompany.max_agents;
  };
  const getNameC = (): string => {
    if (selectedCompany === null) return '';
    return selectedCompany.name;
  };

  const [maxAgents, setMaxAgents] = useState<number>(getAgents());
  const [nameCompany, setNameCompany] = useState<string>(getNameC());
  const [isLoadingActive, setIsLoadingActive] = useState<boolean>(false);
  const [isLoadingAgents, setIsLoadingAgents] = useState<boolean>(false);
  const [isLoadingName, setIsLoadingName] = useState<boolean>(false);

  const [isEditName, setIsEditName] = useState<boolean>(false);

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const askToDoDeactivateCompany = async (): Promise<boolean> => {
    const doIt: boolean = await addAsyncMsg(
      '¿Estás seguro que quieres restringir el acceso a esta empresa?'
    );
    return doIt;
  };

  const updateCompanyController = (
    prev: COMPANY[],
    curr: COMPANY
  ): COMPANY[] => {
    const aux: COMPANY[] = [];

    for (let i = 0; i < prev.length; i++) {
      const c: COMPANY = prev[i];
      if (c.id !== curr.id) {
        aux.push(c);
        continue;
      }

      aux.push(curr);
    }

    return aux;
  };

  const updateName = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (selectedCompany === null) return;

        if (nameCompany.trim() === '') {
          addStaticMsg('No puedes dejar el nombre vacío', 'danger');
          return;
        }
        if (nameCompany === getNameC()) {
          return;
        }

        const body: BODY_UPDATE_COMPANY = {
          key: 'name',
          value: nameCompany,
        };
        setIsLoadingName(true);
        const res: RESPONSE = await fetcher[updateCompany.method]({
          uri: updateCompany.url(selectedCompany.id),
          body,
        });
        setIsLoadingName(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const data: DATA_UPDATE_COMPANY = resData.data;
        setAllCompanies((prev) => updateCompanyController(prev, data));
        setSelectedCompany(data);

        addStaticMsg('Se ha actualizado la empresa con éxito!', 'success');
        setIsEditName(false);
        if (company !== null) {
          if (company.id === data.id) {
            setCompany(data);
          }
        }
      } catch (error) {
        setIsLoadingName(false);
        console.error(error);
        addStaticMsg('Hubo un error al actualizar la empresa.', 'danger');
        void askToDoRefresh();
      }
    };
    void doFetch();
  };

  const updateAgents = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (selectedCompany === null) return;
        if (maxAgents <= 0) {
          addStaticMsg('El mínimo de agentes debe ser 1', 'danger');
          return;
        }

        const body: BODY_UPDATE_COMPANY = {
          key: 'max_agents',
          value: maxAgents,
        };
        setIsLoadingAgents(true);
        const res: RESPONSE = await fetcher[updateCompany.method]({
          uri: updateCompany.url(selectedCompany.id),
          body,
        });
        setIsLoadingAgents(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const data: DATA_UPDATE_COMPANY = resData.data;
        setAllCompanies((prev) => updateCompanyController(prev, data));
        setSelectedCompany(data);

        addStaticMsg('Se ha actualizado la empresa con éxito!', 'success');
        if (company !== null) {
          if (company.id === data.id) {
            setCompany(data);
          }
        }
      } catch (error) {
        setIsLoadingAgents(false);
        console.error(error);
        addStaticMsg('Hubo un error al actualizar la empresa.', 'danger');
        void askToDoRefresh();
      }
    };
    void doFetch();
  };

  const updateActive = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (selectedCompany === null) return;
        const val: boolean = selectedCompany.is_active;
        const valueToUpdate: boolean = !val;

        if (!valueToUpdate) {
          const doIt: boolean = await askToDoDeactivateCompany();
          if (!doIt) return;
        }

        const body: BODY_UPDATE_COMPANY = {
          key: 'is_active',
          value: valueToUpdate,
        };
        setIsLoadingActive(true);
        const res: RESPONSE = await fetcher[updateCompany.method]({
          uri: updateCompany.url(selectedCompany.id),
          body,
        });
        setIsLoadingActive(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const data: DATA_UPDATE_COMPANY = resData.data;
        setAllCompanies((prev) => updateCompanyController(prev, data));
        setSelectedCompany(data);

        addStaticMsg(
          'Se han actualizado los permisos de la empresa con éxito!',
          'success'
        );
        if (company !== null) {
          if (company.id === data.id) {
            setCompany(data);
          }
        }
      } catch (error) {
        setIsLoadingActive(false);
        console.error(error);
        addStaticMsg(
          'Hubo un error al actualizar los permisos de la empresa.',
          'danger'
        );
        void askToDoRefresh();
      }
    };
    void doFetch();
  };

  const returnState = (): void => {
    setSelectedCompany(null);
    setWindowState('companies');
  };

  return (
    <div className={styles.company}>
      <div className={styles.company_top}>
        <div onClick={returnState} className={styles.company_top_left}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
          </svg>
          <span>volver</span>
        </div>
        <div className={styles.company_top_left}>
          <div
            className={styles.info}
            data-tip
            data-for="info-admin-company-view"
          >
            <InfoIcon />
          </div>
          <ReactTooltip
            id="info-admin-company-view"
            effect="solid"
            type="dark"
            place="top"
          >
            Aquí puedes habilitar/deshabilitar la empresa y restringir el número
            de agentes.
          </ReactTooltip>
        </div>
      </div>
      <div
        className={`${
          selectedCompany !== null &&
          // eslint-disable-next-line
          !selectedCompany.is_active &&
          styles.block
        }`}
      >
        <div className={styles.name}>
          {selectedCompany?.name}
          <span
            onClick={() => {
              setIsEditName((prev) => !prev);
              setNameCompany(getNameC());
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
            </svg>
          </span>
        </div>
        {isEditName && (
          <div className={styles.edit_name}>
            <div className={styles.edit_name_top}>
              <Input
                type="text"
                autoComplete="new-password"
                placeholder="Nombre"
                value={nameCompany}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNameCompany(e.target.value);
                }}
                isRequired
              />
            </div>
            <div className={styles.edit_name_btns}>
              <Button
                onClick={() => {
                  setIsEditName(false);
                }}
                type="button"
              >
                Cancelar
              </Button>
              <Button
                onClick={updateName}
                className={styles.btn_blue}
                isLoading={isLoadingName}
                type="button"
              >
                Guardar
              </Button>
            </div>
          </div>
        )}
        <div className={styles.allowed}>
          <div>Agentes permitidos:</div>
          <Input
            type="text"
            autoComplete="new-password"
            placeholder="Máximo de agentes"
            value={maxAgents}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value: any = e.target.value;
              if (isNaN(value)) {
                return;
              }
              if (value.trim() === '') {
                setMaxAgents(1);
                return;
              }
              setMaxAgents(parseInt(value));
            }}
            isRequired
            disabled={
              // eslint-disable-next-line
              selectedCompany !== null && !selectedCompany.is_active
            }
          />
          <Button
            onClick={updateAgents}
            className={`${styles.btn_blue} ${styles.agents_save} ${
              selectedCompany?.max_agents === maxAgents &&
              styles.agents_save_not
            }`}
            isLoading={isLoadingAgents}
            type="button"
          >
            Guardar
          </Button>
        </div>
      </div>
      {company !== null &&
        selectedCompany !== null &&
        company.id !== selectedCompany.id && (
          <div className={styles.active}>
            <div className={styles.active_title}>Permisos</div>
            {
              // eslint-disable-next-line
              selectedCompany?.is_active === true && (
                <Button
                  onClick={updateActive}
                  className={styles.btn}
                  isLoading={isLoadingActive}
                  type="button"
                >
                  Restringir acceso
                </Button>
              )
            }
            {
              // eslint-disable-next-line
              selectedCompany?.is_active === false && (
                <Button
                  onClick={updateActive}
                  className={styles.btn_blue}
                  isLoading={isLoadingActive}
                  type="button"
                >
                  Habilitar acceso
                </Button>
              )
            }
          </div>
        )}
    </div>
  );
};

export default Company;
