import styles from './AdminNav.module.css';
import stylesGlobal from '../Nav.module.css';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../App';
import {
  ROUTE_SETTINGS,
  ROUTE_COMPANY_ADMIN_PANEL,
  ROUTE_DASHBOARD,
} from '../../../config/routes';

const AdminNav: React.FunctionComponent = () => {
  const { user, company } = useContext(AppContext);

  const isInCompany = (): boolean => {
    if (user === null) return false;
    if (user.company === '') return false;
    if (user.company === null) return false;
    if (user.company === undefined) return false;
    if (company === null) return false;
    return company.is_active;
  };

  return (
    <nav className={styles.nav}>
      <div className={stylesGlobal.nav_aside}>
        <div className={stylesGlobal.nav_aside_title}>Admin</div>
      </div>
      <div className={stylesGlobal.nav_section}>
        {isInCompany() && (
          <>
            <Link to={ROUTE_COMPANY_ADMIN_PANEL}>Panel admin</Link>
            <Link to={ROUTE_DASHBOARD}>Dashboard</Link>
          </>
        )}
        <Link to={ROUTE_SETTINGS}>Ajustes</Link>
      </div>
    </nav>
  );
};
export default AdminNav;
