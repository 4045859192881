import React from 'react';
import { LINK_ASIDE } from '../../../types/cpanel/cpanel.types';

const TagsIcon: React.FunctionComponent = () => {
  return (
    <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.42349V5.86628C0 6.37148 0.199108 6.85587 0.555721 7.21249L5.78603 12.4428C6.52897 13.1857 7.73254 13.1857 8.47548 12.4428L12.4428 8.47549C13.1857 7.73254 13.1857 6.52898 12.4428 5.78604L7.21248 0.555731C6.85587 0.199119 6.37147 1.03248e-05 5.86627 1.03248e-05H1.42645C0.63893 -0.00296144 0 0.635968 0 1.42349ZM3.32838 4.27638C2.80238 4.27638 2.37741 3.85142 2.37741 3.32542C2.37741 2.79941 2.80238 2.37445 3.32838 2.37445C3.85438 2.37445 4.27935 2.79941 4.27935 3.32542C4.27935 3.85142 3.85438 4.27638 3.32838 4.27638Z" />
    </svg>
  );
};
const AgentsIcon: React.FunctionComponent = () => {
  return (
    <svg viewBox="0 0 15 17" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.125 8.14286C9.37383 8.14286 11.1964 6.32026 11.1964 4.07143C11.1964 1.8226 9.37383 0 7.125 0C4.87617 0 3.05357 1.8226 3.05357 4.07143C3.05357 6.32026 4.87617 8.14286 7.125 8.14286ZM5.67137 9.66964C2.53828 9.66964 0 12.2079 0 15.341C0 15.8627 0.423047 16.2857 0.944699 16.2857H13.3053C13.827 16.2857 14.25 15.8627 14.25 15.341C14.25 12.2079 11.7117 9.66964 8.57863 9.66964H5.67137Z" />
    </svg>
  );
};
const TicketsIcon: React.FunctionComponent = () => {
  return (
    <svg viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 0C0.896875 0 0 0.896875 0 2V4.5C0.828125 4.5 1.5 5.17188 1.5 6C1.5 6.82812 0.828125 7.5 0 7.5V10C0 11.1031 0.896875 12 2 12H16C17.1031 12 18 11.1031 18 10V7.5C17.1719 7.5 16.5 6.82812 16.5 6C16.5 5.17188 17.1719 4.5 18 4.5V2C18 0.896875 17.1031 0 16 0H2ZM4 3V9H14V3H4ZM3 3C3 2.44688 3.44688 2 4 2H14C14.5531 2 15 2.44688 15 3V9C15 9.55313 14.5531 10 14 10H4C3.44688 10 3 9.55313 3 9V3Z" />
    </svg>
  );
};
const DataIcon: React.FunctionComponent = () => {
  return (
    <svg viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.375 2.03125V3.25C11.375 4.37227 8.82832 5.28125 5.6875 5.28125C2.54668 5.28125 0 4.37227 0 3.25V2.03125C0 0.908984 2.54668 0 5.6875 0C8.82832 0 11.375 0.908984 11.375 2.03125ZM9.98359 5.45137C10.5117 5.26348 10.9967 5.02227 11.375 4.7252V7.3125C11.375 8.43477 8.82832 9.34375 5.6875 9.34375C2.54668 9.34375 0 8.43477 0 7.3125V4.7252C0.37832 5.02481 0.863281 5.26348 1.39141 5.45137C2.53145 5.85762 4.0498 6.09375 5.6875 6.09375C7.3252 6.09375 8.84355 5.85762 9.98359 5.45137ZM0 8.7877C0.37832 9.08731 0.863281 9.32598 1.39141 9.51387C2.53145 9.92012 4.0498 10.1562 5.6875 10.1562C7.3252 10.1562 8.84355 9.92012 9.98359 9.51387C10.5117 9.32598 10.9967 9.08477 11.375 8.7877V10.9688C11.375 12.091 8.82832 13 5.6875 13C2.54668 13 0 12.091 0 10.9688V8.7877Z" />
    </svg>
  );
};

interface Props {
  state: LINK_ASIDE;
}

const Icons: React.FunctionComponent<Props> = ({ state }) => {
  return (
    <>
      {state === 'tags' && <TagsIcon />}
      {state === 'agents' && <AgentsIcon />}
      {state === 'tickets' && <TicketsIcon />}
      {state === 'data' && <DataIcon />}
    </>
  );
};

export default Icons;
