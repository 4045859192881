import styles from './AddTag.module.css';
import React, { useContext, useState } from 'react';
import PopUpModal from '../PopUp/PopUp';
import { CPanelLayoutContext } from '../../Layout/CompanyPanel/CompanyPanelLayout';
import { LayoutContext } from '../../Layout/Layout';
import { AppContext } from '../../../App';
import { Input, Button } from '@chakra-ui/react';
import Tag from '../../Tags/Tags';
import { RESPONSE_DATA } from '../../../routes/index.routes';
import {
  createTagOnCompany,
  BODY_CREATE_TAG,
  DATA_CREATE_TAG,
} from '../../../routes/tags.routes';
import { fetcher, RESPONSE } from '../../../utils/fetcher';

const AddTag: React.FunctionComponent = () => {
  const { isModalAddTag, setIsModalAddTag } = useContext(CPanelLayoutContext);
  const { company, setCompanyTags } = useContext(AppContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>('');
  const [newTagColor, setNewTagColor] = useState<string>('#EEEEEE');

  const handleChangeNewTag = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewTag(e.target.value);
  };

  const handleChangeTagColor = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setNewTagColor(e.target.value);
  };

  const getCompanyId = (): string => {
    if (company === null) return '';
    return company.id;
  };

  const clean = (): void => {
    setNewTagColor('#EEEEEE');
    setNewTag('');
    setIsLoading(false);
  };

  const close = (): void => {
    setIsModalAddTag(false);
    clean();
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const getColor = (): string | null => {
    if (newTagColor.length !== 7) {
      return null;
    }
    const color: string = newTagColor.slice(1, 7);
    return color;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const doFetch = async (): Promise<void> => {
      try {
        const color: string | null = getColor();
        if (color === null) return;
        const body: BODY_CREATE_TAG = {
          name: newTag,
          color,
        };
        setIsLoading(true);
        const res: RESPONSE = await fetcher[createTagOnCompany.method]({
          uri: createTagOnCompany.url(getCompanyId()),
          body,
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;
        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }
        const data: DATA_CREATE_TAG = resData.data;
        if (data === null) {
          return;
        }
        setCompanyTags((prev) => [...prev, data]);
        addStaticMsg('Etiqueta creada con éxito!', 'success');
        close();
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        addStaticMsg('Error al crear una nueva etiqueta', 'danger');
        void askToDoRefresh();
      }
    };
    void doFetch();
  };

  return (
    <PopUpModal
      isOpen={isModalAddTag}
      setIsOpen={setIsModalAddTag}
      callbackClose={clean}
    >
      <div className={styles.container}>
        <div className={styles.title}>Nueva etiqueta:</div>
        <form onSubmit={onSubmit}>
          <div className={styles.top}>
            <div>Nombre de la etiqueta:</div>
            <div className={styles.top_color}>
              <span>Color:</span>
              <input
                type="color"
                name="color etiqueta"
                autoComplete="new-password"
                id="add-tag-color-modal"
                value={newTagColor}
                onChange={handleChangeTagColor}
              />
            </div>
          </div>
          <div className={styles.top_name}>
            <Input
              type="text"
              autoComplete="new-password"
              placeholder="Etiqueta..."
              value={newTag}
              onChange={handleChangeNewTag}
              isRequired
              maxLength={20}
            />
          </div>
          {newTag.trim() !== '' && (
            <div className={styles.prev}>
              <div>Previsualización:</div>
              <div className={styles.prev_tag}>
                <Tag
                  tag={{
                    id: `${new Date().getTime()}`,
                    company: getCompanyId(),
                    color: newTagColor,
                    name: newTag,
                    is_active: true,
                    created_at: new Date(),
                  }}
                />
              </div>
            </div>
          )}
          <div className={styles.btns}>
            <Button onClick={close} className={styles.btn} type="button">
              Cancelar
            </Button>
            <Button
              className={`${styles.btn} ${styles.btn_color}`}
              isLoading={isLoading}
              type="submit"
            >
              Guardar
            </Button>
          </div>
        </form>
      </div>
    </PopUpModal>
  );
};

export default AddTag;
