import React, { useEffect, useContext } from 'react';
import styles from './Agents.module.css';
import { InfoIcon } from '@chakra-ui/icons';
import ReactTooltip from 'react-tooltip';
import Container from './Container/Container';
import { useCache } from '../../../../hooks/useCache';
import { PersistentAgentContext } from '../../../../components/Layout/Context/PersistentAgent';
import { PersistentCAdminContext } from '../../../../components/Layout/Context/PersistentCAdmin';
import Skeleton from './Skeleton/Skeleton';

const Agents: React.FunctionComponent = () => {
  const { usersFromCompany } = useContext(PersistentAgentContext);
  const { hasFetchUsers } = useContext(PersistentCAdminContext);

  const { populateUsersFromCompany } = useCache();

  const getUsersController = (): void => {
    hasFetchUsers.current = true;
    populateUsersFromCompany(true);
  };

  const needsToDoFetch = (): void => {
    if (hasFetchUsers.current) return;

    getUsersController();
  };

  useEffect(() => {
    needsToDoFetch();
  }, []);

  return (
    <div className={styles.agents}>
      {usersFromCompany.size === 0 && <Skeleton />}
      {usersFromCompany.size !== 0 && (
        <div className={styles.agents_container}>
          <Container status="company_admin" />
          <div className={styles.divide}></div>
          <Container status="agent" />
          <div className={styles.divide}></div>
          <Container status="disabled" />
        </div>
      )}
      <div className={styles.agents_info}>
        <div data-tip data-for="info-cpanel-agents">
          <InfoIcon />
        </div>
        <ReactTooltip
          id="info-cpanel-agents"
          effect="solid"
          type="dark"
          place="top"
        >
          Para administrar a los usuarios de la plataforma de click en los
          usuarios.
        </ReactTooltip>
      </div>
    </div>
  );
};

export default Agents;
