import React, { useState, useContext } from 'react';
import styles from './User.module.css';
import { USER, USER_STATUS } from '../../../../../../types/user/user.types';
import Profile from '../../../../../../components/Panel/Profile/Profile';
import { AppContext } from '../../../../../../App';
import { LayoutContext } from '../../../../../../components/Layout/Layout';
import { PersistentAgentContext } from '../../../../../../components/Layout/Context/PersistentAgent';
import { CPanelLayoutContext } from '../../../../../../components/Layout/CompanyPanel/CompanyPanelLayout';
import { EditIcon, EyeCloseIcon, AgentIcon } from './Icons';
import { CONTAINER_CLASS } from '../Container';
import { fetcher, RESPONSE } from '../../../../../../utils/fetcher';
import { RESPONSE_DATA } from '../../../../../../routes/index.routes';
import { useCache } from '../../../../../../hooks/useCache';
import {
  updateUser,
  UPDATE_USER_BODY,
  UPDATE_USER_DATA,
} from '../../../../../../routes/auth.routes';

type OPTION = 'edit' | 'to-agent' | 'to-disable';

const mapOptionToText = new Map<OPTION, string>();
mapOptionToText.set('edit', 'Editar');
mapOptionToText.set('to-agent', 'Hacer agente');
mapOptionToText.set('to-disable', 'Deshabilitar');

interface OptionProps {
  option: OPTION;
  callback: (option: OPTION) => void;
}

const Option: React.FunctionComponent<OptionProps> = ({ option, callback }) => {
  const handleClick = (): void => {
    callback(option);

    // Blur
    if (document.activeElement === null) return;
    (document.activeElement as HTMLElement).blur();
  };

  return (
    <div onClick={handleClick} className={styles.option}>
      {option === 'edit' && <EditIcon />}
      {option === 'to-agent' && <AgentIcon />}
      {option === 'to-disable' && <EyeCloseIcon />}
      <span>{mapOptionToText.get(option)}</span>
    </div>
  );
};

interface OptionsProps {
  status: USER_STATUS;
  callback: (option: OPTION) => void;
  onlyEdit?: boolean;
}

const Options: React.FunctionComponent<OptionsProps> = ({
  status,
  callback,
  onlyEdit = false,
}) => {
  return (
    <>
      {!onlyEdit && status === 'agent' && (
        <>
          <Option callback={callback} option="to-disable" />
          <Option callback={callback} option="edit" />
        </>
      )}
      {!onlyEdit && status === 'disabled' && (
        <>
          <Option callback={callback} option="to-agent" />
          <Option callback={callback} option="edit" />
        </>
      )}
    </>
  );
};

interface Props {
  status: USER_STATUS;
  user: USER;
}

const UserContainer: React.FunctionComponent<Props> = ({ status, user }) => {
  const { user: ownUser } = useContext(AppContext);
  const { addAsyncMsg, addStaticMsg } = useContext(LayoutContext);
  const { setUsersFromCompany } = useContext(PersistentAgentContext);
  const { setIsModalEditAgent } = useContext(CPanelLayoutContext);
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);

  const { populateUsersFromCompany } = useCache();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkIfOwn = (): boolean => {
    if (ownUser === null) return false;
    return ownUser.username === user.username;
  };

  const onFocus = (): void => {
    if (isLoading) return;
    setIsDropDownOpen(true);
  };

  const onBlur = (): void => {
    if (isLoading) return;
    setIsDropDownOpen(false);
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const getMaxWidth = (): string => {
    const container: Element | null = document.querySelector(
      `.${CONTAINER_CLASS}`
    );
    if (container === null) return '15vw';
    const width: number = container.clientWidth;
    return `${width - 100}px`;
  };

  const updateUserControllerActive = (value: boolean): void => {
    const doFetch = async (): Promise<void> => {
      try {
        const body: UPDATE_USER_BODY = {
          key: 'is_active',
          value,
        };

        setIsLoading(true);
        const res: RESPONSE = await fetcher[updateUser.method]({
          uri: updateUser.url(user.username),
          body,
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }

        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const data: UPDATE_USER_DATA = resData.data;
        setUsersFromCompany(
          (prevMap) => new Map(prevMap.set(data.username, data))
        );

        // Populate to caché
        populateUsersFromCompany(true);

        if (value) {
          addStaticMsg('Usuario habilitado con éxito', 'success');
          return;
        }
        addStaticMsg('Usuario deshabilitado con éxito', 'success');
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        addStaticMsg('Error al habilitar/deshabilitar el usuario', 'danger');
        void askToDoRefresh();
      }
    };
    void doFetch();
  };

  const updateUserControllerEdit = (): void => {
    setIsModalEditAgent(user);
  };

  const addUser = (userOption: OPTION): void => {
    if (userOption === 'to-disable') {
      updateUserControllerActive(false);
      return;
    }
    if (userOption === 'to-agent') {
      updateUserControllerActive(true);
      return;
    }
    if (userOption === 'edit') {
      updateUserControllerEdit();
    }
  };

  return (
    <div
      tabIndex={-1}
      onFocus={onFocus}
      onBlur={onBlur}
      className={`${styles.user} ${isLoading && styles.loading}`}
    >
      <div
        className={styles.user_wrap}
        style={{
          cursor: status === 'company_admin' ? 'default' : 'pointer',
        }}
      >
        <Profile atribute="profile-cpanel-agent" user={user} />
        <div
          style={{
            maxWidth: getMaxWidth(),
          }}
          className={styles.user_name}
        >
          {user.name} {checkIfOwn() && '(Tú)'}
        </div>
        {status !== 'company_admin' && (
          <div className={styles.user_svg}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
            </svg>
          </div>
        )}
      </div>
      {status !== 'company_admin' && (
        <div
          className={`${styles.dropdown} ${
            isDropDownOpen && styles.dropdown_active
          }`}
        >
          <Options onlyEdit={checkIfOwn()} status={status} callback={addUser} />
        </div>
      )}
    </div>
  );
};

export default UserContainer;
