import { REQUEST } from './index.routes';
import { API_URL } from '../config/routes';

export const updateClient: REQUEST = {
  url: `${API_URL}/update-client`,
  method: 'put',
};

export type TYPE_UPDATE = 'info' | 'ticket' | 'notes';

export interface BODY_UPDATE_CLIENT {
  client: string;
  company: string;
  key: string;
  value: string | boolean | number;
  update: TYPE_UPDATE;
}
