import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';

let fetchController: boolean = false;

const CardLogIn: React.FunctionComponent = (): JSX.Element => {
  const fetchUser = useAuth();

  useEffect(() => {
    if (fetchController) return;
    fetchController = true;

    const initialize = async (): Promise<void> => {
      await fetchUser();
    };
    void initialize();
  }, []);

  return <></>;
};

export default CardLogIn;
