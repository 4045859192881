import React from 'react';
import styles from './Skeleton.module.css';

const SkeletonClientInfo: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className={styles.skeleton_client}>
      <div className={styles.skeleton_client_top}></div>
      <div className={styles.skeleton_client_bottom}></div>
    </div>
  );
};

const SkeletonClientUser: React.FunctionComponent = (): JSX.Element => {
  return <div className={styles.skeleton_profile_user}></div>;
};

const Skeleton: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className={styles.skeleton}>
      <SkeletonClientUser />
      <SkeletonClientInfo />
    </div>
  );
};

export default Skeleton;
