import styles from './Aside.module.css';
import React, { useContext } from 'react';
import { AppContext } from '../../../App';
import { PersistentCAdminContext } from '../../../components/Layout/Context/PersistentCAdmin';
import { LINK_ASIDE } from '../../../types/cpanel/cpanel.types';
import Icon from './Icons';

export const mapWindowStateToText = new Map<LINK_ASIDE, string>();
mapWindowStateToText.set('tags', 'Etiquetas');
mapWindowStateToText.set('agents', 'Usuarios');
mapWindowStateToText.set('tickets', 'Plantillas');
mapWindowStateToText.set('data', 'Analítica');

interface Props {
  state: LINK_ASIDE;
}

const WindowLink: React.FunctionComponent<Props> = ({ state }) => {
  const { windowState, setWindowState } = useContext(PersistentCAdminContext);

  const isSelected = (): boolean => {
    return windowState === state;
  };

  const handleClick = (): void => {
    if (isSelected()) return;
    setWindowState(state);
  };

  return (
    <button
      onClick={handleClick}
      className={`${styles.link} ${isSelected() && styles.link_selected}`}
    >
      <div className={styles.link_icon}>
        <Icon state={state} />
      </div>
      <div className={styles.link_text}>{mapWindowStateToText.get(state)}</div>
    </button>
  );
};

const AsideLinks: React.FunctionComponent = () => {
  const { company } = useContext(AppContext);

  const getCompanyName = (): string => {
    if (company === null) return '';
    return company.name;
  };

  return (
    <div className={styles.aside}>
      <div className={styles.aside_company_name}>{getCompanyName()}</div>
      <div className={styles.aside_links}>
        <WindowLink state="tags" />
        <WindowLink state="agents" />
        <WindowLink state="tickets" />
        <WindowLink state="data" />
      </div>
    </div>
  );
};
export default AsideLinks;
