import React, { useContext } from 'react';
import styles from './DashboardNav.module.css';
import { Select, InputLeftElement, Input, InputGroup } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { fetcher } from '../../../utils/fetcher';
import { logout } from '../../../routes/auth.routes';
import { AppContext } from '../../../App';
import { useNavigate, NavigateFunction, Link } from 'react-router-dom';
import { LayoutContext } from '../../Layout/Layout';
import ReactTooltip from 'react-tooltip';
import {
  ROUTE_SETTINGS,
  ROUTE_COMPANY_ADMIN_PANEL,
  ROUTE_ADMIN_PANEL,
} from '../../../config/routes';

const DashboardNav: React.FunctionComponent = (): JSX.Element => {
  const { user, isLoadingGetUser, company } = useContext(AppContext);
  const { addStaticMsg } = useContext(LayoutContext);

  const navigate: NavigateFunction = useNavigate();

  const logOut = (): void => {
    const doLogout = async (): Promise<void> => {
      try {
        const res = await fetcher[logout.method]({
          uri: logout.url,
        });

        const resData = res.data;
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        window.localStorage.clear();
        location.reload();
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al cerrar sesión', 'danger');
        window.localStorage.clear();
        location.reload();
      }
    };

    void doLogout();
  };

  const profileDropDown = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();

    if (e.target.value === 'logout') {
      logOut();
      return;
    }

    if (e.target.value === 'admin') {
      navigate(ROUTE_ADMIN_PANEL);
      return;
    }

    if (e.target.value === 'settings') {
      navigate(ROUTE_SETTINGS);
    }
  };

  const isInCompany = (): boolean => {
    if (user === null) return false;
    if (user.company === '') return false;
    if (user.company === null) return false;
    if (user.company === undefined) return false;
    if (company === null) return false;
    return company.is_active;
  };

  const canGoToCPanelAdmin = (): boolean => {
    if (user === null) return false;
    if (!isInCompany()) return false;
    if (user.status === 'company_admin') return true;
    if (user.status === 'admin') return true;
    return false;
  };

  const canGoToRhynoAdmin = (): boolean => {
    if (user === null) return false;
    if (user.status === 'admin') return true;
    return false;
  };

  return (
    <nav className={styles.nav}>
      <div className={styles.company}>
        {isLoadingGetUser && <div className={styles.skeleton_company}></div>}
        {!isLoadingGetUser && company?.name}
      </div>
      <div
        className={styles.search}
        data-tip
        data-for="dashboard-nav-searchbar"
      >
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            className={styles.search_input_group}
            children={<SearchIcon color="gray.400" />}
          />
          <Input isDisabled type="text" placeholder="Buscar" />
        </InputGroup>
        <ReactTooltip
          id="dashboard-nav-searchbar"
          effect="float"
          type="dark"
          place="bottom"
        >
          Próximamente...
        </ReactTooltip>
      </div>
      <div className={styles.panel}>
        {canGoToCPanelAdmin() && (
          <Link to={ROUTE_COMPANY_ADMIN_PANEL}>Panel admin</Link>
        )}
        {!canGoToCPanelAdmin() && (
          <div title={user?.name} className={styles.panel_name}>
            {user?.name}
          </div>
        )}
      </div>
      <div className={styles.dropdown}>
        {isLoadingGetUser && (
          <div className={styles.skeleton_dropdown_container}>
            <div className={styles.skeleton_dropdown}></div>
          </div>
        )}
        {!isLoadingGetUser && (
          <Select
            onChange={profileDropDown}
            variant="outline"
            placeholder="Dashboard"
          >
            {canGoToRhynoAdmin() && <option value="admin">Rhyno</option>}
            <option value="settings">Ajustes</option>
            <option value="logout">Cerrar sesión</option>
          </Select>
        )}
      </div>
    </nav>
  );
};

export default DashboardNav;
