import React, { Dispatch, SetStateAction, useContext } from 'react';
import styles from './UserChat.module.css';
import { CLIENT_ASIDE } from '../../../../types/client/chat.types';
import { getDateFormat } from '../../../../utils/dates/format';
import { DashboardContext } from '../../Dashboard';
import { PersistentAgentContext } from '../../../../components/Layout/Context/PersistentAgent';
import UserProfile from '../../Components/UserProfile/UserProfile';
import { DEBUG } from '../../../../config/routes';

interface Props {
  user: CLIENT_ASIDE;
  isSelected: boolean;
  click: Dispatch<SetStateAction<string | null>>;
  markAsReaded: (clientId: string) => void;
}

const UserChat: React.FunctionComponent<Props> = ({
  user,
  isSelected,
  click,
  markAsReaded,
}): JSX.Element => {
  const {
    setIsLoadingMsgs,
    setIsLoadingGetClient,
    setIsLoadingGetClientTickets,
    setIsLoadingGetClientNotes,
  } = useContext(DashboardContext);

  const {
    socket,
    selectedClientId,
    selectedClient,
    lastMsg,
    firstMsg,
    amountUnReadMsgs,
  } = useContext(PersistentAgentContext);

  const selectChat = (): void => {
    if (selectedClientId.current !== null) {
      // Para que no se seleccione otra vez
      if (selectedClientId.current === user.client_id) return;
    }

    if (socket === null) return;
    if (socket.current === null) return;

    selectedClientId.current = user.client_id;

    // Reset refs
    lastMsg.current = null;
    firstMsg.current = null;
    amountUnReadMsgs.current = 0;

    markAsReaded(user.client_id);
    click(user.client_id);

    // Leave current chat
    if (selectedClient !== null) {
      if (DEBUG) {
        console.log(
          '%cEmit leave client...',
          'color:#0000FF; font-size: 32px;'
        );
      }
      socket.current.emit('leave client', { client: selectedClientId.current });
    }

    setIsLoadingGetClient(true);
    setIsLoadingGetClientTickets(true);
    setIsLoadingGetClientNotes(true);
    if (DEBUG) {
      console.log('%cEmit get client...', 'color:#0000FF; font-size: 32px;');
    }
    socket.current.emit('get client info', { client: user.client_id });
    socket.current.emit('get client notes', { client: user.client_id });
    socket.current.emit('get client tickets', { client: user.client_id });

    setIsLoadingMsgs(true);
    if (DEBUG) {
      console.log('%cEmit get messages...', 'color:#0000FF; font-size: 32px;');
    }
    socket.current.emit('get messages', { client: user.client_id, skip: 0 });
  };

  return (
    <div
      onClick={selectChat}
      className={`${styles.user} ${isSelected && styles.selected}`}
    >
      <UserProfile isGateway isUnreadMsgs user={user} />
      <div className={styles.user_msg}>
        <div className={styles.user_msg_info}>
          <div className={styles.user_msg_info_name}>{user.client_name}</div>
          <div className={styles.user_msg_info_date}>
            {getDateFormat(user.last_msg_created_at)}
          </div>
        </div>
        <p title={user.last_msg_content} className={styles.user_msg_text}>
          {user.last_msg_content}
        </p>
      </div>
    </div>
  );
};

export default UserChat;
