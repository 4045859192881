import styles from './CompanyAdminNav.module.css';
import stylesGlobal from '../Nav.module.css';
import React, { useContext } from 'react';
import { AppContext } from '../../../App';
import { Link } from 'react-router-dom';
import {
  ROUTE_DASHBOARD,
  ROUTE_SETTINGS,
  ROUTE_ADMIN_PANEL,
} from '../../../config/routes';

const CompanyAdminNav: React.FunctionComponent = () => {
  const { user, company } = useContext(AppContext);

  const isInCompany = (): boolean => {
    if (user === null) return false;
    if (user.company === '') return false;
    if (user.company === null) return false;
    if (user.company === undefined) return false;
    if (company === null) return false;
    return company.is_active;
  };

  return (
    <nav className={styles.nav}>
      <div className={stylesGlobal.nav_aside}>
        <div className={stylesGlobal.nav_aside_title}>Panel admin</div>
      </div>
      <div className={stylesGlobal.nav_section}>
        {user?.status === 'admin' && <Link to={ROUTE_ADMIN_PANEL}>Rhyno</Link>}
        {isInCompany() && <Link to={ROUTE_DASHBOARD}>Dashboard</Link>}
        <Link to={ROUTE_SETTINGS}>Ajustes</Link>
      </div>
    </nav>
  );
};
export default CompanyAdminNav;
