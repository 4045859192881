import React, { useContext } from 'react';
import { PersistentCAdminContext } from '../../../components/Layout/Context/PersistentCAdmin';
import Tags from './Tags/Tags';
import Agents from './Agents/Agents';
import Tickets from './Tickets/Tickets';
import Data from './Data/Data';

const Body: React.FunctionComponent = () => {
  const { windowState } = useContext(PersistentCAdminContext);

  return (
    <div>
      {windowState === 'tags' && <Tags />}
      {windowState === 'agents' && <Agents />}
      {windowState === 'tickets' && <Tickets />}
      {windowState === 'data' && <Data />}
    </div>
  );
};

export default Body;
