import React, { useContext } from 'react';
import styles from './Tag.module.css';
import { TicketModalContext } from '../Ticket';
import { TAG } from '../../../../types/ticket/ticket.types';
import { DashboardLayoutContext } from '../../../Layout/Dashboard/DashboardLayout';
import Tag from '../../../Tags/Tags';

interface Props {
  newTag: TAG | null;
}

const TicketTag: React.FunctionComponent<Props> = ({ newTag }): JSX.Element => {
  const { setIsEditTag, setIsAddingNote } = useContext(TicketModalContext);
  const { dataTicket } = useContext(DashboardLayoutContext);

  if (dataTicket === null) return <div></div>;

  const getAllTagsCompany = (): void => {
    setIsEditTag(true);
    setIsAddingNote(false);
  };

  const getSelectedOne = (): TAG | null => {
    return newTag;
  };

  return (
    <div className={styles.form_right_tag}>
      <div className={styles.form_right_tag_title}>Etiqueta</div>
      <div className={styles.form_right_tag_body}>
        <Tag tag={getSelectedOne()} />
        <button
          type="button"
          onClick={getAllTagsCompany}
          className={styles.form_right_tag_body_plus}
        >
          Editar
        </button>
      </div>
    </div>
  );
};

export default TicketTag;
