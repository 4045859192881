import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { LINK_ASIDE } from '../../../types/cpanel/cpanel.types';
import { TICKET_SCHEMA } from '../../../types/ticket/ticket.types';
export const PersistentCAdminContext = createContext({} as ValueCAdminProvider);

interface ValueCAdminProvider {
  windowState: LINK_ASIDE;
  templatesTicketsWindow: TICKET_SCHEMA[];
  hasFetchTags: React.MutableRefObject<boolean>;
  hasFetchUsers: React.MutableRefObject<boolean>;
  hasFetchTemplates: React.MutableRefObject<boolean>;

  setWindowState: Dispatch<SetStateAction<LINK_ASIDE>>;
  setTemplatesTicketsWindow: Dispatch<SetStateAction<TICKET_SCHEMA[]>>;
}

interface Props {
  children: any;
}

const CAdminContextWrapper: React.FunctionComponent<Props> = ({ children }) => {
  const [windowState, setWindowState] = useState<LINK_ASIDE>('tags');
  const [templatesTicketsWindow, setTemplatesTicketsWindow] = useState<
    TICKET_SCHEMA[]
  >([]);
  const hasFetchTags = useRef<boolean>(false);
  const hasFetchUsers = useRef<boolean>(false);
  const hasFetchTemplates = useRef<boolean>(false);

  return (
    <PersistentCAdminContext.Provider
      value={{
        windowState,
        templatesTicketsWindow,
        hasFetchTags,
        hasFetchUsers,
        hasFetchTemplates,

        setWindowState,
        setTemplatesTicketsWindow,
      }}
    >
      {children}
    </PersistentCAdminContext.Provider>
  );
};
export default CAdminContextWrapper;
