import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
  useRef,
} from 'react';
import styles from './Dashboard.module.css';
import Chats from './Chats/Chat';
import Info from './Info/Info';
import Body from './Body/Body';
import { io } from 'socket.io-client';
import { DEBUG } from '../../config/routes';
import { AppContext } from '../../App';
import { PersistentAgentContext } from '../../components/Layout/Context/PersistentAgent';
import { LayoutContext } from '../../components/Layout/Layout';
import { useCache } from '../../hooks/useCache';
import {
  stablishConnection,
  STABLISH_CONNECTION_QUERY,
} from '../../routes/socket.routes';
import TicketModal from '../../components/Modals/Ticket/Ticket';
import NotesClientModal from '../../components/Modals/NotesClient/NotesClient';
import { useRunSocketDefinitions } from '../../hooks/useRunSocketDefinitions';
import { Helmet } from 'react-helmet-async';

export const DashboardContext = createContext({} as ValueDashboardProvider);

interface ValueDashboardProvider {
  isLoadingClients: boolean;
  isLoadingGetClient: boolean;
  isLoadingMsgs: boolean;
  selectedTicketId: React.MutableRefObject<string | null>;
  isLoadingGetClientTickets: boolean;
  isLoadingGetClientNotes: boolean;
  isModalTicket: boolean;
  isModalNotes: boolean;

  setIsLoadingClients: Dispatch<SetStateAction<boolean>>;
  setIsLoadingGetClient: Dispatch<SetStateAction<boolean>>;
  setIsLoadingMsgs: Dispatch<SetStateAction<boolean>>;
  setIsModalTicket: Dispatch<SetStateAction<boolean>>;
  setIsLoadingGetClientTickets: Dispatch<SetStateAction<boolean>>;
  setIsLoadingGetClientNotes: Dispatch<SetStateAction<boolean>>;
  setIsModalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

const Dashboard: React.FunctionComponent = (): JSX.Element => {
  const { user } = useContext(AppContext);
  const { socket } = useContext(PersistentAgentContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const selectedTicketId = useRef<string | null>(null);
  const [isLoadingMsgs, setIsLoadingMsgs] = useState<boolean>(false);
  const [isLoadingGetClient, setIsLoadingGetClient] = useState<boolean>(false);
  const [isLoadingGetClientTickets, setIsLoadingGetClientTickets] =
    useState<boolean>(false);
  const [isLoadingGetClientNotes, setIsLoadingGetClientNotes] =
    useState<boolean>(false);
  const { populateUsersFromCompany } = useCache();

  // Modals
  const [isModalTicket, setIsModalTicket] = useState<boolean>(false);
  const [isModalNotes, setIsModalNotes] = useState<boolean>(false);

  const connectSocket = (): void => {
    if (user === null) return;
    if (socket.current !== null) return;

    try {
      if (DEBUG) {
        console.log(
          '%cStablishing socket connection...',
          'color:#0000FF; font-size: 32px;'
        );
      }

      const companyId: string = user.company;
      const query: STABLISH_CONNECTION_QUERY = {
        company: companyId,
      };

      socket.current = io(`${stablishConnection}`, {
        query,
      });
    } catch (error) {
      console.error(error);
      addStaticMsg('Error al conectar con el servidor', 'danger');

      const askToDoRefresh = async (): Promise<void> => {
        const doRefresh: boolean = await addAsyncMsg(
          '¿Quiéres recargar la página?'
        );
        if (doRefresh) {
          location.reload();
        }
      };

      void askToDoRefresh();
    }
  };

  useEffect(() => {
    connectSocket();
    populateUsersFromCompany();
  }, [user]);

  const { subscribeSocket } = useRunSocketDefinitions();

  useEffect(() => {
    if (socket === null) return;
    if (socket.current === null) return;

    subscribeSocket();
  }, [socket, socket?.current]);

  return (
    <DashboardContext.Provider
      value={{
        isLoadingMsgs,
        isLoadingGetClient,
        selectedTicketId,
        isModalTicket,
        isLoadingGetClientTickets,
        isLoadingGetClientNotes,
        isModalNotes,

        isLoadingClients,
        setIsLoadingClients,
        setIsLoadingMsgs,
        setIsLoadingGetClient,
        setIsModalTicket,
        setIsLoadingGetClientTickets,
        setIsLoadingGetClientNotes,
        setIsModalNotes,
      }}
    >
      <Helmet>
        <title>Rhyno | Plataforma</title>
      </Helmet>
      <TicketModal />
      <NotesClientModal />
      <div className={styles.dashboard}>
        <Chats />
        <Body />
        <Info />
      </div>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
