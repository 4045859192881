import styles from './SettingsNav.module.css';
import stylesGlobal from '../Nav.module.css';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  ROUTE_DASHBOARD,
  ROUTE_COMPANY_ADMIN_PANEL,
  ROUTE_ADMIN_PANEL,
} from '../../../config/routes';
import { AppContext } from '../../../App';

const SettingsNav: React.FunctionComponent = () => {
  const { user, company } = useContext(AppContext);

  const isInCompany = (): boolean => {
    if (user === null) return false;
    if (user.company === '') return false;
    if (user.company === null) return false;
    if (user.company === undefined) return false;
    if (company === null) return false;
    return company.is_active;
  };

  const crmAdminRoutes = (): JSX.Element => {
    if (user === null) return <></>;
    if (user.status !== 'admin') return <></>;
    return (
      <>
        <Link to={ROUTE_ADMIN_PANEL}>Rhyno</Link>
        {isInCompany() && (
          <>
            <Link to={ROUTE_COMPANY_ADMIN_PANEL}>Panel admin</Link>
            <Link to={ROUTE_DASHBOARD}>Dashboard</Link>
          </>
        )}
      </>
    );
  };

  const companyAdminRoutes = (): JSX.Element => {
    if (user === null) return <></>;
    if (user.status !== 'company_admin') return <></>;
    if (!isInCompany()) return <></>;
    return (
      <>
        <Link to={ROUTE_COMPANY_ADMIN_PANEL}>Panel admin</Link>
        <Link to={ROUTE_DASHBOARD}>Dashboard</Link>
      </>
    );
  };

  const agentRoutes = (): JSX.Element => {
    if (user === null) return <></>;
    if (user.status !== 'agent') return <></>;
    if (!isInCompany()) return <></>;
    return (
      <>
        <Link to={ROUTE_DASHBOARD}>Dashboard</Link>
      </>
    );
  };

  return (
    <nav className={styles.nav}>
      <div className={stylesGlobal.nav_aside}>
        <div className={stylesGlobal.nav_aside_title}>Ajustes</div>
      </div>
      <div className={stylesGlobal.nav_section}>
        {crmAdminRoutes()}
        {companyAdminRoutes()}
        {agentRoutes()}
      </div>
    </nav>
  );
};
export default SettingsNav;
