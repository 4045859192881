import React, { useContext, useState } from 'react';
import PopUpModal from '../PopUp/PopUp';
import styles from './NotesClient.module.css';
import { DashboardContext } from '../../../pages/Dashboard/Dashboard';
import { Button } from '@chakra-ui/react';
import { PersistentAgentContext } from '../../Layout/Context/PersistentAgent';
import { fetcher, RESPONSE } from '../../../utils/fetcher';
import { LayoutContext } from '../../Layout/Layout';
import { DashboardLayoutContext } from '../../Layout/Dashboard/DashboardLayout';
import { RESPONSE_DATA } from '../../../routes/index.routes';
import {
  createNote,
  CREATE_NOTE_BODY,
  CREATE_NOTE_DATA,
} from '../../../routes/tickets.routes';

const NotesClient: React.FunctionComponent = () => {
  const { setIsModalNotes, isModalNotes } = useContext(DashboardContext);
  const { selectedClientId, setNotes } = useContext(PersistentAgentContext);
  const { updateAllClients } = useContext(DashboardLayoutContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const [text, setText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setText(e.target.value);
  };

  const close = (): void => {
    setText('');
    setIsLoading(false);
    setIsModalNotes(false);
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const createNewNote = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (selectedClientId.current === null) return;

        const body: CREATE_NOTE_BODY = {
          text,
          client: selectedClientId.current,
        };

        setIsLoading(true);
        const res: RESPONSE = await fetcher[createNote.method]({
          uri: createNote.url,
          body,
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const data: CREATE_NOTE_DATA = resData.data;

        setNotes((prev) => [...prev, data]);

        // Go back
        addStaticMsg('Se creó una nueva nota', 'success');

        // At the end we update every client
        updateAllClients(selectedClientId.current, 'notes');
        close();
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al crear una nueva nota', 'danger');
        setIsLoading(false);
        void askToDoRefresh();
      }
    };

    void doFetch();
  };

  return (
    <PopUpModal
      setIsOpen={setIsModalNotes}
      isOpen={isModalNotes}
      callbackClose={close}
    >
      <div className={styles.notes}>
        <div className={styles.note_title}>Notas</div>
        <div className={styles.note_instructions}>Escribe una nueva nota:</div>
        <div className={styles.note_body}>
          <textarea
            name="new-note"
            id="new-note-textarea"
            className={styles.note_body_text}
            placeholder="Nueva nota..."
            value={text}
            onChange={handleChange}
          ></textarea>
          <Button
            isDisabled={text.trim() === ''}
            isLoading={isLoading}
            onClick={createNewNote}
          >
            Crear nota
          </Button>
        </div>
      </div>
    </PopUpModal>
  );
};

export default NotesClient;
