import React, {
  useContext,
  useState,
  useRef,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import DashboardLayout from './Dashboard/DashboardLayout';
import LandingLayout from './Landing/LandingLayout';
import SettingsLayout from './Settings/SettingsLayout';
import CompanyPanelLayout from './CompanyPanel/CompanyPanelLayout';
import AdminLayout from './Admin/AdminLayout';
import { AppContext } from '../../App';
import { Spinner } from '@chakra-ui/react';
import MessagesModal from '../Modals/Messages/Messages';
import styles from './Layout.module.css';
import {
  MESSAGE_MODAL,
  MESSAGE_MODAL_RESOLVERS_DICT,
  MESSAGE_TYPE_MODAL_STATIC,
} from '../../types/modals/messages/messages.types';

// Context
export const LayoutContext = createContext({} as LayoutValueProvider);

interface LayoutValueProvider {
  modalMsgs: MESSAGE_MODAL[];
  waitForPressResolveArray: React.MutableRefObject<MESSAGE_MODAL_RESOLVERS_DICT>;
  hashSetSeedsMsgs: React.MutableRefObject<Set<string>>;

  setModalMsgs: Dispatch<SetStateAction<MESSAGE_MODAL[]>>;
  addAsyncMsg: (text: string) => Promise<boolean>;
  addStaticMsg: (text: string, typeMsg: MESSAGE_TYPE_MODAL_STATIC) => void;
}

interface Props {
  layoutType: 'login' | 'dashboard' | 'settings' | 'company-admin' | 'admin';
}

const Layout: React.FunctionComponent<Props> = ({
  layoutType,
}): JSX.Element => {
  const { isLoadingGetUser } = useContext(AppContext);

  // Modal messages
  const [modalMsgs, setModalMsgs] = useState<MESSAGE_MODAL[]>([]);
  const waitForPressResolveArray = useRef<MESSAGE_MODAL_RESOLVERS_DICT>({});
  const hashSetSeedsMsgs = useRef<Set<string>>(new Set());

  const addAsyncMsg = async (text: string): Promise<boolean> => {
    // eslint-disable-next-line
    if (hashSetSeedsMsgs.current.has(text))
      return await new Promise<boolean>((resolve) => {
        resolve(false);
      });
    hashSetSeedsMsgs.current.add(text);

    const waitForPress = async (id: string): Promise<boolean> => {
      // eslint-disable-next-line
      return new Promise<boolean>(
        (resolve) => (waitForPressResolveArray.current[id] = resolve)
      );
    };

    // Create unique id
    const id: string = String(new Date().getTime() + Math.random());

    // Push msg
    setModalMsgs((prev) => [
      ...prev,
      {
        id,
        text,
        type: 'ask',
        isOkCancel: true,
      },
    ]);

    const resOfBtn: boolean = await waitForPress(id);
    return resOfBtn;
  };

  const addStaticMsg = (
    text: string,
    typeMsg: MESSAGE_TYPE_MODAL_STATIC
  ): void => {
    if (hashSetSeedsMsgs.current.has(text)) return;
    hashSetSeedsMsgs.current.add(text);

    // Create unique id
    const id: string = String(new Date().getTime() + Math.random());

    // Push msg
    setModalMsgs((prev) => [
      ...prev,
      {
        id,
        text,
        type: typeMsg,
        isOkCancel: false,
      },
    ]);
  };

  if (isLoadingGetUser) {
    return (
      <div className={styles.spin}>
        <Spinner />
      </div>
    );
  }

  return (
    <LayoutContext.Provider
      value={{
        modalMsgs,
        waitForPressResolveArray,
        hashSetSeedsMsgs,

        setModalMsgs,
        addAsyncMsg,
        addStaticMsg,
      }}
    >
      <MessagesModal />
      {layoutType === 'login' && <LandingLayout />}
      {layoutType === 'dashboard' && <DashboardLayout />}
      {layoutType === 'company-admin' && <CompanyPanelLayout />}
      {layoutType === 'settings' && <SettingsLayout />}
      {layoutType === 'admin' && <AdminLayout />}
    </LayoutContext.Provider>
  );
};

export default Layout;
