import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useRef,
} from 'react';
import Nav from '../../Nav/Dashboard/DashboardNav';
import { Outlet } from 'react-router-dom';
import { fetcher, RESPONSE } from '../../../utils/fetcher';
import { RESPONSE_DATA } from '../../../routes/index.routes';
import {
  updateClient,
  BODY_UPDATE_CLIENT,
  TYPE_UPDATE,
} from '../../../routes/clients.routes';
import { GET_TICKET_DATA } from '../../../routes/tickets.routes';
import { LayoutContext } from '../Layout';
import { AppContext } from '../../../App';

// Context
export const DashboardLayoutContext = createContext(
  {} as DashboardValueProvider
);

interface DashboardValueProvider {
  dataTicket: GET_TICKET_DATA | null;

  controllerOnMessages: React.MutableRefObject<boolean>;
  controllerOnChats: React.MutableRefObject<boolean>;
  controllerOnInfo: React.MutableRefObject<boolean>;

  updateAllClients: (client: string, update: TYPE_UPDATE) => void;
  setDataTicket: Dispatch<SetStateAction<GET_TICKET_DATA | null>>;
}

const DashboardLayout: React.FunctionComponent = (): JSX.Element => {
  const { addAsyncMsg, addStaticMsg } = useContext(LayoutContext);
  const { company } = useContext(AppContext);

  // Controllers
  const controllerOnMessages = useRef<boolean>(false);
  const controllerOnChats = useRef<boolean>(false);
  const controllerOnInfo = useRef<boolean>(false);

  // Modal tickets
  const [dataTicket, setDataTicket] = useState<GET_TICKET_DATA | null>(null);

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const updateAllClients = (client: string, update: TYPE_UPDATE): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (company === null) return;

        const body: BODY_UPDATE_CLIENT = {
          client,
          company: company.id,
          key: '.',
          value: '.',
          update,
        };

        const res: RESPONSE = await fetcher[updateClient.method]({
          uri: updateClient.url,
          body,
        });
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }

        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
        }
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al actualizar los datos del cliente', 'danger');
        void askToDoRefresh();
      }
    };
    void doFetch();
  };

  return (
    <DashboardLayoutContext.Provider
      value={{
        dataTicket,

        controllerOnMessages,
        controllerOnChats,
        controllerOnInfo,

        updateAllClients,
        setDataTicket,
      }}
    >
      <Nav />
      <Outlet />
    </DashboardLayoutContext.Provider>
  );
};

export default DashboardLayout;
