import React, { useContext, useEffect, useRef, useState } from 'react';
import PopUpModal from '../PopUp/PopUp';
import styles from './EditAgent.module.css';
import { CPanelLayoutContext } from '../../Layout/CompanyPanel/CompanyPanelLayout';
import { PersistentAgentContext } from '../../Layout/Context/PersistentAgent';
import { LayoutContext } from '../../Layout/Layout';
import { Input, Button, InputGroup, InputRightElement } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import ReactTooltip from 'react-tooltip';
import { createSecurePassword } from '../../../utils/password';
import { fetcher, RESPONSE } from '../../../utils/fetcher';
import { useCache } from '../../../hooks/useCache';
import {
  updateUser,
  UPDATE_USER_BODY,
  UPDATE_USER_DATA,
} from '../../../routes/auth.routes';
import { RESPONSE_DATA } from '../../../routes/index.routes';
import { USER } from '../../../types/user/user.types';

interface Props {
  password: string;
  callback: (text: string) => void;
}

const CopyClipboard: React.FunctionComponent<Props> = ({
  password,
  callback,
}) => {
  return (
    <>
      <span
        onClick={() => {
          callback(password);
        }}
        data-tip
        data-for="copy-clipboard-agent-edit-agent-pswd"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
        </svg>
      </span>
      <ReactTooltip
        id="copy-clipboard-agent-edit-agent-pswd"
        effect="solid"
        type="dark"
        place="top"
      >
        Copiar al portapapeles.
      </ReactTooltip>
    </>
  );
};

const EditAgent: React.FunctionComponent = () => {
  const { isModalEditAgent, setIsModalEditAgent } =
    useContext(CPanelLayoutContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const { setUsersFromCompany } = useContext(PersistentAgentContext);

  const [password, setPassword] = useState<string>('');
  const [realName, setRealName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updated = useRef<USER | null>(null);
  const hasError = useRef<boolean>(false);

  const { populateUsersFromCompany } = useCache();

  useEffect(() => {
    if (isModalEditAgent === null) return;
    if (realName.trim() !== '') return;

    setRealName(isModalEditAgent.name);
  }, [isModalEditAgent]);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const close = (): void => {
    setIsModalEditAgent(null);
    clean();
  };

  const clean = (): void => {
    setPassword('');
    setRealName('');
    updated.current = null;
    hasError.current = false;
    setIsLoading(false);
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const getKeysThatWereUpdated = (): Array<'name' | 'password'> => {
    const keys: Array<'name' | 'password'> = [];

    if (password.trim() !== '') {
      keys.push('password');
    }

    if (realName !== isModalEditAgent?.name) {
      keys.push('name');
    }
    return keys;
  };

  const getArrPromises = (): Array<Promise<void>> => {
    const keys: Array<'name' | 'password'> = getKeysThatWereUpdated();
    const arr: Array<Promise<void>> = [];

    const doFetch = async (key: 'name' | 'password'): Promise<void> => {
      try {
        if (isModalEditAgent === null) return;
        let newVal: string | boolean = '';

        if (key === 'name') {
          newVal = realName;
        } else if (key === 'password') {
          newVal = password;
        }

        const body: UPDATE_USER_BODY = {
          key,
          value: newVal,
        };
        const res: RESPONSE = await fetcher[updateUser.method]({
          uri: updateUser.url(isModalEditAgent.username),
          body,
        });

        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          hasError.current = true;
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const data: UPDATE_USER_DATA = resData.data;
        updated.current = data;
      } catch (error) {
        console.error(error);
        hasError.current = true;
        addStaticMsg('Hubo un error al actualizar el usuario', 'danger');
        void askToDoRefresh();
      }
    };

    for (let i = 0; i < keys.length; i++) {
      arr.push(
        new Promise<void>((resolve) => {
          const f = async (): Promise<void> => {
            await doFetch(keys[i]);
            resolve();
          };
          void f();
        })
      );
    }

    return arr;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    hasError.current = false;
    const promises: Array<Promise<void>> = getArrPromises();
    const updatePromises = async (): Promise<void> => {
      setIsLoading(true);
      await Promise.all(promises);
      setIsLoading(false);
      close();
      if (updated.current === null) {
        return;
      }
      if (hasError.current) {
        return;
      }
      addStaticMsg('Usuario actualizado correctamente!', 'success');
      const n: USER = updated.current;

      setUsersFromCompany((prevMap) => new Map(prevMap.set(n.username, n)));

      // Populate to caché
      populateUsersFromCompany(true);
    };

    if (promises.length > 0) {
      void updatePromises();
    }
  };

  const copy = (text: string): void => {
    const wait = async (): Promise<void> => {
      await navigator.clipboard.writeText(text);
      addStaticMsg('Se ha copiado la contraseña al portapapeles!', 'success');
    };
    void wait();
  };

  const createPswd = (): void => {
    const pswd: string = createSecurePassword();
    setPassword(pswd);
    copy(pswd);
  };

  return (
    <PopUpModal
      callbackClose={close}
      isOpen={isModalEditAgent !== null}
      setIsOpen={null}
    >
      <div className={styles.container}>
        <div className={styles.title}>Editar agente:</div>
        <div className={styles.username}>
          <div className={styles.username_title}>Correo electrónico:</div>
          <div className={styles.username_data}>
            {isModalEditAgent?.username}
          </div>
        </div>
        <form
          autoComplete="new-password"
          onSubmit={onSubmit}
          className={styles.form}
        >
          <div className={styles.wrapper}>
            <div className={styles.input}>
              <span>Nombre de usuario:</span>
              <Input
                type="text"
                autoComplete="new-password"
                placeholder="John Doe"
                value={realName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setRealName(e.target.value);
                }}
              />
            </div>
            <div className={styles.input}>
              <span className={styles.input_span}>
                <div>Contraseña:</div>
                <div className={styles.password}>
                  {password.trim() !== '' && (
                    <CopyClipboard callback={copy} password={password} />
                  )}
                  <span
                    onClick={createPswd}
                    data-tip
                    data-for="add-random-pswd-edit-agent"
                    className={styles.add}
                  >
                    +
                  </span>
                  <ReactTooltip
                    id="add-random-pswd-edit-agent"
                    effect="solid"
                    type="dark"
                    place="top"
                  >
                    Crear contraseña segura.
                  </ReactTooltip>
                </div>
              </span>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  autoComplete="new-password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Contraseña..."
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                  maxLength={32}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => {
                      setShowPassword((prev) => !prev);
                    }}
                  >
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
          </div>
          <div className={styles.btns}>
            <Button onClick={close} className={styles.btn} type="button">
              Cancelar
            </Button>
            <Button
              className={`${styles.btn} ${styles.btn_color}`}
              isLoading={isLoading}
              type="submit"
            >
              Guardar
            </Button>
          </div>
        </form>
      </div>
    </PopUpModal>
  );
};

export default EditAgent;
