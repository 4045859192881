import React from 'react';
import styles from './Skeleton.module.css';

const Skeleton: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className={styles.skeleton}>
      <div className={styles.skeleton_bottom}></div>
      <div className={styles.skeleton_bottom}></div>
      <div className={styles.skeleton_bottom}></div>
    </div>
  );
};

export default Skeleton;
