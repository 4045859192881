import React, { useContext } from 'react';
import { TicketModalContext } from '../Ticket';
import styles from './Notes.module.css';
import { NOTE } from '../../../../types/ticket/ticket.types';
import { DashboardLayoutContext } from '../../../Layout/Dashboard/DashboardLayout';
import Skeleton from './Skeleton/Skeleton';

const TicketNotes: React.FunctionComponent = (): JSX.Element => {
  const { setIsAddingNote, setIsEditTag } = useContext(TicketModalContext);
  const { dataTicket } = useContext(DashboardLayoutContext);

  if (dataTicket === null) return <div></div>;

  const getNotes = (): JSX.Element => {
    if (dataTicket.schema === null) return <Skeleton />;
    if (dataTicket.notes === null || dataTicket.notes.length === 0)
      return <div>Sin notas...</div>;
    return (
      <div className={styles.notes}>
        {dataTicket.notes.map((note: NOTE, index: number) => {
          return (
            <div key={index} className={styles.note}>
              {note.content}
            </div>
          );
        })}
      </div>
    );
  };

  const openNewNote = (): void => {
    setIsAddingNote(true);
    setIsEditTag(false);
  };

  return (
    <div className={styles.form_right_notes}>
      <div className={styles.form_right_notes_title}>
        <div className={styles.form_right_notes_title_text}>Notas</div>
        <button
          onClick={openNewNote}
          type="button"
          className={styles.form_right_notes_title_btn}
        >
          Nueva nota
        </button>
      </div>
      <div className={styles.form_right_notes_body}>{getNotes()}</div>
    </div>
  );
};

export default TicketNotes;
