import React, { Dispatch, SetStateAction, useState } from 'react';
import { Input, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import styles from './Card.module.css';

interface Props {
  username: string;
  setUsername: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  handleLogIn: (e: React.FormEvent) => void;
  msgInput: string;
  isLoading: boolean;
}

const CardLogIn: React.FunctionComponent<Props> = ({
  username,
  setUsername,
  password,
  setPassword,
  handleLogIn,
  msgInput,
  isLoading,
}): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = (): void => setShowPassword(!showPassword);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setUsername(e.target.value);
  };
  const handleChangePassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.h1}>Iniciar sesión</h1>
        <form onSubmit={handleLogIn}>
          <div className={styles.input_container}>
            <Input
              value={username}
              onChange={handleChangeEmail}
              placeholder="Nombre de usuario"
              type="email"
              isRequired
            />
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showPassword ? 'text' : 'password'}
                placeholder="Contraseña"
                value={password}
                onChange={handleChangePassword}
                isRequired
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClickShowPassword}>
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Button isLoading={isLoading} type="submit">
              Entrar
            </Button>
          </div>
        </form>
        <div className={styles.msg}>{msgInput}</div>
      </div>
    </div>
  );
};

export default CardLogIn;
