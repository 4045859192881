import styles from './PopUp.module.css';
import React, { Dispatch, SetStateAction } from 'react';
import { CloseIcon } from '@chakra-ui/icons';

interface Props {
  children: any;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>> | null;
  callbackClose?: () => void;
}

const PopUp: React.FunctionComponent<Props> = ({
  children,
  setIsOpen,
  isOpen,
  callbackClose,
}): JSX.Element => {
  const close = (): void => {
    if (setIsOpen !== null) {
      setIsOpen(false);
    }
    if (callbackClose === undefined) return;
    callbackClose();
  };

  return (
    <div
      // onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      //   if (e.target !== e.currentTarget) return;
      //   close();
      // }}
      className={`${styles.pop} ${isOpen && styles.pop_open}`}
    >
      <div className={styles.card}>
        <CloseIcon className={styles.cross} onClick={close} />
        {children}
      </div>
    </div>
  );
};

export default PopUp;
