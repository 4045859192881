import React, { useContext, useState } from 'react';
import styles from './ChangePswd.module.css';
import PopUpModal from '../PopUp/PopUp';
import { SettingsLayoutContext } from '../../../components/Layout/Settings/SettingsLayout';
import { LayoutContext } from '../../../components/Layout/Layout';
import { AppContext } from '../../../App';
import { Input, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { fetcher, RESPONSE } from '../../../utils/fetcher';
import { RESPONSE_DATA } from '../../../routes/index.routes';
import { updateUser, UPDATE_USER_BODY } from '../../../routes/auth.routes';

const ChangePswd: React.FunctionComponent = () => {
  const { isModalChangePswd, setIsModalChangePswd } = useContext(
    SettingsLayoutContext
  );
  const { addAsyncMsg, addStaticMsg } = useContext(LayoutContext);
  const { user } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentPswd, setCurrentPswd] = useState<string>('');
  const [showCurrentPswd, setShowCurrentPswd] = useState<boolean>(false);

  const handleChangeCurrentPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setCurrentPswd(e.target.value);
  };
  const handleClickShowCurrentPassword = (): void => {
    setShowCurrentPswd((prev) => !prev);
  };

  const [newPswd, setNewPswd] = useState<string>('');
  const [showNewPswd, setShowNewPswd] = useState<boolean>(false);

  const handleChangeNewPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setNewPswd(e.target.value);
  };
  const handleClickShowNewPassword = (): void => {
    setShowNewPswd((prev) => !prev);
  };

  const [confirmNewPswd, setConfirmNewPswd] = useState<string>('');
  const [showConfirmNewPswd, setShowConfirmNewPswd] = useState<boolean>(false);

  const handleChangeConfirmNewPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setConfirmNewPswd(e.target.value);
  };
  const handleClickShowConfirmPassword = (): void => {
    setShowConfirmNewPswd((prev) => !prev);
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const changePswd = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const doFetch = async (): Promise<void> => {
      try {
        if (user === null) return;
        if (confirmNewPswd !== newPswd) {
          addStaticMsg('Las contraseñas no coinciden', 'danger');
          return;
        }

        const body: UPDATE_USER_BODY = {
          key: 'password',
          value: newPswd,
          old_password: currentPswd,
        };

        setIsLoading(true);
        const res: RESPONSE = await fetcher[updateUser.method]({
          uri: updateUser.url(user.username),
          body,
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }

        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        // No hay que actualizar nada
        addStaticMsg('Contraseña actualizada con éxito', 'success');
        close();
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        addStaticMsg('Error al actualizar la contraseña', 'danger');
        void askToDoRefresh();
      }
    };

    void doFetch();
  };

  const close = (): void => {
    clean();
    setIsModalChangePswd(false);
  };

  const clean = (): void => {
    setCurrentPswd('');
    setNewPswd('');
    setConfirmNewPswd('');

    setShowCurrentPswd(false);
    setShowNewPswd(false);
    setShowConfirmNewPswd(false);
    setIsLoading(false);
  };

  return (
    <PopUpModal
      isOpen={isModalChangePswd}
      setIsOpen={setIsModalChangePswd}
      callbackClose={clean}
    >
      <div className={styles.container}>
        <div className={styles.title}>Cambiar contraseña</div>
        <div>
          <form onSubmit={changePswd} className={styles.input}>
            <div className={styles.input_group}>
              <div className={styles.input_label}>Contraseña actual</div>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  autoComplete="new-password"
                  type={showCurrentPswd ? 'text' : 'password'}
                  placeholder="Actual"
                  value={currentPswd}
                  onChange={handleChangeCurrentPassword}
                  isRequired
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleClickShowCurrentPassword}
                  >
                    {showCurrentPswd ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
            <div className={styles.input_group}>
              <div className={styles.input_label}>Nueva contraseña</div>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  autoComplete="new-password"
                  type={showNewPswd ? 'text' : 'password'}
                  placeholder="Contraseña"
                  value={newPswd}
                  onChange={handleChangeNewPassword}
                  isRequired
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleClickShowNewPassword}
                  >
                    {showNewPswd ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
            <div className={styles.input_group}>
              <div className={styles.input_label}>
                Confirmar nueva contraseña
              </div>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  autoComplete="new-password"
                  type={showConfirmNewPswd ? 'text' : 'password'}
                  placeholder="Confirmar"
                  value={confirmNewPswd}
                  onChange={handleChangeConfirmNewPassword}
                  isRequired
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleClickShowConfirmPassword}
                  >
                    {showConfirmNewPswd ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
            <Button className={styles.btn} isLoading={isLoading} type="submit">
              Cambiar
            </Button>
          </form>
        </div>
      </div>
    </PopUpModal>
  );
};

export default ChangePswd;
