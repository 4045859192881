import { METHODS } from '../utils/fetcher';
import { TAG } from '../types/ticket/ticket.types';
import { API_URL } from '../config/routes';

interface REQUEST_PARAM {
  url: (ticketId: string) => string;
  method: METHODS;
}

export const getAllTagsCompany: REQUEST_PARAM = {
  url: (companyId: string) => `${API_URL}/v2/tags/${companyId}?all=1`,
  method: 'get',
};

export interface GET_ALL_TAGS_COMPANY_DATA {
  tags: TAG[];
}

export const createTagOnCompany: REQUEST_PARAM = {
  url: (companyId: string) => `${API_URL}/v2/tags/${companyId}`,
  method: 'post',
};

export interface BODY_CREATE_TAG {
  name: string;
  color: string;
}

export interface DATA_CREATE_TAG extends TAG {}

export const updateTagOnCompany: REQUEST_PARAM = {
  url: (tagId: string) => `${API_URL}/v2/tags/${tagId}`,
  method: 'put',
};

export interface BODY_UPDATE_TAG {
  value: boolean;
}
