import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import styles from './TagsCompany.module.css';
import { TicketModalContext } from '../Ticket';
import { AppContext } from '../../../../App';
import { fetcher, RESPONSE } from '../../../../utils/fetcher';
import { TAG } from '../../../../types/ticket/ticket.types';
import { RESPONSE_DATA } from '../../../../routes/index.routes';
import {
  getAllTagsCompany as getAllTagsCompanyRoute,
  GET_ALL_TAGS_COMPANY_DATA,
} from '../../../../routes/tags.routes';
import { Spinner } from '@chakra-ui/react';
import Tag from '../../../Tags/Tags';
import { LayoutContext } from '../../../Layout/Layout';

let useEffectController: boolean = false;

interface Props {
  newTag: TAG | null;
  setNewTag: Dispatch<SetStateAction<TAG | null>>;
}

const TagsCompany: React.FunctionComponent<Props> = ({
  newTag,
  setNewTag,
}): JSX.Element => {
  const { setIsAddingNote, setIsEditTag, isEditTag } =
    useContext(TicketModalContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const { company, companyTags, setCompanyTags } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const getAllTagsCompany = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (company === null) return;
        if (isLoading) return;
        setIsLoading(true);
        const res: RESPONSE = await fetcher[getAllTagsCompanyRoute.method]({
          uri: getAllTagsCompanyRoute.url(company.id),
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const dataTags: GET_ALL_TAGS_COMPANY_DATA = resData.data;
        setCompanyTags(dataTags.tags);
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al obtener las etiquetas de la empresa', 'danger');
        void askToDoRefresh();
        setIsLoading(false);
      }
    };

    void doFetch();
  };

  useEffect(() => {
    if (!isEditTag) return;
    if (companyTags.length !== 0) return;
    if (useEffectController) return;
    useEffectController = true;
    getAllTagsCompany();
  }, [isEditTag]);

  const close = (): void => {
    setIsAddingNote(false);
    setIsEditTag(false);
  };

  const selectTag = (tag: TAG): void => {
    setNewTag((prev) =>
      prev === null ? tag : prev.id === tag.id ? null : tag
    );
  };

  if (isLoading)
    return (
      <div className={styles.tags}>
        <div className={styles.loader}>
          <Spinner />
        </div>
      </div>
    );

  return (
    <div className={styles.tags}>
      <div onClick={close} className={styles.return}>
        Volver
      </div>
      <div className={styles.tags_instructions}>Selecciona una etiqueta:</div>
      <div className={styles.tags_company}>
        {companyTags.map((tag: TAG, index: number) => {
          if (!tag.is_active)
            return <React.Fragment key={index}></React.Fragment>;
          return (
            <React.Fragment key={index}>
              <Tag
                tag={tag}
                click={() => {
                  selectTag(tag);
                }}
              />
            </React.Fragment>
          );
        })}
      </div>
      {newTag !== null && (
        <div className={styles.container_selected}>
          <span>Etiqueta seleccionada:</span>
          <span className={styles.container_selected_span}>
            <Tag tag={newTag} />
          </span>
        </div>
      )}
    </div>
  );
};

export default TagsCompany;
