import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import Nav from '../../Nav/Admin/AdminNav';
import { Outlet } from 'react-router-dom';
import styles from './AdminLayout.module.css';
import AddCompanyModal from '../../Modals/AddCompany/AddCompany';

// Context
export const AdminLayoutContext = createContext({} as AdminValueProvider);

interface AdminValueProvider {
  isModalAddCompany: boolean;

  setIsModalAddCompany: Dispatch<SetStateAction<boolean>>;
}

const AdminLayout: React.FunctionComponent = (): JSX.Element => {
  const [isModalAddCompany, setIsModalAddCompany] = useState<boolean>(false);

  return (
    <AdminLayoutContext.Provider
      value={{
        isModalAddCompany,

        setIsModalAddCompany,
      }}
    >
      <AddCompanyModal />
      <div className={styles.layout}>
        <Nav />
        <Outlet />
      </div>
    </AdminLayoutContext.Provider>
  );
};

export default AdminLayout;
