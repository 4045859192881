import styles from './Aside.module.css';
import React, { useContext } from 'react';
import { PersistentAdminContext } from '../../../components/Layout/Context/PersistentAdmin';
import { LINK_ASIDE } from '../../../types/admin/admin.types';
import Icon from './Icons';

export const mapWindowStateToText = new Map<LINK_ASIDE, string>();
mapWindowStateToText.set('companies', 'Empresas');
mapWindowStateToText.set('data', 'Analítica');
mapWindowStateToText.set('view', 'Empresas');

interface Props {
  state: LINK_ASIDE;
}

const WindowLink: React.FunctionComponent<Props> = ({ state }) => {
  const { windowState, setWindowState, setSelectedCompany } = useContext(
    PersistentAdminContext
  );

  const isSelected = (): boolean => {
    return windowState === state;
  };

  const handleClick = (): void => {
    if (isSelected()) return;
    setSelectedCompany(null);
    setWindowState(state);
  };

  return (
    <button
      onClick={handleClick}
      className={`${styles.link} ${isSelected() && styles.link_selected}`}
    >
      <div className={styles.link_icon}>
        <Icon state={state} />
      </div>
      <div className={styles.link_text}>{mapWindowStateToText.get(state)}</div>
    </button>
  );
};

const AsideLinks: React.FunctionComponent = () => {
  return (
    <div className={styles.aside}>
      <div className={styles.aside_company_name}>Panel Admin</div>
      <div className={styles.aside_links}>
        <WindowLink state="companies" />
        <WindowLink state="data" />
      </div>
    </div>
  );
};
export default AsideLinks;
