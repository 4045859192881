import React, { useEffect, useContext, useState } from 'react';
import styles from './Tickets.module.css';
import ReactTooltip from 'react-tooltip';
import { InfoIcon } from '@chakra-ui/icons';
import { PersistentCAdminContext } from '../../../../components/Layout/Context/PersistentCAdmin';
import { CPanelLayoutContext } from '../../../../components/Layout/CompanyPanel/CompanyPanelLayout';
import { AppContext } from '../../../../App';
import { LayoutContext } from '../../../../components/Layout/Layout';
import { TICKET_SCHEMA } from '../../../../types/ticket/ticket.types';
import { fetcher, RESPONSE } from '../../../../utils/fetcher';
import { RESPONSE_DATA } from '../../../../routes/index.routes';
import {
  getAllMongoSchemasOfCompany,
  GET_MONGO_SCHEMAS_DATA,
} from '../../../../routes/schemas.routes';
import Template from './Template/Template';
import Skeleton from './Skeleton/Skeleton';
import { sortTemplates } from './utils/sort';

const Tickets: React.FunctionComponent = () => {
  const {
    setTemplatesTicketsWindow,
    templatesTicketsWindow,
    hasFetchTemplates,
  } = useContext(PersistentCAdminContext);
  const { company } = useContext(AppContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const { setIsModalAddTemplate } = useContext(CPanelLayoutContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addTicket = (): void => {
    setIsModalAddTemplate(true);
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const getTemplatesController = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (company === null) return;
        if (isLoading) return;
        setIsLoading(true);
        hasFetchTemplates.current = true;
        const res: RESPONSE = await fetcher[getAllMongoSchemasOfCompany.method](
          { uri: getAllMongoSchemasOfCompany.url(company.id) }
        );
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const templates: GET_MONGO_SCHEMAS_DATA = resData.data;
        setTemplatesTicketsWindow(templates);
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al obtener las plantillas de la empresa', 'danger');
        void askToDoRefresh();
        setIsLoading(false);
      }
    };
    void doFetch();
  };

  const needsToDoFetch = (): void => {
    if (hasFetchTemplates.current) return;
    getTemplatesController();
  };

  const getFilteredTemplates = (type: 'client' | 'ticket'): TICKET_SCHEMA[] => {
    const aux: TICKET_SCHEMA[] = [];

    for (let i = 0; i < templatesTicketsWindow.length; i++) {
      const template: TICKET_SCHEMA = templatesTicketsWindow[i];
      if (template.type !== type) continue;
      aux.push(template);
    }

    return sortTemplates(aux);
  };

  useEffect(() => {
    needsToDoFetch();
  }, []);

  return (
    <div className={styles.tickets}>
      <div className={styles.fluid}>
        <div className={styles.add}>
          <button
            data-tip
            data-for="button-add-ticket-cpanel"
            className={styles.add_ticket}
            onClick={addTicket}
          >
            +
          </button>
          <span>Plantillas:</span>
          <ReactTooltip
            id="button-add-ticket-cpanel"
            effect="solid"
            type="dark"
            place="top"
          >
            Añadir plantilla
          </ReactTooltip>
        </div>
        <div className={styles.info} data-tip data-for="info-cpanel-tickets">
          <InfoIcon />
        </div>
        <ReactTooltip
          id="info-cpanel-tickets"
          effect="solid"
          type="dark"
          place="top"
        >
          Estas son las plantillas para los tickets y los clientes.
        </ReactTooltip>
      </div>
      {isLoading && <Skeleton />}
      {!isLoading && (
        <div className={styles.templates_wrapper}>
          <div className={styles.templates_wrapper_titles}>
            <div className={styles.templates_wrapper_title}>Tickets:</div>
            <div className={styles.templates}>
              {/* Tickets */}
              {getFilteredTemplates('ticket').map(
                (template: TICKET_SCHEMA, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Template template={template} isActual={index === 0} />
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>
          <div className={styles.templates_wrapper_titles}>
            <div className={styles.templates_wrapper_title}>Clientes:</div>
            <div className={styles.templates}>
              {/* Clients */}
              {getFilteredTemplates('client').map(
                (template: TICKET_SCHEMA, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Template template={template} isActual={index === 0} />
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tickets;
