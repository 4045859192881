import React, {
  useContext,
  useState,
  useRef,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import styles from './Body.module.css';
import { DEBUG } from '../../../config/routes';
import Info from './Info/Info';
import { GET_MESSAGES_DATA } from '../../../types/socket/index.types';
import Messages from './Messages/Messages';
import InputChat from './InputChat/InputChat';
import { DashboardContext } from '../Dashboard';
import { DashboardLayoutContext } from '../../../components/Layout/Dashboard/DashboardLayout';
import { PersistentAgentContext } from '../../../components/Layout/Context/PersistentAgent';

export const BodyDashboardContext = createContext(
  {} as ValueBodyDashboardProvider
);

interface ValueBodyDashboardProvider {
  isFetchingMoreMsgs: React.MutableRefObject<boolean>;
  isLoadingMoreMsgs: boolean;
  fetchMoreMsgs: () => void;

  setIsLoadingMoreMsgs: Dispatch<SetStateAction<boolean>>;
}

let isTopMsgs: string = '';

const Body: React.FunctionComponent = (): JSX.Element => {
  const { selectedClient, socket, selectedClientId, lastMsg, messages } =
    useContext(PersistentAgentContext);
  const { isLoadingGetClient, setIsLoadingMsgs } = useContext(DashboardContext);
  const { controllerOnMessages } = useContext(DashboardLayoutContext);

  const isFetchingMoreMsgs = useRef<boolean>(false);
  const [isLoadingMoreMsgs, setIsLoadingMoreMsgs] = useState<boolean>(false);

  const fetchMoreMsgs = (): void => {
    if (socket === null) return;
    if (socket.current === null) return;
    if (selectedClientId.current === null) return;
    if (messages === undefined) return;
    if (isTopMsgs === selectedClientId.current) {
      if (DEBUG) {
        console.log('%cTOP...', 'color: #FF0000; font-size: 32px;');
      }
      return;
    }

    setIsLoadingMoreMsgs(true);
    isFetchingMoreMsgs.current = true;

    if (DEBUG) {
      console.log('%cEmit get messages...', 'color:#0000FF; font-size: 32px;');
    }
    socket.current.emit('get messages', {
      client: selectedClientId.current,
      skip: messages.length,
    });
  };

  const show = (): boolean => {
    if (selectedClient !== null) return true;
    if (isLoadingGetClient) return true;
    return false;
  };

  const moveScrollToBottom = (): void => {
    // Needs to be imperative
    const refScroll: HTMLDivElement | null =
      document.querySelector('#container-msgs');
    if (refScroll === null) return;

    const timeOut: NodeJS.Timeout = setTimeout(() => {
      if (DEBUG) {
        console.log('%cScrolling...', 'color:#FFFFFF; font-size: 32px;');
      }
      refScroll.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      clearTimeout(timeOut);
    }, 500);
  };

  useEffect(() => {
    if (socket === null || socket === undefined) return;
    if (socket.current === null || socket.current === undefined) return;
    if (controllerOnMessages.current) return;
    controllerOnMessages.current = true;

    socket.current.on('messages', (res) => {
      setIsLoadingMsgs(false);
      setIsLoadingMoreMsgs(false);

      if (selectedClientId.current === null) return;
      const data: GET_MESSAGES_DATA = res;
      if (data.clientId !== selectedClientId.current) return;
      isFetchingMoreMsgs.current = false;

      if (data.allMsgs.length === 0) {
        // We only care about the length
        isTopMsgs = selectedClientId.current;
        return;
      }

      // We check first for scroll, because updateMsgs() updates our lastMsg ref...
      if (
        lastMsg.current !== null &&
        new Date(lastMsg.current.created_at).getTime() <
          new Date(data.allMsgs[0].created_at).getTime()
      ) {
        // This is a new msg... so we need to scroll
        moveScrollToBottom();
      }
    });
  }, [socket, socket.current, messages]);

  return (
    <BodyDashboardContext.Provider
      value={{
        isFetchingMoreMsgs,
        isLoadingMoreMsgs,
        fetchMoreMsgs,

        setIsLoadingMoreMsgs,
      }}
    >
      <div className={styles.body}>
        {show() && (
          <>
            <Info />
            <Messages />
            <InputChat />
          </>
        )}
        {!show() && <div className={styles.no_chat}>Escoge un chat</div>}
      </div>
    </BodyDashboardContext.Provider>
  );
};

export default Body;
