import React, { useContext } from 'react';
import stylesLayout from '../../components/Layout/Layout.module.css';
import styles from './Settings.module.css';
import { Helmet } from 'react-helmet-async';
import Profile from '../../components/Panel/Profile/Profile';
import { AppContext } from '../../App';
import { LayoutContext } from '../../components/Layout/Layout';
import { fetcher } from '../../utils/fetcher';
import { logout } from '../../routes/auth.routes';
import { SettingsLayoutContext } from '../../components/Layout/Settings/SettingsLayout';
import ReactTooltip from 'react-tooltip';

const Settings: React.FunctionComponent = (): JSX.Element => {
  const { user } = useContext(AppContext);
  const { addStaticMsg } = useContext(LayoutContext);
  const { setIsModalChangePswd } = useContext(SettingsLayoutContext);

  const logOut = (): void => {
    const doLogout = async (): Promise<void> => {
      try {
        const res = await fetcher[logout.method]({
          uri: logout.url,
        });
        const resData = res.data;

        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        window.localStorage.clear();
        location.reload();
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al cerrar sesión', 'danger');
        window.localStorage.clear();
        location.reload();
      }
    };

    void doLogout();
  };

  const openChangePwdModal = (): void => {
    setIsModalChangePswd(true);
  };

  return (
    <>
      <Helmet>
        <title>Rhyno | Ajustes</title>
      </Helmet>
      <div className={stylesLayout.layout}>
        <aside className={stylesLayout.aside}>
          <div className={styles.profile}>
            {user !== null && (
              <Profile user={user} atribute="profile-user-settings" />
            )}
          </div>
          <div onClick={logOut} className={styles.logout}>
            Cerrar sesión
          </div>
        </aside>
        <section className={stylesLayout.section}>
          <h2 className={stylesLayout.section_title}>Perfil</h2>
          <div className={styles.pswd}>
            <div className={styles.pswd_text}>Contraseña:</div>
            <div className={styles.pswd_dots}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div
              className={styles.pswd_icon}
              data-tip
              data-for="settings-section-edit-pswd-icon"
              onClick={openChangePwdModal}
            >
              <svg viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.08646 1.94804L11.1222 4.06251C11.208 4.15159 11.208 4.29693 11.1222 4.38601L6.19306 9.50576L4.09861 9.74722C3.81875 9.78004 3.58177 9.53389 3.61337 9.24321L3.84583 7.06779L8.775 1.94804C8.86076 1.85896 9.00069 1.85896 9.08646 1.94804ZM12.7427 1.41121L11.6413 0.26724C11.2983 -0.0890799 10.7408 -0.0890799 10.3955 0.26724L9.59653 1.09709C9.51076 1.18617 9.51076 1.33151 9.59653 1.42059L11.6323 3.53507C11.7181 3.62415 11.858 3.62415 11.9438 3.53507L12.7427 2.70522C13.0858 2.34655 13.0858 1.76753 12.7427 1.41121ZM8.66667 8.1133V10.4997H1.44444V2.99824H6.6309C6.70312 2.99824 6.77083 2.96777 6.82274 2.91619L7.72552 1.97851C7.89705 1.80035 7.77517 1.49795 7.53368 1.49795H1.08333C0.485243 1.49795 0 2.00195 0 2.62317V10.8748C0 11.496 0.485243 12 1.08333 12H9.02778C9.62587 12 10.1111 11.496 10.1111 10.8748V7.17562C10.1111 6.92479 9.81997 6.80055 9.64844 6.97636L8.74566 7.91405C8.69601 7.96796 8.66667 8.03829 8.66667 8.1133Z" />
              </svg>
            </div>
            <ReactTooltip
              id="settings-section-edit-pswd-icon"
              effect="solid"
              type="dark"
              place="top"
            >
              Cambiar contraseña
            </ReactTooltip>
          </div>
        </section>
      </div>
    </>
  );
};

export default Settings;
