import { MESSAGE } from '../../../../../types/messages/messages.types';

export interface SORTED_MSGS {
  lastMsg: MESSAGE | null;
  firstMsg: MESSAGE | null;
  arrayMsgsRead: MESSAGE[];
  arrayMsgsUnRead: MESSAGE[];
}

// We sort in reverse order
export const sortByDate = (messages: MESSAGE[]): SORTED_MSGS => {
  if (messages === undefined)
    return {
      lastMsg: null,
      firstMsg: null,
      arrayMsgsRead: [],
      arrayMsgsUnRead: [],
    };

  let lastMsg: MESSAGE | null = null;
  let firstMsg: MESSAGE | null = null;

  const arrayMsgsRead: MESSAGE[] = messages.filter(
    (msg: MESSAGE) => !msg.from_client || msg.is_read
  );
  const arrayMsgsUnRead: MESSAGE[] = messages.filter(
    (msg: MESSAGE) => msg.from_client && !msg.is_read
  );

  arrayMsgsRead.sort((a: MESSAGE, b: MESSAGE) => {
    if (a.created_at < b.created_at) {
      return 1;
    }
    if (a.created_at > b.created_at) {
      return -1;
    }
    return 0;
  });

  arrayMsgsUnRead.sort((a: MESSAGE, b: MESSAGE) => {
    if (a.created_at < b.created_at) {
      return 1;
    }
    if (a.created_at > b.created_at) {
      return -1;
    }
    return 0;
  });

  if (arrayMsgsRead.length > 0) {
    firstMsg = arrayMsgsRead[arrayMsgsRead.length - 1];
    lastMsg = arrayMsgsRead[0];
  }

  if (arrayMsgsUnRead.length > 0) {
    lastMsg = arrayMsgsUnRead[0];
    if (firstMsg === null) {
      firstMsg = arrayMsgsUnRead[arrayMsgsUnRead.length - 1];
    }
  }

  return {
    lastMsg,
    firstMsg,
    arrayMsgsRead,
    arrayMsgsUnRead,
  };
};
