// TEMPORAL
export const DEBUG: boolean = false;
export const DEV: boolean = false;
export const API_URL: string = DEV
  ? 'http://127.0.0.1:5001'
  : 'https://api.comunica.me';
// TEMPORAL

export const ROUTE_HOME = '/';

export const ROUTE_DASHBOARD = '/aplicacion';
export const ROUTE_DASHBOARD_NAME = 'aplicacion';

export const ROUTE_SETTINGS = '/ajustes';
export const ROUTE_SETTINGS_NAME = 'ajustes';

export const ROUTE_COMPANY_ADMIN_PANEL = '/panel';
export const ROUTE_COMPANY_ADMIN_PANEL_NAME = 'panel';

export const ROUTE_ADMIN_PANEL = '/admin';
export const ROUTE_ADMIN_PANEL_NAME = 'admin';

// Routes
export const authProtectedRoutes = [
  ROUTE_DASHBOARD,
  ROUTE_SETTINGS,
  ROUTE_COMPANY_ADMIN_PANEL,
  ROUTE_ADMIN_PANEL,
]; // Necesitas auth

export const agentRoutes = [ROUTE_DASHBOARD, ROUTE_SETTINGS]; // Routes para los agentes

export const companyAdminRoutes = [
  ROUTE_SETTINGS,
  ROUTE_DASHBOARD,
  ROUTE_COMPANY_ADMIN_PANEL,
]; // Routes para los admin de las empresas

export const adminRoutes = [
  ROUTE_SETTINGS,
  ROUTE_ADMIN_PANEL,
  ROUTE_DASHBOARD,
  ROUTE_COMPANY_ADMIN_PANEL,
]; // Routes para los admin

export const authRestrictedRoute: string = ROUTE_HOME; // Con auth no puedes estar aqui
