import React from 'react';
import { LINK_ASIDE } from '../../../types/admin/admin.types';

const CompaniesIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z" />
    </svg>
  );
};
const DataIcon: React.FunctionComponent = () => {
  return (
    <svg viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.375 2.03125V3.25C11.375 4.37227 8.82832 5.28125 5.6875 5.28125C2.54668 5.28125 0 4.37227 0 3.25V2.03125C0 0.908984 2.54668 0 5.6875 0C8.82832 0 11.375 0.908984 11.375 2.03125ZM9.98359 5.45137C10.5117 5.26348 10.9967 5.02227 11.375 4.7252V7.3125C11.375 8.43477 8.82832 9.34375 5.6875 9.34375C2.54668 9.34375 0 8.43477 0 7.3125V4.7252C0.37832 5.02481 0.863281 5.26348 1.39141 5.45137C2.53145 5.85762 4.0498 6.09375 5.6875 6.09375C7.3252 6.09375 8.84355 5.85762 9.98359 5.45137ZM0 8.7877C0.37832 9.08731 0.863281 9.32598 1.39141 9.51387C2.53145 9.92012 4.0498 10.1562 5.6875 10.1562C7.3252 10.1562 8.84355 9.92012 9.98359 9.51387C10.5117 9.32598 10.9967 9.08477 11.375 8.7877V10.9688C11.375 12.091 8.82832 13 5.6875 13C2.54668 13 0 12.091 0 10.9688V8.7877Z" />
    </svg>
  );
};

interface Props {
  state: LINK_ASIDE;
}

const Icons: React.FunctionComponent<Props> = ({ state }) => {
  return (
    <>
      {state === 'companies' && <CompaniesIcon />}
      {state === 'view' && <CompaniesIcon />}
      {state === 'data' && <DataIcon />}
    </>
  );
};

export default Icons;
