import styles from './Template.module.css';
import React from 'react';
import {
  TICKET_SCHEMA,
  TICKET_TYPES,
} from '../../../../../types/ticket/ticket.types';

const mapTicketTypesToText = new Map<TICKET_TYPES, string>();
mapTicketTypesToText.set('bool', 'Sí/No');
mapTicketTypesToText.set('float', 'Número');
mapTicketTypesToText.set('int', 'Número');
mapTicketTypesToText.set('str', 'Texto');

interface Props {
  template: TICKET_SCHEMA;
  isActual: boolean;
}

const Template: React.FunctionComponent<Props> = ({ template, isActual }) => {
  return (
    <div className={`${styles.wrap} ${isActual && styles.actual}`}>
      {isActual && (
        <>
          <div className={styles.actual_shadow}></div>
          <div className={styles.actual_text}>Actual</div>
        </>
      )}
      <div className={styles.template}>
        <div className={styles.template_top}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M208 80c0-26.5 21.5-48 48-48h64c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48h-8v40H464c30.9 0 56 25.1 56 56v32h8c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H464c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h8V288c0-4.4-3.6-8-8-8H312v40h8c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H256c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h8V280H112c-4.4 0-8 3.6-8 8v32h8c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h8V288c0-30.9 25.1-56 56-56H264V192h-8c-26.5 0-48-21.5-48-48V80z" />
          </svg>
          <span>Plantilla: {template.name}</span>
        </div>
        <span className={styles.des}>{template.description}</span>
        {Object.keys(template.fields).map((field: string, index: number) => {
          return (
            <div key={index} className={styles.field}>
              <span className={styles.field_name}>{field}:</span>
              <span className={styles.field_type}>
                {mapTicketTypesToText.get(template.fields[field])}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Template;
