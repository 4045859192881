import React, { useContext, useEffect } from 'react';
import styles from './Info.module.css';
import Skeleton from './Skeleton/Skeleton';
import {
  GET_CLIENT_DATA,
  GET_TICKETS_CLIENT_DATA,
  GET_NOTES_CLIENT_DATA,
} from '../../../types/socket/index.types';
import { DashboardContext } from '../Dashboard';
import { DashboardLayoutContext } from '../../../components/Layout/Dashboard/DashboardLayout';
import { LayoutContext } from '../../../components/Layout/Layout';
import { AppContext } from '../../../App';
import { PersistentAgentContext } from '../../../components/Layout/Context/PersistentAgent';
import { PersistentCAdminContext } from '../../../components/Layout/Context/PersistentCAdmin';
import InfoElement from './Element/Element';
import { fetcher, RESPONSE } from '../../../utils/fetcher';
import {
  TICKET,
  TICKET_SCHEMA,
  TICKET_FIELDS,
  TICKET_TYPES,
} from '../../../types/ticket/ticket.types';
import { TEMP_ID_PUSH_TICKETS } from '../../../config/constraints';
import {
  createTicket as createTicketFetch,
  CREATE_TICKET_BODY,
} from '../../../routes/tickets.routes';
import { RESPONSE_DATA } from '../../../routes/index.routes';
import {
  getAllMongoSchemasOfCompany,
  GET_MONGO_SCHEMAS_DATA,
} from '../../../routes/schemas.routes';
import Tickets from './Tickets/Tickets';
import Notes from './Notes/Notes';
import { InfoIcon } from '@chakra-ui/icons';
import ReactTooltip from 'react-tooltip';

const InfoNotes: React.FunctionComponent = (): JSX.Element => {
  const {
    isLoadingClients,
    setIsLoadingGetClient,
    setIsLoadingGetClientTickets,
    setIsLoadingGetClientNotes,
    isLoadingGetClient,

    isLoadingGetClientTickets,
    isLoadingGetClientNotes,
    setIsModalNotes,
  } = useContext(DashboardContext);
  const {
    selectedClient,
    socket,
    selectedClientId,
    setTickets,
    selectedClientSchema,
  } = useContext(PersistentAgentContext);

  const { company } = useContext(AppContext);

  const { setTemplatesTicketsWindow, hasFetchTemplates } = useContext(
    PersistentCAdminContext
  );

  const { updateAllClients, controllerOnInfo } = useContext(
    DashboardLayoutContext
  );
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);

  const getWhatsApp = (): string => {
    if (selectedClient === null) return '';
    if (selectedClient.whatsapp === null) return '';

    return selectedClient.whatsapp;
  };

  const getEmail = (): string => {
    if (selectedClient === null) return '';
    if (selectedClient.email === null) return '';

    return selectedClient.email;
  };

  const getIdTicket = (): string => {
    return `${TEMP_ID_PUSH_TICKETS}:${new Date().getTime()}`;
  };

  const pushLoadingTicket = (): string | null => {
    if (selectedClientId.current === null) return null;

    const newTicket: TICKET = {
      id: getIdTicket(),
      client: selectedClientId.current,
      tag: null,
      extra: {},
      created_at: new Date(),
      title: 'Cargando...',
    };

    setTickets((prev) => [newTicket, ...prev]);

    return newTicket.id;
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const createTicket = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (selectedClientId.current === null) return;
        const body: CREATE_TICKET_BODY = {
          client: selectedClientId.current,
        };

        // Add loading ticket
        const temporalTicketId = pushLoadingTicket();
        if (temporalTicketId === null) return;

        const res = await fetcher[createTicketFetch.method]({
          uri: createTicketFetch.url,
          body,
        });
        const resData = res.data;
        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const updateTickets = (prev: TICKET[], newT: TICKET): TICKET[] => {
          const a: TICKET[] = [];

          a.push(newT);

          for (let i = 0; i < prev.length; i++) {
            if (temporalTicketId === prev[i].id) continue;

            a.push(prev[i]);
          }

          return a;
        };

        setTickets((prev) => updateTickets(prev, resData.data));

        // At the end we update every client
        updateAllClients(selectedClientId.current, 'ticket');
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al crear el ticket', 'danger');
        void askToDoRefresh();
      }
    };

    void doFetch();
  };

  const openModalNotes = (): void => {
    setIsModalNotes(true);
  };

  useEffect(() => {
    if (socket === null) return;
    if (socket.current === null) return;
    if (controllerOnInfo.current) return;
    controllerOnInfo.current = true;

    socket.current.on('client', (res: any) => {
      const data: GET_CLIENT_DATA = res;
      if (selectedClientId.current !== data.client.id) return;

      setIsLoadingGetClient(false);
    });

    socket.current.on('tickets', (res: any) => {
      const data: GET_TICKETS_CLIENT_DATA = res;
      if (selectedClientId.current !== data.clientId) return;
      setIsLoadingGetClientTickets(false);
    });

    socket.current.on('notes', (res: any) => {
      const data: GET_NOTES_CLIENT_DATA = res;
      if (selectedClientId.current !== data.clientId) return;
      setIsLoadingGetClientNotes(false);
    });
  }, [socket, socket?.current]);

  const getTemplatesController = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (company === null) return;
        hasFetchTemplates.current = true;
        const res: RESPONSE = await fetcher[getAllMongoSchemasOfCompany.method](
          { uri: getAllMongoSchemasOfCompany.url(company.id) }
        );
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const templates: GET_MONGO_SCHEMAS_DATA = resData.data;
        setTemplatesTicketsWindow(templates);
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al obtener las plantillas de la empresa', 'danger');
        void askToDoRefresh();
      }
    };
    void doFetch();
  };

  const needsToDoFetch = (): void => {
    if (hasFetchTemplates.current) return;
    getTemplatesController();
  };

  useEffect(() => {
    needsToDoFetch();
  }, []);

  const getFieldsClient = (): TICKET_FIELDS => {
    const lastT: TICKET_SCHEMA | null = selectedClientSchema;
    if (lastT === null) return {};
    return lastT.fields;
  };

  const getTypeOfField = (key: string): TICKET_TYPES | 'text' => {
    const lastT: TICKET_SCHEMA | null = selectedClientSchema;
    if (lastT === null) return 'text';
    const v: TICKET_TYPES = lastT.fields[key];
    if (v === 'str') return 'text';
    return lastT.fields[key];
  };

  const getValueFromField = (key: string): string | boolean | number | null => {
    if (selectedClient === null) return '';
    if (selectedClient.extra === null) return '';
    return selectedClient.extra[key];
  };

  if (isLoadingClients || isLoadingGetClient) {
    return (
      <div className={styles.notes}>
        <Skeleton />
      </div>
    );
  }

  if (selectedClient === null) {
    return <div className={styles.notes}></div>;
  }

  return (
    <div className={`${styles.notes} ${styles.notes_gray}`}>
      <div className={styles.notes_header}>
        <div>👤 Datos</div>
        <div className={styles.notes_header_info}>
          <InfoIcon data-tip data-for="info-icon-for-updates-client" />
          <ReactTooltip
            className={styles.tooltip}
            id="info-icon-for-updates-client"
            effect="solid"
            type="dark"
            place="left"
          >
            La información del cliente se puede editar
            <br />
            con tan solo un click.
            <br />
            Para guardar pulsa ENTER.
          </ReactTooltip>
        </div>
      </div>
      <div className={styles.notes_container}>
        <InfoElement
          label="Nombre"
          value={selectedClient.name}
          labelKey="name"
          type="text"
        />
        <InfoElement
          label="Teléfono"
          value={getWhatsApp()}
          labelKey="whatsapp"
          type="text"
        />
        <InfoElement
          type="email"
          label="Correo"
          value={getEmail()}
          labelKey="email"
        />
        {Object.keys(getFieldsClient()).map((field: string, index: number) => {
          return (
            <React.Fragment key={index}>
              <InfoElement
                type={getTypeOfField(field)}
                labelKey={field}
                label={field}
                value={getValueFromField(field)}
                isMongo
              />
            </React.Fragment>
          );
        })}
        <InfoElement
          onClick={createTicket}
          label="Tickets"
          value="Agregar ticket +"
          isLoading={isLoadingGetClientTickets}
        />
        <Tickets />
        <InfoElement
          onClick={openModalNotes}
          label="Notas"
          value="Agregar nota +"
          isLoading={isLoadingGetClientNotes}
          className={styles.notes_element}
        />
        <Notes />
      </div>
    </div>
  );
};

export default InfoNotes;
