import React, { useEffect, useState, useContext, useRef } from 'react';
import styles from './Container.module.css';
import {
  TICKET_EXTRA,
  TAG,
  TICKET_TYPES,
  TICKET,
} from '../../../../types/ticket/ticket.types';
import ExtraTicket from '../Extra/Extra';
import TagTicket from '../Tag/Tag';
import NotesTicket from '../Notes/Notes';
import TagsCompany from '../TagsCompany/TagsCompany';
import NewNote from '../NewNote/NewNote';
import Title from '../Title/Title';
import { Button } from '@chakra-ui/react';
import { TicketModalContext } from '../Ticket';
import { PersistentAgentContext } from '../../../Layout/Context/PersistentAgent';
import { LayoutContext } from '../../../Layout/Layout';
import { DashboardLayoutContext } from '../../../Layout/Dashboard/DashboardLayout';
import { DashboardContext } from '../../../../pages/Dashboard/Dashboard';
import {
  updateTicket as updateTicketRoute,
  UPDATE_TICKET_BODY,
} from '../../../../routes/tickets.routes';
import { fetcher } from '../../../../utils/fetcher';

const TicketContainer: React.FunctionComponent = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isEditTag, isAddingNote } = useContext(TicketModalContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const { setTickets } = useContext(PersistentAgentContext);
  const { dataTicket } = useContext(DashboardLayoutContext);
  const { selectedTicketId, setIsModalTicket, isModalTicket } =
    useContext(DashboardContext);

  // New
  const [ticketGroup, setTicketGroup] = useState<TICKET_EXTRA>({});
  const [newTag, setNewTag] = useState<TAG | null>(null);
  const [newTitle, setNewTitle] = useState<string>('');
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);

  const populateDataTicketRef = useRef<boolean>(false);

  useEffect(() => {
    populateDataTicketRef.current = false;

    const timeOut = setTimeout(() => {
      setIsEditingTitle(false);
      clearTimeout(timeOut);
    }, 500);
  }, [isModalTicket]);

  useEffect(() => {
    // Populate new state
    if (dataTicket === null) return;

    if (populateDataTicketRef.current) return;
    populateDataTicketRef.current = true;

    setTicketGroup(dataTicket.ticket.extra);
    setNewTag(dataTicket.tag);
    setNewTitle(dataTicket.ticket.title);
  }, [dataTicket]);

  if (dataTicket === null) return <div></div>;

  const getKeysThatWereUpdated = (): string[] => {
    const keys: string[] = [];

    // Add schema keys
    for (let i = 0; i < Object.keys(dataTicket.ticket.extra).length; i++) {
      const key: string = Object.keys(dataTicket.ticket.extra)[i];
      if (key === 'tag') continue; // To avoid collision
      if (key === 'title') continue; // To avoid collision

      const prevValue: string | number | boolean | null =
        dataTicket.ticket.extra[key];
      const typeValue: TICKET_TYPES | undefined =
        dataTicket.schema?.fields[key];
      const newValue: string | number | boolean | null = ticketGroup[key];

      if (typeValue === undefined) continue; // Error

      if (typeValue === 'int' || typeValue === 'float') {
        if (prevValue?.toString() === newValue?.toString()) {
          continue;
        }
      } else {
        if (prevValue === newValue) {
          continue;
        }
      }

      keys.push(key);
    }

    // Add title
    if (newTitle.trim() !== '' && newTitle !== dataTicket.ticket.title) {
      keys.push('title');
    }

    // Add "tag" key:
    if (newTag === null && dataTicket.tag === null) return keys;
    if (newTag !== null && dataTicket.tag !== null) {
      if (newTag.id === dataTicket.tag.id) return keys;
    }

    keys.push('tag');

    return keys;
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const getArrPromises = (): Array<Promise<void>> => {
    const keys: string[] = getKeysThatWereUpdated();
    const arr: Array<Promise<void>> = [];

    const doFetch = async (key: string): Promise<void> => {
      try {
        let currVal: string | number | boolean | null = null;

        if (dataTicket.schema === null) return;

        const t: TICKET_TYPES = dataTicket.schema.fields[key];
        // TAG
        if (key === 'tag' && newTag !== null) {
          currVal = newTag.id;
        }
        // Title
        else if (key === 'title' && newTitle.trim() !== '') {
          currVal = newTitle;
        }
        // Extra
        else if (ticketGroup[key] !== undefined) {
          currVal = ticketGroup[key];
        }

        // Type of inputs
        if (t === 'float') {
          currVal = parseFloat(currVal as any);
        } else if (t === 'int') {
          currVal = parseInt(currVal as any);
        }

        if (selectedTicketId.current === null) return;

        // In case we deselected the tag
        if ((currVal === null || currVal === undefined) && key === 'tag') {
          currVal = null;
        }

        const body: UPDATE_TICKET_BODY = {
          key,
          value: currVal,
        };
        const res = await fetcher[updateTicketRoute.method]({
          uri: updateTicketRoute.url(selectedTicketId.current),
          body,
        });

        const resData = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }
      } catch (error) {
        console.error(error);
        addStaticMsg('Hubo un error al actualizar el ticket', 'danger');
        void askToDoRefresh();
      }
    };

    for (let i = 0; i < keys.length; i++) {
      arr.push(
        new Promise<void>((resolve) => {
          const f = async (): Promise<void> => {
            await doFetch(keys[i]);
            resolve();
          };
          void f();
        })
      );
    }

    return arr;
  };

  const updateStateTickets = (prev: TICKET[], n: TICKET): TICKET[] => {
    const a: TICKET[] = [];

    for (let i = 0; i < prev.length; i++) {
      if (prev[i].id !== n.id) {
        a.push(prev[i]);
        continue;
      }

      a.push(n);
    }

    return a;
  };

  const updateTicket = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (newTitle.trim() === '') {
      addStaticMsg('El nombre no puede estar vacio!', 'danger');
      return;
    }

    const promises: Array<Promise<void>> = getArrPromises();
    const updatePromises = async (): Promise<void> => {
      setIsLoading(true);
      await Promise.all(promises);
      setIsLoading(false);
      setIsModalTicket(false);
      addStaticMsg('Ticket actualizado correctamente!', 'success');

      // ticketGroup
      // newTag
      setTickets((prev) =>
        updateStateTickets(prev, {
          id: dataTicket.ticket.id,
          client: dataTicket.ticket.client,
          tag: newTag,
          extra: ticketGroup,
          created_at: dataTicket.ticket.created_at,
          title: newTitle,
        })
      );
    };

    if (promises.length > 0) {
      void updatePromises();
    }
  };

  if (isEditTag)
    return (
      <div className={styles.ticket}>
        <div className={styles.title}>Editar ticket</div>
        <TagsCompany newTag={newTag} setNewTag={setNewTag} />
      </div>
    );

  if (isAddingNote)
    return (
      <div className={styles.ticket}>
        <div className={styles.title}>Editar ticket</div>
        <NewNote />
      </div>
    );

  return (
    <div className={styles.ticket}>
      <div className={styles.title}>
        <Title
          newTitle={newTitle}
          isEditingTitle={isEditingTitle}
          setIsEditingTitle={setIsEditingTitle}
          defaultTitle={dataTicket.ticket.title}
          setNewTitle={setNewTitle}
        />
      </div>
      <form onSubmit={updateTicket} className={styles.form_wrapper}>
        <div className={styles.form_actions}>
          <ExtraTicket
            setTicketGroup={setTicketGroup}
            ticketGroup={ticketGroup}
          />
          <div className={styles.form_right}>
            <TagTicket newTag={newTag} />
            <NotesTicket />
          </div>
        </div>
        <div className={styles.form_btn}>
          <Button isLoading={isLoading} type="submit">
            Actualizar ticket
          </Button>
        </div>
      </form>
    </div>
  );
};

export default TicketContainer;
