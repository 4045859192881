import React from 'react';
import styles from './UserProfile.module.css';
import { CLIENT, CLIENT_ASIDE } from '../../../../types/client/chat.types';
import { getRawText } from '../../../../utils/getRawText';

interface Props {
  user: CLIENT | CLIENT_ASIDE;
  isGateway: boolean;
  isUnreadMsgs?: boolean;
}

const UserProfile: React.FunctionComponent<Props> = ({
  user,
  isGateway,
  isUnreadMsgs = false,
}): JSX.Element => {
  const getName = (): string => {
    if ((user as CLIENT).id !== undefined) {
      return getRawText((user as CLIENT).name);
    }

    return getRawText((user as CLIENT_ASIDE).client_name);
  };

  const getUnread = (): number => {
    if ((user as CLIENT_ASIDE).client_id !== undefined) {
      return (user as CLIENT_ASIDE).unread;
    }

    return 0;
  };

  return (
    <div className={styles.user_profile}>
      <div className={styles.user_profile_img}>
        {getName().split(' ')[0][0]}
        {getName().split(' ').length > 1 && getName().split(' ')[1][0]}
      </div>
      {isUnreadMsgs && getUnread() > 0 && (
        <div className={styles.user_profile_unread}>
          {getUnread() > 99 ? '+99' : getUnread()}
        </div>
      )}
      {isGateway && (
        <div className={styles.user_profile_gateway}>
          {/* <img src={`gateways/${user.gateway}.png`} alt="Aplicación" /> */}
          <img src={`gateways/Messenger.png`} alt="Aplicación" />
        </div>
      )}
    </div>
  );
};

export default UserProfile;
