import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import Nav from '../../Nav/Settings/SettingsNav';
import { Outlet } from 'react-router-dom';
import styles from './SettingsLayout.module.css';
import ChangePswdModal from '../../../components/Modals/ChangePswd/ChangePswd';

// Context
export const SettingsLayoutContext = createContext({} as SettingsValueProvider);

interface SettingsValueProvider {
  isModalChangePswd: boolean;

  setIsModalChangePswd: Dispatch<SetStateAction<boolean>>;
}

const SettingsLayout: React.FunctionComponent = (): JSX.Element => {
  const [isModalChangePswd, setIsModalChangePswd] = useState<boolean>(false);

  return (
    <SettingsLayoutContext.Provider
      value={{
        isModalChangePswd,

        setIsModalChangePswd,
      }}
    >
      <ChangePswdModal />
      <div className={styles.layout}>
        <Nav />
        <Outlet />
      </div>
    </SettingsLayoutContext.Provider>
  );
};

export default SettingsLayout;
