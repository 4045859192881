import React, { Dispatch, SetStateAction } from 'react';
import styles from './Title.module.css';
import ReactTooltip from 'react-tooltip';

const EditIcon: React.FunctionComponent = () => {
  return (
    <>
      <svg
        data-tip
        data-for="icon-edit-ticket-title"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
      </svg>
      <ReactTooltip
        id="icon-edit-ticket-title"
        effect="solid"
        type="dark"
        place="top"
      >
        Editar nombre
      </ReactTooltip>
    </>
  );
};
const CancelIcon: React.FunctionComponent = () => {
  return (
    <>
      <div
        data-tip
        data-for="icon-cancel-ticket-title"
        className={styles.cancel}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </div>

      <ReactTooltip
        id="icon-cancel-ticket-title"
        effect="solid"
        type="dark"
        place="top"
      >
        Cancelar
      </ReactTooltip>
    </>
  );
};

interface Props {
  newTitle: string;
  defaultTitle: string;
  isEditingTitle: boolean;

  setIsEditingTitle: Dispatch<SetStateAction<boolean>>;
  setNewTitle: Dispatch<SetStateAction<string>>;
}

const TicketTitle: React.FunctionComponent<Props> = ({
  newTitle,
  defaultTitle,
  setNewTitle,
  isEditingTitle,
  setIsEditingTitle,
}) => {
  return (
    <div className={styles.title}>
      {!isEditingTitle && (
        <div className={styles.title_name}>{defaultTitle}</div>
      )}
      {isEditingTitle && (
        <input
          className={styles.title_input}
          type="text"
          placeholder="Nombre del ticket"
          value={newTitle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNewTitle(e.target.value);
          }}
          maxLength={255}
        />
      )}
      <div
        className={styles.title_icon}
        onClick={() => {
          setIsEditingTitle((prev) => !prev);
          setNewTitle(defaultTitle);
        }}
      >
        {!isEditingTitle && <EditIcon />}
        {isEditingTitle && <CancelIcon />}
      </div>
    </div>
  );
};

export default TicketTitle;
