import React, { useContext } from 'react';
import { TICKET } from '../../../../types/ticket/ticket.types';
import styles from './Ticket.module.css';
import { DashboardContext } from '../../Dashboard';
import { DashboardLayoutContext } from '../../../../components/Layout/Dashboard/DashboardLayout';
import { LayoutContext } from '../../../../components/Layout/Layout';
import { fetcher, RESPONSE } from '../../../../utils/fetcher';
import { getTicket, GET_TICKET_DATA } from '../../../../routes/tickets.routes';
import { RESPONSE_DATA } from '../../../../routes/index.routes';
import { TEMP_ID_PUSH_TICKETS } from '../../../../config/constraints';
import { Spinner } from '@chakra-ui/react';
import Tag from '../../../../components/Tags/Tags';

interface Props {
  ticket: TICKET;
  index: number;
}

const Ticket: React.FunctionComponent<Props> = ({
  ticket,
  index,
}): JSX.Element => {
  const { setIsModalTicket, selectedTicketId } = useContext(DashboardContext);
  const { setDataTicket } = useContext(DashboardLayoutContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);

  const openModal = (): void => {
    if (isTicketLoader()) return;
    selectedTicketId.current = ticket.id;
    setIsModalTicket(true);
    getTicketData();
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const getTicketData = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        setDataTicket({
          ticket,
          tag: ticket.tag,
          notes: [],
          schema: null,
        });

        // Para que React no me diga nada
        const res: RESPONSE = await fetcher[getTicket.method]({
          uri: getTicket.url(ticket.id),
        });

        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const fetchData: GET_TICKET_DATA = resData.data;
        if (fetchData.ticket.id !== selectedTicketId.current) return;

        // We only update if data is null or if data its from different ticket
        // Because if data.ticket.id == selectedTicketId.current
        // and it already has some values... we might have a repeated fetch
        // So we don't want to override any local changes... just in case
        setDataTicket(fetchData);
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al obtener la información del ticket', 'danger');
        void askToDoRefresh();
      }
    };

    void doFetch();
  };

  const isTicketLoader = (): boolean => {
    return ticket.id.split(':')[0] === TEMP_ID_PUSH_TICKETS;
  };

  return (
    <div
      onClick={openModal}
      className={`${styles.ticket} ${isTicketLoader() && styles.ticket_wait}`}
      title={isTicketLoader() ? 'Cargando...' : 'Abrir ticket'}
    >
      {isTicketLoader() && (
        <div className={styles.ticket_temp}>
          <Spinner className={styles.ticket_temp_svg} />
        </div>
      )}
      <div className={styles.ticket_left}>
        <div className={styles.ticket_name}>{ticket.title}</div>
        <div className={styles.ticket_date}>
          <Tag tag={ticket.tag} />
        </div>
      </div>
      <div className={styles.ticket_number}>#{index + 1}</div>
    </div>
  );
};

export default Ticket;
