import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useRef,
} from 'react';
import { CLIENT, CLIENT_ASIDE } from '../../../types/client/chat.types';
import {
  TICKET,
  NOTE,
  TICKET_SCHEMA,
} from '../../../types/ticket/ticket.types';
import { MESSAGE } from '../../../types/messages/messages.types';
import { Socket } from 'socket.io-client';
import { KEY_VAL_USER } from '../../../cache/agents';
import {
  ServerToClientEvents,
  ClientToServerEvents,
} from '../../../types/socket/index.types';

export const PersistentAgentContext = createContext(
  {} as ValuePersistentAgentContexttProvider
);

interface ValuePersistentAgentContexttProvider {
  // Dashboard data we need to preserve
  selectedClient: CLIENT | null;
  selectedClientSchema: TICKET_SCHEMA | null;
  selectedClientId: React.MutableRefObject<string | null>;
  lastMsg: React.MutableRefObject<MESSAGE | null>;
  firstMsg: React.MutableRefObject<MESSAGE | null>;
  amountUnReadMsgs: React.MutableRefObject<number>;
  messages: MESSAGE[];
  messagesLoading: MESSAGE[];
  chats: CLIENT_ASIDE[];
  selectedClientIdState: string | null;
  tickets: TICKET[];
  notes: NOTE[];
  usersFromCompany: KEY_VAL_USER;

  setSelectedClient: Dispatch<SetStateAction<CLIENT | null>>;
  setSelectedClientSchema: Dispatch<SetStateAction<TICKET_SCHEMA | null>>;
  setMessages: Dispatch<SetStateAction<MESSAGE[]>>;
  setMessagesLoading: Dispatch<SetStateAction<MESSAGE[]>>;
  setChats: Dispatch<SetStateAction<CLIENT_ASIDE[]>>;
  setTickets: Dispatch<SetStateAction<TICKET[]>>;
  setNotes: React.Dispatch<React.SetStateAction<NOTE[]>>;
  setSelectedClientIdState: Dispatch<SetStateAction<string | null>>;
  socket: React.MutableRefObject<Socket<
    ServerToClientEvents,
    ClientToServerEvents
  > | null>;
  setUsersFromCompany: React.Dispatch<React.SetStateAction<KEY_VAL_USER>>;
  // ------------------------------------
}

interface Props {
  children: any;
}

const Agent: React.FunctionComponent<Props> = ({ children }) => {
  // Dashboard
  const [chats, setChats] = useState<CLIENT_ASIDE[]>([]);
  const [selectedClientIdState, setSelectedClientIdState] = useState<
    string | null
  >(null);
  const socket = useRef<Socket<
    ServerToClientEvents,
    ClientToServerEvents
  > | null>(null);
  const [selectedClient, setSelectedClient] = useState<CLIENT | null>(null);
  const [selectedClientSchema, setSelectedClientSchema] =
    useState<TICKET_SCHEMA | null>(null);
  const selectedClientId = useRef<string | null>(null);
  const lastMsg = useRef<MESSAGE | null>(null);
  const firstMsg = useRef<MESSAGE | null>(null);
  const [tickets, setTickets] = useState<TICKET[]>([]);
  const [notes, setNotes] = useState<NOTE[]>([]);
  const amountUnReadMsgs = useRef<number>(0);
  const [messages, setMessages] = useState<MESSAGE[]>([]);
  const [messagesLoading, setMessagesLoading] = useState<MESSAGE[]>([]);

  // Cache dashboard
  const [usersFromCompany, setUsersFromCompany] = useState<KEY_VAL_USER>(
    new Map()
  );
  // ------------------------------------

  return (
    <PersistentAgentContext.Provider
      value={{
        selectedClient,
        selectedClientSchema,
        selectedClientId,
        lastMsg,
        firstMsg,
        amountUnReadMsgs,
        messages,
        chats,
        selectedClientIdState,
        tickets,
        notes,
        usersFromCompany,
        messagesLoading,

        setSelectedClient,
        setSelectedClientSchema,
        setMessages,
        socket,
        setChats,
        setSelectedClientIdState,
        setTickets,
        setNotes,
        setUsersFromCompany,
        setMessagesLoading,
      }}
    >
      {children}
    </PersistentAgentContext.Provider>
  );
};
export default Agent;
