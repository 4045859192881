import React, { useContext } from 'react';
import styles from './Notes.module.css';
import { PersistentAgentContext } from '../../../../components/Layout/Context/PersistentAgent';
import { NOTE } from '../../../../types/ticket/ticket.types';
import { sortNotes } from '../utils/sort';

const Notes: React.FunctionComponent = () => {
  const { notes, selectedClientId } = useContext(PersistentAgentContext);

  if (notes.length === 0) return <></>;

  return (
    <div className={styles.notes_wrapper}>
      {sortNotes(notes).map((note: NOTE, index: number) => {
        if (note.client !== selectedClientId.current) {
          return <div key={index}></div>;
        }
        return (
          <div key={index} className={styles.note}>
            {note.content}
          </div>
        );
      })}
    </div>
  );
};
export default Notes;
