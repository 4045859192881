import React, { useEffect } from 'react';
import { ROUTE_HOME } from '../../config/routes';

const NotFound: React.FunctionComponent = (): JSX.Element => {
  useEffect(() => {
    window.open(ROUTE_HOME, '_self');
  }, []);

  return <></>;
};

export default NotFound;
