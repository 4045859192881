import { API_URL } from '../config/routes';
import { METHODS } from '../utils/fetcher';
import { TICKET_FIELDS, TICKET_SCHEMA } from '../types/ticket/ticket.types';

interface REQUEST_PARAM {
  url: (companyId: string) => string;
  method: METHODS;
}

export const createMongoSchema: REQUEST_PARAM = {
  url: (companyId: string) => `${API_URL}/v2/schemas/${companyId}`,
  method: 'post',
};

export interface BODY_CREATE_MONGO_SCHEMA {
  name: string; // max : 128
  description: string; // max : 255
  type: 'client' | 'ticket';
  fields: TICKET_FIELDS;
}

export interface CREATE_MONGO_SCHEMA_DATA extends TICKET_SCHEMA {}

export const getAllMongoSchemasOfCompany: REQUEST_PARAM = {
  url: (companyId: string) => `${API_URL}/v2/schemas/${companyId}`,
  method: 'get',
};

export type GET_MONGO_SCHEMAS_DATA = TICKET_SCHEMA[];
