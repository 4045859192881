import React, { useContext, useRef } from 'react';
import styles from './Messages.module.css';
import { Spinner } from '@chakra-ui/react';
import { DashboardContext } from '../../../Dashboard/Dashboard';
import { PersistentAgentContext } from '../../../../components/Layout/Context/PersistentAgent';
import { BodyDashboardContext } from '../Body';
import Message from './Message/Message';
import { MESSAGE } from '../../../../types/messages/messages.types';
import { useScroll } from '../../../../hooks/useScroll';

const Messages: React.FunctionComponent = (): JSX.Element => {
  const { isLoadingMsgs } = useContext(DashboardContext);

  const { selectedClient, amountUnReadMsgs, messages, messagesLoading } =
    useContext(PersistentAgentContext);

  const { isLoadingMoreMsgs, fetchMoreMsgs, isFetchingMoreMsgs } =
    useContext(BodyDashboardContext);

  const refMsgsContainer = useRef<HTMLDivElement | null>(null);

  useScroll({
    ref: refMsgsContainer,
    callback: fetchMoreMsgs,
    condition: !isFetchingMoreMsgs.current && !isLoadingMsgs,
    isReversed: true,
  });

  return (
    <div id="container-msgs" ref={refMsgsContainer} className={styles.msgs}>
      {isLoadingMsgs && (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )}
      {!isLoadingMsgs && (
        <>
          {/* LOADING */}
          {messagesLoading.map((msg: MESSAGE, index: number) => {
            return (
              <React.Fragment key={index}>
                <Message msg={msg} />
              </React.Fragment>
            );
          })}
          {/* UNREAD */}
          {messages
            .slice(0, amountUnReadMsgs.current)
            .map((msg: MESSAGE, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Message msg={msg} />
                </React.Fragment>
              );
            })}
          {/* UNREAD COUNT */}
          {selectedClient !== null &&
            messages !== undefined &&
            messages.length > 0 &&
            selectedClient.id === messages[0].client &&
            amountUnReadMsgs.current > 0 && (
              <div className={styles.unread_count}>
                {amountUnReadMsgs.current} mensaje
                {amountUnReadMsgs.current > 1 ? 's' : ''} no leído
                {amountUnReadMsgs.current > 1 ? 's' : ''}
              </div>
            )}
          {/* READ */}
          {messages
            .slice(amountUnReadMsgs.current, messages.length)
            .map((msg: MESSAGE, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Message msg={msg} />
                </React.Fragment>
              );
            })}
          {isLoadingMoreMsgs && (
            <div className={styles.loader_more}>
              <Spinner />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Messages;
