import React, { useContext } from 'react';
import styles from './Tickets.module.css';
import { TICKET } from '../../../../types/ticket/ticket.types';
import { DashboardContext } from '../../Dashboard';
import { PersistentAgentContext } from '../../../../components/Layout/Context/PersistentAgent';
import Ticket from '../Ticket/Ticket';
import { sortTickets } from '../utils/sort';

const InfoNotes: React.FunctionComponent = (): JSX.Element => {
  const { isLoadingGetClientTickets, isLoadingGetClient, isLoadingClients } =
    useContext(DashboardContext);
  const { selectedClientId, tickets } = useContext(PersistentAgentContext);

  if (isLoadingClients || isLoadingGetClient) {
    // Nothing
    return <div></div>;
  }

  if (isLoadingGetClientTickets) {
    return <></>;
  }

  if (tickets.length === 0) return <></>;

  return (
    <div className={styles.tickets_wrapper}>
      {sortTickets(tickets).map((ticket: TICKET, index: number) => {
        if (selectedClientId.current !== ticket.client) return null;
        return (
          <React.Fragment key={index}>
            <Ticket ticket={ticket} index={index} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default InfoNotes;
