import React, { useContext, useState, useEffect } from 'react';
import styles from './Companies.module.css';
import { InfoIcon } from '@chakra-ui/icons';
import ReactTooltip from 'react-tooltip';
import Skeleton from './Skeleton/Skeleton';
import { PersistentAdminContext } from '../../../../components/Layout/Context/PersistentAdmin';
import { AdminLayoutContext } from '../../../../components/Layout/Admin/AdminLayout';
import { LayoutContext } from '../../../../components/Layout/Layout';
import { fetcher, RESPONSE } from '../../../../utils/fetcher';
import { COMPANY } from '../../../../types/company/company.types';
import {
  getAllCompanies,
  DATA_GET_ALL_COMPANIES,
} from '../../../../routes/company.routes';
import { RESPONSE_DATA } from '../../../../routes/index.routes';

const RhynoCompanies: React.FunctionComponent = () => {
  const {
    setAllCompanies,
    hasFetchCompanies,
    allCompanies,
    setWindowState,
    setSelectedCompany,
  } = useContext(PersistentAdminContext);
  const { addAsyncMsg, addStaticMsg } = useContext(LayoutContext);
  const { setIsModalAddCompany } = useContext(AdminLayoutContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const getAllCompaniesController = (): void => {
    const doFetch = async (): Promise<void> => {
      try {
        if (isLoading) return;
        setIsLoading(true);
        hasFetchCompanies.current = true;
        const res: RESPONSE = await fetcher[getAllCompanies.method]({
          uri: getAllCompanies.url,
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }

        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const dataCompanies: DATA_GET_ALL_COMPANIES = resData.data;
        setAllCompanies(dataCompanies);
      } catch (error) {
        console.error(error);
        addStaticMsg('Error al obtener las empresas de Rhyno', 'danger');
        void askToDoRefresh();
        setIsLoading(false);
      }
    };

    void doFetch();
  };

  const needsToDoFetch = (): void => {
    if (hasFetchCompanies.current) return;

    getAllCompaniesController();
  };

  const addCompany = (): void => {
    setIsModalAddCompany(true);
  };

  const clickCompany = (company: COMPANY): void => {
    // Open new page
    setSelectedCompany(company);
    setWindowState('view');
  };

  useEffect(() => {
    needsToDoFetch();
  }, []);

  return (
    <div className={styles.companies}>
      <div className={styles.fluid}>
        <div className={styles.add}>
          <button
            data-tip
            data-for="button-add-company-admin"
            className={styles.add_company}
            onClick={addCompany}
          >
            +
          </button>
          <span>Empresas</span>
          <ReactTooltip
            id="button-add-company-admin"
            effect="solid"
            type="dark"
            place="top"
          >
            Añadir empresa
          </ReactTooltip>
        </div>
        <div
          className={styles.info}
          data-tip
          data-for="info-admin-add-companies"
        >
          <InfoIcon />
        </div>
        <ReactTooltip
          id="info-admin-add-companies"
          effect="solid"
          type="dark"
          place="top"
        >
          Para navegar dentro de las empresas da click en ellas.
        </ReactTooltip>
      </div>
      {isLoading && <Skeleton />}
      {!isLoading && (
        <div className={styles.data}>
          <div className={styles.companies_container}>
            {allCompanies.map((company: COMPANY, index: number) => {
              const opposite: boolean = company.is_active;
              if (!opposite)
                return (
                  <React.Fragment key={`${index}:active`}></React.Fragment>
                );
              return (
                <div
                  onClick={() => {
                    clickCompany(company);
                  }}
                  className={styles.company}
                  key={`${index}:active`}
                >
                  {company.name}
                </div>
              );
            })}
          </div>
          <div className={styles.line}></div>
          <div className={styles.in_active_companies}>
            <div className={styles.label}>Deshabilitadas:</div>
            <div className={styles.companies_container}>
              {allCompanies.map((company: COMPANY, index: number) => {
                const state: boolean = company.is_active;
                if (state)
                  return (
                    <React.Fragment key={`${index}:inactive`}></React.Fragment>
                  );
                return (
                  <div
                    onClick={() => {
                      clickCompany(company);
                    }}
                    className={styles.company}
                    key={`${index}:inactive`}
                  >
                    {company.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RhynoCompanies;
