import { REQUEST } from './index.routes';
import { METHODS } from '../utils/fetcher';
import { TICKET, TAG, NOTE, TICKET_SCHEMA } from '../types/ticket/ticket.types';
import { API_URL } from '../config/routes';

export const createTicket: REQUEST = {
  url: `${API_URL}/v2/tickets`,
  method: 'post',
};

export interface CREATE_TICKET_BODY {
  client: string;
}

interface REQUEST_PARAM {
  url: (ticketId: string) => string;
  method: METHODS;
}

export const getTicket: REQUEST_PARAM = {
  url: (ticketId: string) => `${API_URL}/v2/tickets/${ticketId}`,
  method: 'get',
};

export interface GET_TICKET_DATA {
  ticket: TICKET;
  tag: TAG | null;
  notes: NOTE[] | null;
  schema: TICKET_SCHEMA | null;
}

export const updateTicket: REQUEST_PARAM = {
  url: (ticketId: string) => `${API_URL}/v2/tickets/${ticketId}`,
  method: 'put',
};

export interface UPDATE_TICKET_BODY {
  key: string;
  value: string | number | boolean | null;
}

export const createNote: REQUEST = {
  url: `${API_URL}/v2/notes`,
  method: 'post',
};

export interface CREATE_NOTE_BODY {
  text: string;
  ticket?: string;
  client?: string;
}

export interface CREATE_NOTE_DATA extends NOTE {}
