import { useContext, useEffect } from 'react';
import { PersistentAgentContext } from '../components/Layout/Context/PersistentAgent';
import {
  GET_MESSAGES_DATA,
  GET_CLIENTS_DATA,
  GET_UPDATE_CLIENT_ASIDE_DATA,
  GET_UPDATE_INFO_DATA,
  GET_UPDATE_TICKETS_DATA,
  GET_CLIENT_DATA,
  GET_TICKETS_CLIENT_DATA,
  GET_NOTES_CLIENT_DATA,
  GET_UPDATE_NOTES_DATA,
} from '../types/socket/index.types';
import { DEBUG, ROUTE_DASHBOARD } from '../config/routes';
import { AppContext } from '../App';
import { USER } from '../types/user/user.types';
import { useLocation } from 'react-router-dom';
import { MESSAGE } from '../types/messages/messages.types';
import {
  setChatsUtil,
  updateThisChatRead,
  updateChats,
} from '../pages/Dashboard/Chats/utils/chats';
import { getSortedMsgs } from '../pages/Dashboard/Body/Messages/utils/msgs';
import { SORTED_MSGS } from '../pages/Dashboard/Body/Messages/utils/sort';

let controllerSubscription: boolean = false;

export interface RETURN_TYPE {
  subscribeSocket: () => void;
  unsubscribeSocketDashboard: () => void;
}

export const useRunSocketDefinitions = (): RETURN_TYPE => {
  const {
    socket,
    selectedClientId,
    setMessages,
    amountUnReadMsgs,
    lastMsg,
    firstMsg,
    setChats,
    setSelectedClient,
    setTickets,
    setNotes,
    messagesLoading,
    setMessagesLoading,
    setSelectedClientSchema,
  } = useContext(PersistentAgentContext);
  const location = useLocation();
  const { user } = useContext(AppContext);
  const unsubscribeSocketDashboard = (): void => {
    if (socket === null || socket === undefined) return;
    if (socket.current === null || socket.current === undefined) return;
    socket.current.off('messages');
    socket.current.off('client');
    socket.current.off('tickets');
    socket.current.off('clients');
    socket.current.off('update client aside');
    socket.current.off('update info');
    socket.current.off('update tickets');
  };

  const updateMsgs = (
    aux: MESSAGE[],
    msgs: MESSAGE[],
    allMsgsToRead: boolean
  ): MESSAGE[] => {
    const sortedMsgs: SORTED_MSGS = getSortedMsgs(
      aux,
      msgs,
      allMsgsToRead,
      selectedClientId
    );
    amountUnReadMsgs.current = sortedMsgs.arrayMsgsUnRead.length;
    lastMsg.current = sortedMsgs.lastMsg;
    firstMsg.current = sortedMsgs.firstMsg;

    // First unread, because the flex is reversed
    return [...sortedMsgs.arrayMsgsUnRead, ...sortedMsgs.arrayMsgsRead];
  };

  const checkIfMsgInLoadingWasReceived = (msgs: MESSAGE[]): void => {
    let aux: MESSAGE[] = messagesLoading;
    const myUser: USER | null = user;

    if (myUser === null) return;

    for (let i = 0; i < msgs.length; i++) {
      const msg: MESSAGE = msgs[i];

      if (msg.from_client) {
        // We dont care
        continue;
      }

      if (msg.from_agent !== myUser.username) {
        // We dont care
        continue;
      }

      // We need to search in aux, so we can delete it
      for (let j = 0; j < aux.length; j++) {
        const currMsg: MESSAGE = aux[j];
        const timeThis: Date = new Date(currMsg.created_at);
        const timeForeign: Date = new Date(msg.created_at);

        if (timeThis.getTime() > timeForeign.getTime()) {
          // If our msg was created later, then it is not this one
          // We dont alter aux
          continue;
        }

        // Check text
        if (currMsg.content !== msg.content) {
          // Its not the same text
          // We dont alter aux
          continue;
        }

        // Its the same
        // We alter aux
        aux = [...aux.slice(0, j), ...aux.slice(j + 1, aux.length)];

        // We break, so it doesnt delete more than 1 per msg
        break;
      }
    }

    setMessagesLoading(aux);
  };

  const markAsReaded = (clientId: string, unreadValue: number = 0): void => {
    setChats((prev) => updateThisChatRead(prev, clientId, unreadValue));
  };

  const subscribeSocket = (): void => {
    if (socket === null || socket === undefined) return;
    if (socket.current === null || socket.current === undefined) return;
    if (controllerSubscription) return;
    controllerSubscription = true;

    unsubscribeSocketDashboard();

    if (DEBUG) {
      console.log('%cSub...', 'color:#FF00FF; font-size: 32px;');
    }

    socket.current.on('messages', (res) => {
      if (selectedClientId.current === null) return;
      const data: GET_MESSAGES_DATA = res;
      if (data.clientId !== selectedClientId.current) return;

      if (DEBUG) {
        console.log('%cOn messages...', 'color:#00FF00; font-size: 32px;');
      }

      if (data.allMsgs.length === 0) {
        return;
      }

      // We check first for scroll, because updateMsgs() updates our lastMsg ref...
      let allMsgsToRead: boolean = false;
      if (
        lastMsg.current !== null &&
        new Date(lastMsg.current.created_at).getTime() <
          new Date(data.allMsgs[0].created_at).getTime()
      ) {
        // This is a new msg... so we need to scroll
        allMsgsToRead = !data.allMsgs[0].from_client;
      }

      setMessages((prev) => updateMsgs(prev, data.allMsgs, allMsgsToRead));
      checkIfMsgInLoadingWasReceived(data.allMsgs);
    });

    socket.current.on('clients', (res: any) => {
      if (DEBUG) {
        console.log('%cOn clients...', 'color:#00FF00; font-size: 32px;');
      }
      const data: GET_CLIENTS_DATA = res;
      setChats((prev) => setChatsUtil(prev, data.allClients, selectedClientId));
    });

    socket.current.on('update client aside', (res: any) => {
      if (DEBUG) {
        console.log(
          '%cOn update client aside...',
          'color:#00FF00; font-size: 32px;'
        );
      }
      const data: GET_UPDATE_CLIENT_ASIDE_DATA = res;

      if (data.key === 'unread') {
        // We dont need to check if we have this clientId in the aside chat
        markAsReaded(data.clientId, parseInt(data.value));
      }
    });

    socket.current.on('update info', (res: any) => {
      if (DEBUG) {
        console.log('%cOn update info...', 'color:#00FF00; font-size: 32px;');
      }
      const data: GET_UPDATE_INFO_DATA = res;
      if (data.client === null) return;

      // Update left chats
      setChats((prev) => updateChats(prev, data.client));

      // Update selected client
      if (selectedClientId.current !== data.client.id) return;
      setSelectedClient(data.client);
    });

    socket.current.on('update tickets', (res: any) => {
      if (DEBUG) {
        console.log(
          '%cOn update tickets...',
          'color:#00FF00; font-size: 32px;'
        );
      }
      const data: GET_UPDATE_TICKETS_DATA = res;
      if (data.clientId === null) return;

      if (selectedClientId.current !== data.clientId) return;
      if (data.tickets === null) {
        setTickets([]);
        return;
      }
      setTickets(data.tickets);
    });

    socket.current.on('client', (res: any) => {
      const data: GET_CLIENT_DATA = res;
      if (selectedClientId.current !== data.client.id) return;

      if (DEBUG) {
        console.log('%cOn client...', 'color:#00FF00; font-size: 32px;');
      }

      setSelectedClient(data.client);
      setSelectedClientSchema(data.schema);
    });

    socket.current.on('tickets', (res: any) => {
      const data: GET_TICKETS_CLIENT_DATA = res;
      if (selectedClientId.current !== data.clientId) return;

      if (DEBUG) {
        console.log('%cOn tickets...', 'color:#00FF00; font-size: 32px;');
      }

      if (data.tickets === null) {
        setTickets([]);
        return;
      }
      setTickets(data.tickets);
    });

    socket.current.on('notes', (res: any) => {
      const data: GET_NOTES_CLIENT_DATA = res;
      if (selectedClientId.current !== data.clientId) return;

      if (DEBUG) {
        console.log('%cOn notes...', 'color:#00FF00; font-size: 32px;');
      }

      if (data.notes === null) {
        setNotes([]);
        return;
      }
      setNotes(data.notes);
    });

    socket.current.on('update notes', (res: any) => {
      if (DEBUG) {
        console.log('%cOn update notes...', 'color:#00FF00; font-size: 32px;');
      }
      const data: GET_UPDATE_NOTES_DATA = res;
      if (data.clientId === null) return;

      if (selectedClientId.current !== data.clientId) return;
      if (data.notes === null) {
        setNotes([]);
        return;
      }
      setNotes(data.notes);
    });
  };

  useEffect(() => {
    if (!location.pathname.includes(ROUTE_DASHBOARD)) {
      subscribeSocket();
    }
  }, [location]);

  return {
    subscribeSocket,
    unsubscribeSocketDashboard,
  };
};
