import { METHODS } from '../utils/fetcher';
import { USER } from '../types/user/user.types';
import { API_URL } from '../config/routes';

interface REQUEST_PARAM {
  url: (ticketId: string) => string;
  method: METHODS;
}

export const getUsersFromCompanyRoute: REQUEST_PARAM = {
  url: (companyId: string) => `${API_URL}/v1/company/${companyId}/users`,
  method: 'get',
};

export interface DATA_GET_USERS_COMPANY {
  users: USER[];
}
