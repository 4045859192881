import React, { useContext } from 'react';
import styles from './Container.module.css';
import { USER_STATUS, USER } from '../../../../../types/user/user.types';
import ReactTooltip from 'react-tooltip';
import { PersistentAgentContext } from '../../../../../components/Layout/Context/PersistentAgent';
import { CPanelLayoutContext } from '../../../../../components/Layout/CompanyPanel/CompanyPanelLayout';
import User from './User/User';

export const CONTAINER_CLASS = 'container-cpanel-agents';

const mapStatusName = new Map<USER_STATUS, string>();
mapStatusName.set('agent', 'Agentes:');
mapStatusName.set('company_admin', 'Administradores:');
mapStatusName.set('disabled', 'Deshabilitados:');

const mapStatusTooltip = new Map<USER_STATUS, string>();
mapStatusTooltip.set('agent', 'agente');
mapStatusTooltip.set('company_admin', '');
mapStatusTooltip.set('disabled', '');

interface Props {
  status: USER_STATUS;
}

const Container: React.FunctionComponent<Props> = ({ status }) => {
  const { usersFromCompany } = useContext(PersistentAgentContext);
  const { setIsModalAddAgent } = useContext(CPanelLayoutContext);

  const addAnAgent = (): void => {
    setIsModalAddAgent(true);
  };

  return (
    <div className={`${styles.container} ${CONTAINER_CLASS}`}>
      <div className={styles.container_top}>
        {status === 'agent' && (
          <>
            <button
              onClick={addAnAgent}
              data-tip
              data-for={`${status}-btn-add-cpanel-agents`}
            >
              +
            </button>
            <ReactTooltip
              id={`${status}-btn-add-cpanel-agents`}
              effect="solid"
              type="dark"
              place="top"
            >
              Añadir un {mapStatusTooltip.get(status)}
            </ReactTooltip>
          </>
        )}
        {status === 'disabled' && (
          <svg viewBox="0 0 18 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.09139 0.143593C0.798894 -0.0870279 0.374214 -0.0335913 0.143593 0.258904C-0.0870279 0.551398 -0.0335913 0.976079 0.258904 1.2067L16.9086 14.2565C17.2011 14.4871 17.6258 14.4337 17.8564 14.1412C18.087 13.8487 18.0336 13.424 17.7411 13.1934L14.7824 10.8759C15.8961 9.73405 16.6499 8.45438 17.0295 7.54596C17.1224 7.32378 17.1224 7.07628 17.0295 6.8541C16.6105 5.85006 15.7302 4.38758 14.414 3.16698C13.0921 1.93512 11.2725 0.900142 9 0.900142C7.08191 0.900142 5.48444 1.63982 4.23852 2.61011L1.09139 0.143593ZM6.27474 4.20477C6.99191 3.54947 7.95096 3.1501 9 3.1501C11.2359 3.1501 13.0499 4.96413 13.0499 7.20003C13.0499 7.90033 12.8727 8.55844 12.5606 9.13218L11.475 8.28282C11.6212 7.95096 11.7 7.58534 11.7 7.20003C11.7 5.70943 10.4906 4.50008 9 4.50008C8.92125 4.50008 8.8425 4.50289 8.76375 4.51133C8.91281 4.77289 9 5.07663 9 5.40006C9 5.68693 8.9325 5.95693 8.81438 6.19599L6.27474 4.20477ZM12.5493 12.5859L10.4906 10.9659C10.0294 11.1487 9.52593 11.25 9 11.25C6.7641 11.25 4.95007 9.43593 4.95007 7.20003C4.95007 7.00597 4.96413 6.81754 4.98945 6.63192L2.3373 4.54227C1.69607 5.37756 1.23764 6.21005 0.970454 6.8541C0.877643 7.07628 0.877643 7.32378 0.970454 7.54596C1.38951 8.55001 2.26981 10.0125 3.58603 11.2331C4.90788 12.4649 6.72754 13.4999 9 13.4999C10.3444 13.4999 11.5284 13.1371 12.5493 12.5859Z" />
          </svg>
        )}
        {status === 'company_admin' && (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z" />
          </svg>
        )}
        <span>{mapStatusName.get(status)}</span>
      </div>
      <div className={styles.container_users}>
        {Array.from(usersFromCompany.keys()).map(
          (username: string, index: number) => {
            const user: USER | undefined = usersFromCompany.get(username);
            if (user === undefined)
              return (
                <React.Fragment key={`${status}-${index}`}></React.Fragment>
              );
            let ownStatus: USER_STATUS = user.status;
            const isActive: boolean = user.is_active;
            if (ownStatus === 'admin') {
              ownStatus = 'company_admin';
            }

            // First check for active
            if (status === 'disabled' && isActive) {
              return (
                <React.Fragment key={`${status}-${index}`}></React.Fragment>
              );
            }
            if (status === 'disabled' && !isActive) {
              return (
                <React.Fragment key={`${status}-${index}`}>
                  <User status={status} user={user} />
                </React.Fragment>
              );
            }

            // Then check for status
            if (status !== 'disabled' && !isActive) {
              return (
                <React.Fragment key={`${status}-${index}`}></React.Fragment>
              );
            }
            if (ownStatus !== status)
              return (
                <React.Fragment key={`${status}-${index}`}></React.Fragment>
              );
            return (
              <React.Fragment key={`${status}-${index}`}>
                <User status={status} user={user} />
              </React.Fragment>
            );
          }
        )}
      </div>
    </div>
  );
};

export default Container;
