import React from 'react';
import Nav from '../../Nav/Landing/LandingNav';
import { Outlet } from 'react-router-dom';
import styles from './LandingLayout.module.css';

const LandingLayout: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className={styles.layout}>
      <Nav />
      <Outlet />
    </div>
  );
};

export default LandingLayout;
