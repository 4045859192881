import React, { useContext, useState } from 'react';
import PopUpModal from '../PopUp/PopUp';
import styles from './AddTemplate.module.css';
import {
  TICKET_TYPES,
  TICKET_FIELDS,
} from '../../../types/ticket/ticket.types';
import { CPanelLayoutContext } from '../../Layout/CompanyPanel/CompanyPanelLayout';
import { LayoutContext } from '../../Layout/Layout';
import { PersistentCAdminContext } from '../../Layout/Context/PersistentCAdmin';
import { AppContext } from '../../../App';
import { Input, Button } from '@chakra-ui/react';
import { fetcher, RESPONSE } from '../../../utils/fetcher';
import { RESPONSE_DATA } from '../../../routes/index.routes';
import {
  createMongoSchema,
  BODY_CREATE_MONGO_SCHEMA,
  CREATE_MONGO_SCHEMA_DATA,
} from '../../../routes/schemas.routes';
import ReactTooltip from 'react-tooltip';

const MAP_TYPES = new Map<TICKET_TYPES, string>();
MAP_TYPES.set('str', 'Texto');
MAP_TYPES.set('int', 'Número entero');
MAP_TYPES.set('float', 'Número decimal');
MAP_TYPES.set('bool', 'Sí/No');

const MAP_TYPE_TEMPLATE = new Map<'ticket' | 'client', string>();
MAP_TYPE_TEMPLATE.set('client', 'Clientes');
MAP_TYPE_TEMPLATE.set('ticket', 'Tickets');

const TOGGLE_TYPES: TICKET_TYPES[] = ['str', 'int', 'float', 'bool'];

const EditIcon: React.FunctionComponent = () => {
  return (
    <>
      <svg
        data-tip
        data-for="icon-edit-template-type"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
      </svg>
      <ReactTooltip
        id="icon-edit-template-type"
        effect="solid"
        type="dark"
        place="top"
      >
        Escoger tipo de plantilla
      </ReactTooltip>
    </>
  );
};

const TemplateModal: React.FunctionComponent = () => {
  const { isModalAddTemplate, setIsModalAddTemplate } =
    useContext(CPanelLayoutContext);
  const { addStaticMsg, addAsyncMsg } = useContext(LayoutContext);
  const { company } = useContext(AppContext);
  const { setTemplatesTicketsWindow } = useContext(PersistentCAdminContext);

  const [newTemplateFields, setNewTemplateFields] = useState<string[][]>([]);
  const [nameTemplate, setNameTemplate] = useState<string>('');
  const [desTemplate, setDesTemplate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typeTicket, setTypeTicket] = useState<'ticket' | 'client' | null>(
    null
  );

  const close = (): void => {
    setIsModalAddTemplate(false);
    clear();
  };

  const getFieldsForFetch = (): TICKET_FIELDS => {
    const fields: TICKET_FIELDS = {};
    for (let i = 0; i < newTemplateFields.length; i++) {
      const wrapper: string[] = newTemplateFields[i];
      const key: string = wrapper[0];
      const value: TICKET_TYPES = wrapper[1] as TICKET_TYPES;

      fields[key] = value;
    }
    return fields;
  };

  const askToDoRefresh = async (): Promise<void> => {
    const doRefresh: boolean = await addAsyncMsg(
      '¿Quiéres recargar la página?'
    );
    if (doRefresh) {
      location.reload();
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const ok: 'repeated' | 'trim' | 'ok' = checkIfAllOk();

    if (ok === 'repeated') {
      addStaticMsg('Tienes un campo repetido!', 'danger');
      return;
    }
    if (ok === 'trim') {
      addStaticMsg('Tienes un campo vacío!', 'danger');
      return;
    }
    if (newTemplateFields.length === 0) {
      addStaticMsg('Al menos debes de crear un campo.', 'danger');
      return;
    }

    if (nameTemplate.trim() === '') {
      addStaticMsg('Debes de ponerle nombre.', 'danger');
      return;
    }

    if (desTemplate.trim() === '') {
      addStaticMsg('Debes de ponerle descripción.', 'danger');
      return;
    }

    if (typeTicket === null) {
      addStaticMsg('Debes de escoger un tipo de plantilla.', 'danger');
      return;
    }

    const doFetch = async (): Promise<void> => {
      try {
        if (company === null) return;
        const body: BODY_CREATE_MONGO_SCHEMA = {
          name: nameTemplate,
          description: desTemplate,
          type: typeTicket,
          fields: getFieldsForFetch(),
        };
        setIsLoading(true);
        const res: RESPONSE = await fetcher[createMongoSchema.method]({
          uri: createMongoSchema.url(company.id),
          body,
        });
        setIsLoading(false);
        const resData: RESPONSE_DATA = res.data;

        // if (!resData.isAuth) {
        //   void askToDoRefresh();
        // }
        if (res.status !== 200) {
          addStaticMsg(resData.msg, 'danger');
          return;
        }

        const data: CREATE_MONGO_SCHEMA_DATA = resData.data;
        setTemplatesTicketsWindow((prev) => [...prev, data]);
        addStaticMsg('Se ha creado la plantilla con éxito!', 'success');
        close();
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        addStaticMsg('Hubo un error al crear la plantilla.', 'danger');
        void askToDoRefresh();
      }
    };
    void doFetch();
  };

  const clear = (): void => {
    setIsLoading(false);
    setNewTemplateFields([]);
    setNameTemplate('');
    setDesTemplate('');
    setTypeTicket(null);
  };

  const checkIfAllOk = (): 'repeated' | 'trim' | 'ok' => {
    const setKeys: Set<string> = new Set<string>();

    for (let i = 0; i < newTemplateFields.length; i++) {
      const wrapper: string[] = newTemplateFields[i];
      const key: string = wrapper[0];

      if (key.trim() === '') {
        return 'trim';
      }

      const hasIt: boolean = setKeys.has(key);
      if (!hasIt) {
        setKeys.add(key);
        continue;
      }
      return 'repeated';
    }
    return 'ok';
  };

  const updateTemplateKey = (
    prev: string[][],
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ): string[][] => {
    const aux: string[][] = prev.slice(0, prev.length);
    const key: string = e.target.value;
    const typeIndex: string = aux[index][1];
    aux[index] = [key, typeIndex];

    return aux;
  };

  const updateTemplateType = (
    prev: string[][],
    index: number,
    newType: TICKET_TYPES
  ): string[][] => {
    const aux: string[][] = prev.slice(0, prev.length);
    if (newType === null) return prev;

    const keyIndex: string = aux[index][0];
    aux[index] = [keyIndex, newType];
    return aux;
  };

  const getNextOneToggle = (curr: TICKET_TYPES): TICKET_TYPES => {
    for (let i = 0; i < TOGGLE_TYPES.length; i++) {
      if (curr === TOGGLE_TYPES[i]) {
        if (i === TOGGLE_TYPES.length - 1) {
          return TOGGLE_TYPES[0];
        }
        return TOGGLE_TYPES[i + 1];
      }
    }
    return curr;
  };

  return (
    <PopUpModal
      isOpen={isModalAddTemplate}
      setIsOpen={setIsModalAddTemplate}
      callbackClose={clear}
    >
      <div className={styles.container}>
        <div className={styles.title}>Nueva plantilla:</div>
        <form
          autoComplete="new-password"
          onSubmit={onSubmit}
          className={styles.form}
        >
          <div className={styles.input_top}>
            <div className={styles.input}>
              <div className={styles.input_label}>Nombre de la plantilla:</div>
              <div className={styles.input_name}>
                <Input
                  type="text"
                  autoComplete="new-password"
                  placeholder="Nombre"
                  value={nameTemplate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNameTemplate(e.target.value);
                  }}
                  isRequired
                />
              </div>
            </div>
            <div className={styles.input_type_template}>
              <div className={styles.input_label_template}>
                <div>Tipo de plantilla:</div>
                <div
                  onClick={() => {
                    setTypeTicket((prev) =>
                      prev === 'client' ? 'ticket' : 'client'
                    );
                  }}
                >
                  <EditIcon />
                </div>
              </div>
              <div className={styles.input_name}>
                <button
                  type="button"
                  style={{
                    cursor: 'text',
                  }}
                  className={`${
                    typeTicket !== null
                      ? styles.template_type
                      : styles.template_type_not
                  }`}
                >
                  {typeTicket === null && 'Escoge el tipo de plantilla'}
                  {typeTicket !== null && MAP_TYPE_TEMPLATE.get(typeTicket)}
                </button>
              </div>
            </div>
          </div>
          <div className={styles.input}>
            <div className={styles.input_label}>
              Descripción de la plantilla:
            </div>
            <div className={styles.input_des}>
              <Input
                type="text"
                autoComplete="new-password"
                placeholder="Descipción..."
                value={desTemplate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDesTemplate(e.target.value);
                }}
                isRequired
              />
            </div>
          </div>
          {newTemplateFields.length > 0 && (
            <div className={styles.fields}>
              {newTemplateFields.map((wrap: string[], index: number) => {
                if (wrap.length !== 2)
                  return <React.Fragment key={index}></React.Fragment>;
                const key: string = wrap[0];
                const typeField: TICKET_TYPES = wrap[1] as TICKET_TYPES;

                if (typeField === null)
                  return <React.Fragment key={index}></React.Fragment>;
                return (
                  <div key={index} className={styles.wrapper}>
                    <div className={styles.input}>
                      <div className={styles.input_label}>Campo:</div>
                      <div className={styles.input_data}>
                        <Input
                          type="text"
                          autoComplete="new-password"
                          placeholder="Campo"
                          value={key}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setNewTemplateFields((prev) =>
                              updateTemplateKey(prev, index, e)
                            );
                          }}
                          isRequired
                        />

                        <button
                          type="button"
                          onClick={() => {
                            const nextOne: TICKET_TYPES =
                              getNextOneToggle(typeField);
                            setNewTemplateFields((prev) =>
                              updateTemplateType(prev, index, nextOne)
                            );
                          }}
                        >
                          {MAP_TYPES.get(typeField)}
                        </button>
                      </div>
                    </div>
                    <button
                      className={styles.delete}
                      type="button"
                      onClick={() => {
                        setNewTemplateFields((prev) => [
                          ...prev.slice(0, index),
                          ...prev.slice(index + 1, prev.length),
                        ]);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                      </svg>
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.add}>
            <Button
              onClick={() => {
                setNewTemplateFields((prev) => [...prev, ['', 'str']]);
              }}
              className={styles.btn}
              type="button"
            >
              Añadir campo
            </Button>
          </div>
          <div className={styles.btns}>
            <Button onClick={close} className={styles.btn} type="button">
              Cancelar
            </Button>
            <Button
              className={`${styles.btn} ${styles.btn_color}`}
              isLoading={isLoading}
              type="submit"
            >
              Guardar
            </Button>
          </div>
        </form>
      </div>
    </PopUpModal>
  );
};

export default TemplateModal;
